import React, { useState, useEffect, useMemo, createRef } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  LinearProgress,
  Radio,
  ThemeProvider
} from "@mui/material"

import { Autocomplete } from "@mui/material"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import makeStyles from "@mui/styles/makeStyles"
import Icon from "@mui/material/Icon"

//Icons
import Battery20 from "@mui/icons-material/Battery20"
import BatteryUnknown from "@mui/icons-material/BatteryUnknown"
import BlockOutlined from "@mui/icons-material/BlockOutlined"
import BookmarkBorderOutlined from "@mui/icons-material/BookmarkBorderOutlined"
import PlannedIcon from "@mui/icons-material/EmojiObjectsOutlined"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Edit from "@mui/icons-material/Edit"
import Cancel from "@mui/icons-material/Cancel"

import SaturationChip from "components/ContentSaturation/SaturationChip"
import StatusType from "../Types/StatusType"
import RenderType from "../Types/RenderType"
import GridItem from "components/Grid/GridItem"
import CardHeader from "components/Card/CardHeader"
import CodeEditor from "components/CodeEditor"
import NarrativeCategories from "../NarrativeCategories"
import NarrativeTags from "../NarrativeTags"
import NarrativePreviewDAO from "daos/narrativePreviewDAO"
import WordCounter from "components/WordCounter/WordCounter"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import Helpers from "tools/Helpers"
import Check from "@mui/icons-material/CheckCircleOutline"
import Report from "@mui/icons-material/Report"
import ValidateTriggers from "components/NarrativeAdmin/Trigger/ValidateTriggers"
import { FormTheme } from "components/SharedStyles/FormStyles/FormTheme"
import CTAAccordion from "components/CTAEditorV2/CTAAccordion"

const useStyles = makeStyles({
  modalHeader: {
    backgroundColor: "#DDEAEF"
  },
  modalTitle: {
    fontSize: "large",
    fontWeight: "bold",
    marginBottom: "15px",
    marginLeft: "5px"
  },
  sentenceModel: {
    display: "flex"
  },
  modalStatus: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  sentenceInfo: {
    display: "inline-flex",
    marginBottom: "8px"
  },
  sentenceDetail: {
    display: "inline-flex",
    marginBottom: "8px",
    marginTop: "4px"
  },
  triggerHeader: {
    backgroundColor: "#DDEAEF",
    fontSize: "large",
    fontWeight: "bold",
    padding: "5px",
    borderRadius: "0px",
    marginTop: "5px",
    marginBottom: "9px",
    marginLeft: "-25px",
    marginRight: "-25px",
    paddingBottom: "9px",
    paddingLeft: "15px"
  },
  modalButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "10px",
    paddingRight: "9px"
  },
  buttonStyle: {
    fontSize: "12px",
    padding: "14px 23px",
    minWidth: "100px",
    marginRight: "4px"
  },
  autocompleteModal: {
    minWidth: "235px"
  },
  autocompleteModalDark: {
    minWidth: "235px",
    backgroundColor: "#FFF"
  },
  inputFieldModalDark: {
    backgroundColor: "#FFF"
  },
  requiredMsg: {
    color: "#c00",
    textAlign: "center",
    backgroundColor: "#f7f7f7"
  },
  statusIcon: {
    fontSize: "1em",
    width: "20px",
    height: "25px",
    color: "#144E68",
    marginRight: "20px",
    marginBottom: "8px"
  },
  ownerField: {
    marginTop: "8px"
  }
})

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    narrative: store.narrativeStore.narrative,
    loadingSentence: store.narrativeStore.loadingSentence,
    getSentence: store.narrativeStore.getSentence,
    postSentence: store.narrativeStore.postSentence,
    reloadNarrativeBrief: store.narrativeStore.reloadNarrativeBrief,
    accounts: store.accountStore.accounts,
    getActiveAccountsByOrganization:
      store.accountStore.getActiveAccountsByOrganization,
    account: store.accountStore.account,
    narrativeParagraphTagsSummary:
      store.narrativeStore.narrativeParagraphTagsSummary,
    narrativeParagraphTags: store.narrativeStore.narrativeParagraphTags,
    triggersBrief: store.narrativeStore.triggersBrief
  }))
}

const SentenceEditor = props => {
  const {
    loadingSentence,
    getSentence,
    postSentence,
    narrative,
    accounts,
    getActiveAccountsByOrganization,
    reloadNarrativeBrief,
    narrativeParagraphTagsSummary,
    narrativeParagraphTags,
    triggersBrief
  } = useStoreData()
  const { sentence, create, paragraph } = props
  const { contentBlock } = paragraph
  const classes = useStyles()
  const PUBLISHED = 3

  const [sentenceFormValues, setSentenceFormValues] = useState(sentence)

  const [sentenceTemplate, setSentenceTemplate] = useState(sentence?.template)

  const [lastTemplateEdit, setLastTemplateEdit] = useState("")

  const [shouldReload, setShouldReload] = useState(create)

  const [currentEditor, setCurrentEditor] = useState("code")

  const [isLoadingPreview, setIsLoadingPreview] = useState(false)

  const [templateError, setTemplateError] = useState(false)

  const [previewResponse, setPreviewResponse] = useState({
    preview: "",
    previewElapsedTimeMilliseconds: 0
  })

  const [doValidateJSON, setDoValidateJSON] = useState(false)

  const contentRef = createRef()

  const [includeAllStatuses, setIncludeAllStatuses] = useState(false)

  const [triggerBlurred, setTriggerBlurred] = useState(true)
  const [triggerValue, setTriggerValue] = useState("")
  const [manualEdit, setManualEdit] = useState(true)

  const accountsJS = accounts?.toJS() || null

  useEffect(() => {
    if (!accountsJS || accountsJS.length === 0) {
      getActiveAccountsByOrganization(narrative?.organization?.id)
    }
    if (!create && sentence?.id) {
      getSentence(sentence?.id)
    }
  }, [])

  useEffect(() => {
    setSentenceFormValues(sentence)
  }, [sentence])

  const accountList = useMemo(
    () =>
      accounts
        ?.toJS()
        .sort((a, b) =>
          a.username.toUpperCase() > b.username.toUpperCase() ? 1 : -1
        ),
    [accounts]
  )

  const handleStatusChange = event => {
    setIncludeAllStatuses(event.target.checked)
  }

  const labelStatusIcon = status => {
    switch (status) {
      case 1:
        return includeAllStatuses ? <Battery20 /> : null
      case 2:
        return includeAllStatuses ? <BatteryUnknown /> : null
      case 3:
        return null
      case 4:
        return includeAllStatuses ? <PlannedIcon /> : null
      case 5:
        return includeAllStatuses ? <BookmarkBorderOutlined /> : null
      case 6:
        return includeAllStatuses ? <BlockOutlined /> : null
      default:
        return null
    }
  }
  const handleAutoCompleteMultiChangeForTriggerType = (
    event,
    newValue,
    reason,
    name
  ) => {
    let newVal = ""
    newValue.forEach(itm => {
      let matchVal = null
      triggerList.forEach(tl => {
        if (`!${itm.type}` === tl) {
          matchVal = `!${itm.type}`
        } else if (itm.type === tl) {
          matchVal = itm.type
        }
      })
      if (matchVal) {
        newVal = !newVal ? matchVal : `${newVal}, ${matchVal}`
      } else {
        newVal = !newVal ? itm.type : `${newVal}, ${itm.type}`
      }
    })
    const newFormValues = {
      ...sentenceFormValues,
      [name]: newVal
    }
    setTriggerValue(newVal)
    setSentenceFormValues(autonameSentence(newFormValues))
  }

  const handleChange = event => {
    const thisId = event.target.name || event.target.id
    if (
      thisId === "librarySentencePosition" &&
      sentenceFormValues.isLibraryPlaceholder
    ) {
      const thisValue = event.target.value > -1 ? event.target.value : 0
      const newFormValues = {
        ...sentenceFormValues,
        [thisId]: thisValue,
        name: getJustNewName(sentenceFormValues, thisValue)
      }
      setSentenceFormValues(autonameSentence(newFormValues))
    } else {
      const newFormValues = {
        ...sentenceFormValues,
        [thisId]: event.target.value
      }
      setSentenceFormValues(autonameSentence(newFormValues))
    }
    if (thisId === "librarySentencePosition") {
      setShouldReload(true)
    }
  }

  const getJustNewName = (sentenceObject, position) => {
    sentenceObject = sentenceObject || sentenceFormValues
    const nm = narrative?.libraryParagraphs?.find(
      lp => lp.id === Number(sentenceObject.libraryParagraph_Id)
    )
    if (nm) {
      const pos =
        position || position === 0
          ? `- Pos. ${position}`
          : sentenceObject.librarySentencePosition &&
            typeof Number(sentenceObject.librarySentencePosition) === "number"
          ? `- Pos. ${sentenceObject.librarySentencePosition}`
          : ""
      const myName = nm.name.split("(")[0].split("\n")[0]
      return `Lib. Para. ${nm.id} - ${myName}${pos}`
    } else {
      return ""
    }
  }

  const checkForNameOverride = event => {
    if (
      sentenceFormValues.libraryParagraph_Id &&
      sentenceFormValues.isLibraryPlaceholder
    ) {
      const newName = getJustNewName()
      if (newName !== sentenceFormValues.name) {
        const newFormValues = {
          ...sentenceFormValues,
          name: getJustNewName()
        }
        setSentenceFormValues(autonameSentence(newFormValues))
      }
    }
  }

  const handleLibraryChange = event => {
    const newFormValues = {
      ...sentenceFormValues,
      libraryParagraph_Id: event?.target?.innerText?.split(" - ")[0],
      name: getJustNewName()
    }
    setShouldReload(true)
    setSentenceFormValues(autonameSentence(newFormValues))
  }

  const handleMultiChange = (event, newValue, reason, name) => {
    const newFormValues = {
      ...sentenceFormValues,
      [name]: newValue
    }
    setSentenceFormValues(autonameSentence(newFormValues))
  }

  const handleCheckboxChange = event => {
    const newFormValues = {
      ...sentenceFormValues,
      [event.target.id]: event.target.checked
    }
    setSentenceFormValues(autonameSentence(newFormValues))
  }
  const handleCodeEditorChange = editor => {
    const strTemplate = editor && editor.getValue()
    if (strTemplate !== sentenceTemplate) {
      setSentenceTemplate(strTemplate)
      setLastTemplateEdit("Code")
    }
    setTemplateError(!strTemplate)
  }
  const handleAddCTAVariant = templateCTA => {
    setSentenceTemplate(prevTemplate => `${prevTemplate} ${templateCTA}`)
  }

  const handleSave = () => {
    const sentenceString =
      lastTemplateEdit === "Code"
        ? sentenceTemplate
        : lastTemplateEdit === "Categories"
        ? sentenceFormValues.template
        : sentenceTemplate || sentenceFormValues.template
    if (!sentenceFormValues.isLibraryPlaceholder && !sentenceString) {
      alert("Template is a required field.")
      return
    }
    const newValues = {
      ...sentenceFormValues,
      template: sentenceString
    }
    postSentence(newValues)
    if (shouldReload) {
      setTimeout(() => reloadNarrativeBrief(narrative.id), 1000)
    }
    props.handleClose()
  }

  const buildPreview = () => {
    setIsLoadingPreview(true)
    const previewStartDateTime = new Date()
    const previewString = sentenceTemplate || sentenceFormValues.template
    if (!previewString) {
      return
    }
    const previewRequest = {
      templateString: previewString,
      modelType: narrative.modelType,
      modelTypeId: narrative.modelTypeId,
      narrativeId: narrative.id,
      contentId: narrative.queryId,
      contentType: "query",
      dataModelText: !narrative.contentId ? narrative.model : "",
      itemIndex: 0,
      useCache: true,
      excludeDependencies: false,
      sentenceId: sentenceFormValues.id
    }
    const getSentencePreview =
      NarrativePreviewDAO.evaluateSentence(previewRequest)
    getSentencePreview
      .then(evaluatedSentence => {
        const previewText = sentenceFormValues.renderType
          ? `<${sentenceFormValues.renderType}>${evaluatedSentence}</${sentenceFormValues.renderType}`
          : evaluatedSentence
        const newPreviewResponse = {
          preview: previewText,
          previewElapsedTimeMilliseconds:
            new Date().getTime() - previewStartDateTime.getTime()
        }
        setPreviewResponse(newPreviewResponse)
      })
      .catch(error => {
        const newPreviewResponse = {
          preview: error,
          previewElapsedTimeMilliseconds:
            new Date().getTime() - previewStartDateTime.getTime()
        }
        setPreviewResponse(newPreviewResponse)
      })
      .finally(() => setIsLoadingPreview(false))
  }

  const autonameSentence = sentenceObject => {
    // Check to see if they gave us a Library Sentence Id
    const positionSame =
      Number(sentenceObject.librarySentencePosition) ===
      Number(sentenceFormValues.librarySentencePosition)
    const isLibraryPlaceholderSame =
      sentenceObject.isLibraryPlaceholder ===
      sentenceFormValues.isLibraryPlaceholder
    const isLibraryParagraphSame =
      sentenceObject.libraryParagraph_Id ===
      sentenceFormValues.libraryParagraph_Id
    if (!positionSame) {
      if (
        sentenceObject.isLibraryPlaceholder &&
        !!sentenceObject.libraryParagraph_Id
      ) {
        sentenceObject.name = getJustNewName(sentenceObject)
      } else {
      }
    } else if (!isLibraryPlaceholderSame) {
      if (sentenceObject.isLibraryPlaceholder) {
        sentenceObject.name = getJustNewName(sentenceObject)
      } else {
        sentenceObject.name = ""
      }
    } else if (!isLibraryParagraphSame) {
      if (
        sentenceObject.isLibraryPlaceholder &&
        !!sentenceObject.libraryParagraph_Id
      ) {
        sentenceObject.name = getJustNewName(sentenceObject)
      } else {
        sentenceObject.name = ""
      }
    }
    // Return the Sentence whether changed or not
    return sentenceObject
  }

  const narrativeParagraphTagsSummarySorted =
    narrativeParagraphTagsSummary || []
  const narrativeParagraphTagsSorted = narrativeParagraphTags || []

  let filteredLibraryParagraphsById = []
  narrativeParagraphTagsSorted.forEach(itm => {
    sentenceFormValues.libraryParagraphTagsSelected?.forEach((itm2, index) => {
      if (itm.tagId === itm2.tagId && itm.tagTypeId === itm2.tagTypeId) {
        if (
          !filteredLibraryParagraphsById.includes({
            id: itm.paragraphId,
            index
          })
        ) {
          filteredLibraryParagraphsById.push({ id: itm.paragraphId, index })
        }
      }
    })
  })

  let filteredLibraryParagraphs = filteredLibraryParagraphsById.length
    ? narrative?.libraryParagraphs?.toJS().filter(itm => {
        let found = []
        filteredLibraryParagraphsById.forEach(itm2 => {
          if (itm.id === itm2.id) {
            if (!found.includes(itm2.index)) {
              found.push(itm2.index)
            }
          }
        })
        return (
          found.length ===
          sentenceFormValues.libraryParagraphTagsSelected.length
        )
      })
    : narrative?.libraryParagraphs?.toJS() || []

  //If includeAllStatuses is Checked, show all filtered Paragraphs.  if not, only show ones with Status_Id = 3
  filteredLibraryParagraphs = filteredLibraryParagraphs.filter(itm => {
    if (!includeAllStatuses) {
      return itm.status_Id === PUBLISHED
    } else {
      return true
    }
  })

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = (
    <CheckBoxIcon fontSize="small" className={classes.checkRegion} />
  )

  const triggerList =
    (sentenceFormValues?.triggerType &&
      sentenceFormValues?.triggerType.split(", ")) ||
    []

  const triggers = triggersBrief?.toJS() || []
  let triggerKeys = []
  triggers.forEach(itm => {
    if (!triggerKeys.includes(itm.type)) {
      triggerKeys.push(itm.type)
    }
  })

  return (
    <>
      {loadingSentence && (
        <CircularProgress
          style={{
            position: "absolute",
            left: "45%",
            top: "250px",
            zIndex: "101"
          }}
        />
      )}
      <ThemeProvider theme={FormTheme}>
        <Grid container spacing={2} className={classes.modalStatus}>
          <GridItem xs={4}>
            <Typography className={classes.modalTitle}>
              Sentence Information
            </Typography>
          </GridItem>
          <GridItem xs={4} className={classes.ownerField}>
            {/* owner */}
            <Autocomplete
              size="medium"
              name="ownerId"
              id="ownerId"
              onChange={(e, option) => {
                const newFormValues = {
                  ...sentenceFormValues,
                  ownerId: option.id
                }
                setSentenceFormValues(newFormValues)
              }}
              options={accountList}
              getOptionLabel={option => option.username}
              value={accountList?.find(
                ob => ob.id === sentenceFormValues.ownerId
              )}
              readonly={loadingSentence}
              renderInput={params => (
                <TextField
                  {...params}
                  label="OWNER"
                  key={params.id}
                  value={params.name}
                />
              )}
            />
          </GridItem>
          <GridItem xs={4}>
            <StatusType
              value={sentenceFormValues.status_Id || ""}
              onChange={e => {
                const newFormvalues = {
                  ...sentenceFormValues,
                  status_Id: e
                }
                setSentenceFormValues(newFormvalues)
              }}
              isLibrary={narrative.isLibrary}
            />
          </GridItem>
        </Grid>
        {!create && (
          <Grid container spacing={2} className={classes.sentenceInfo}>
            <GridItem>
              <TextField
                id="id"
                label="ID"
                fullWidth={true}
                value={sentenceFormValues.id}
                readonly
              />
            </GridItem>
            <GridItem>
              <FormControlLabel
                control={
                  <Checkbox
                    id="isArchived"
                    checked={sentenceFormValues.isArchived || false}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Archive"
              />
            </GridItem>
          </Grid>
        )}
        <Grid container spacing={2} className={classes.sentenceDetail}>
          <GridItem xs={2}>
            <TextField
              id="paragraph_Id"
              label="Paragraph Id"
              fullWidth={true}
              value={sentenceFormValues.paragraph_Id}
              readonly
            />
          </GridItem>
          <GridItem xs={2}>
            <TextField
              id="position"
              name="position"
              label="POSITION"
              type="number"
              fullWidth={true}
              value={sentenceFormValues.position}
              onChange={handleChange}
            />
          </GridItem>
          <GridItem xs={4}>
            <TextField
              id="name"
              name="name"
              label="NAME"
              fullWidth={true}
              value={sentenceFormValues.name}
              onChange={handleChange}
              onBlur={checkForNameOverride}
            />
          </GridItem>
          <GridItem xs={2}>
            <RenderType
              margin="none"
              aria-label="SentenceEditor RenderType Dropdown"
              fullWidth
              value={sentenceFormValues.renderType || ""}
              renderNoneOption={sentenceFormValues.isLibraryPlaceholder}
              onChange={e => {
                const newFormValues = { ...sentenceFormValues, renderType: e }
                setSentenceFormValues(newFormValues)
              }}
              classes={{ selected: classes.selected }}
            />
          </GridItem>
          <GridItem xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  id="isLibraryPlaceholder"
                  checked={sentenceFormValues.isLibraryPlaceholder}
                  onChange={handleCheckboxChange}
                />
              }
              label="Library Position Group"
            />
          </GridItem>
        </Grid>
        {sentenceFormValues.isLibraryPlaceholder && (
          <Grid className={classes.sentenceDetail} container spacing={2}>
            {!narrative?.isLibrary && (
              <GridItem xs={8}>
                <Autocomplete
                  multiple={true}
                  className={classes.autocompleteModal}
                  fullWidth={true}
                  limitTags={4}
                  name="libraryParagraphTagsSelected"
                  id="libraryParagraphTagsSelected"
                  value={narrativeParagraphTagsSummarySorted.filter(rgn => {
                    let match = false
                    sentenceFormValues.libraryParagraphTagsSelected &&
                      sentenceFormValues.libraryParagraphTagsSelected.forEach(
                        itm => {
                          if (
                            itm.tagId === rgn.tagId &&
                            itm.tagTypeId === rgn.tagTypeId
                          ) {
                            match = true
                          }
                        }
                      )
                    return match
                  })}
                  options={narrativeParagraphTagsSummarySorted}
                  onChange={(event, newValue, reason) => {
                    handleMultiChange(
                      event,
                      newValue,
                      reason,
                      "libraryParagraphTagsSelected"
                    )
                  }}
                  getOptionLabel={option =>
                    `${option.tagTypeId} - ${option.tagName}`
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {option.tagTypeId} - {option.tagName}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.tagId === value.tagId : false
                  }
                  renderInput={params => (
                    <TextField {...params} label="FILTER BY" />
                  )}
                />
              </GridItem>
            )}
            <GridItem xs={5}>
              <Autocomplete
                className={classes.autocompleteModal}
                fullWidth={true}
                name="libraryParagraph_Id"
                id="libraryParagraph_Id"
                value={
                  sentenceFormValues.libraryParagraph_Id
                    ? narrative?.libraryParagraphs?.find(
                        lp =>
                          lp.id ===
                          Number(sentenceFormValues.libraryParagraph_Id)
                      )
                    : null
                }
                options={filteredLibraryParagraphs || []}
                onChange={handleLibraryChange}
                getOptionLabel={option => `${option.id} - ${option.name}`}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Icon className={classes.statusIcon}>
                      {labelStatusIcon(option.status_Id)}
                    </Icon>
                    {option.id} - {option.name}
                    <SaturationChip
                      saturation={option.saturation}
                      utilization={option.utilization}
                    />
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id === value.id : false
                }
                renderInput={params => (
                  <TextField {...params} label="LIBRARY PARAGRAPH" />
                )}
                readonly={loadingSentence}
              />
            </GridItem>
            <GridItem xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="includeAllStatus"
                    checked={includeAllStatuses}
                    onChange={handleStatusChange}
                  />
                }
                label="Include All Statuses?"
              />
            </GridItem>
            <GridItem xs={1}>
              <TextField
                id="librarySentencePosition"
                name="librarySentencePosition"
                type="number"
                className={classes.autocompleteModal}
                label="SENTENCE POSITION FILTER"
                value={sentenceFormValues.librarySentencePosition}
                onChange={handleChange}
                readonly={loadingSentence}
              />
            </GridItem>
          </Grid>
        )}
        {!narrative?.isLibrary && (
          <Card className={classes.triggerHeader} elevation={0}>
            <CardHeader className={classes.triggerTitle}>
              Trigger Details
            </CardHeader>
            <Grid container spacing={2}>
              {manualEdit && (
                <GridItem xs={8} className={classes.sentenceDetail}>
                  <TextField
                    label="TRIGGER"
                    type="text"
                    name="triggerType"
                    id="triggerType"
                    fullWidth={true}
                    value={sentenceFormValues.triggerType}
                    onChange={handleChange}
                    onBlur={e => {
                      setTriggerValue(e.target.value)
                      setTriggerBlurred(!triggerBlurred)
                    }}
                    readonly={loadingSentence}
                  />
                  <Tooltip
                    id="tooltip-top"
                    title="Cancel Manual Edit"
                    placement="top"
                  >
                    <IconButton
                      className={classes.iconButton}
                      aria-label="Cancel Manual Edit"
                      onClick={() => setManualEdit(false)}
                      size="large"
                    >
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                </GridItem>
              )}
              {!manualEdit && (
                <GridItem xs={8} className={classes.sentenceDetail}>
                  <Autocomplete
                    multiple={true}
                    className={classes.ctaRegion}
                    limitTags={3}
                    fullWidth={true}
                    disableCloseOnSelect
                    options={triggers}
                    variant="outlined"
                    value={triggers.filter(keys => {
                      let match = false
                      triggerList.forEach(itm => {
                        if (
                          itm.replace(/^\s+!|^\s+|^!|\s+$/g, "") === keys.type
                        ) {
                          match = true
                        }
                      })
                      return match
                    })}
                    getOptionLabel={option => option.type}
                    isOptionEqualToValue={(option, value) =>
                      option.type === value.type
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.type}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Selected Triggers"
                        required={true}
                      />
                    )}
                    onChange={(event, newValue, reason) => {
                      handleAutoCompleteMultiChangeForTriggerType(
                        event,
                        newValue,
                        reason,
                        "triggerType"
                      )
                    }}
                  />
                  <Tooltip
                    id="tooltip-top"
                    title="Manually Edit Triggers"
                    placement="top"
                  >
                    <IconButton
                      className={classes.iconButton}
                      aria-label="Manually Edit Triggers"
                      onClick={() => setManualEdit(true)}
                      size="large"
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </GridItem>
              )}

              <GridItem xs={1} className={classes.sentenceDetail}>
                <ValidateTriggers
                  triggerType={sentenceFormValues.triggerType}
                  triggerKeys={triggerKeys}
                  triggerValue={triggerValue}
                  triggerBlurred={triggerBlurred}
                  size={"40px"}
                  setTriggerValue={val => {
                    setTriggerValue(val)
                  }}
                />
              </GridItem>
              <GridItem xs={3} className={classes.sentenceDetail}>
                <TextField
                  label="TRIGGER WEIGHT"
                  type="number"
                  name="triggerWeight"
                  id="triggerWeight"
                  value={sentenceFormValues.triggerWeight}
                  onChange={handleChange}
                  readonly={loadingSentence}
                />
              </GridItem>
            </Grid>
          </Card>
        )}
        <Box>
          <Grid>
            <CTAAccordion
              orgId={narrative?.organization?.id}
              leagueId={narrative?.leagueId}
              verticalId={narrative?.verticalId}
              handleAddCTAVariant={handleAddCTAVariant}
            />
          </Grid>
        </Box>
        {!sentenceFormValues.isLibraryPlaceholder && (
          <Box>
            {(contentBlock === "categories" ||
              contentBlock === "dscategories" ||
              contentBlock === "outputexperimentlog" ||
              contentBlock === "tags" ||
              contentBlock === "entrytags" ||
              contentBlock === "metadata" ||
              contentBlock === "interestgroups") && (
              <div>
                <div className={classes.editorOptions}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={currentEditor === "code"}
                        onChange={e => setCurrentEditor(e.target.value)}
                        value="code"
                        name="Code Editor"
                        aria-label="Code"
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label="Code Editor"
                  />
                  {(contentBlock === "categories" ||
                    contentBlock === "dscategories" ||
                    contentBlock === "outputexperimentlog" ||
                    contentBlock === "entrytags" ||
                    contentBlock === "metadata" ||
                    contentBlock === "interestgroups") && (
                    <FormControlLabel
                      control={
                        <Radio
                          checked={currentEditor === "categories"}
                          onChange={e => setCurrentEditor(e.target.value)}
                          value="categories"
                          name="Category Categories"
                          aria-label="Category Editor"
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Category Editor"
                    />
                  )}
                  {contentBlock === "tags" && (
                    <FormControlLabel
                      control={
                        <Radio
                          checked={currentEditor === "tags"}
                          onChange={e => setCurrentEditor(e.target.value)}
                          value="tags"
                          name="Content Tags"
                          aria-label="Tag Editor"
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Tag Editor"
                    />
                  )}
                </div>
              </div>
            )}
            {currentEditor === "code" && (
              <div>
                <CodeEditor
                  withTabs
                  template={sentenceTemplate}
                  handleChange={e => handleCodeEditorChange(e)}
                />
                {templateError && (
                  <FormHelperText className={classes.requiredMsg}>
                    The Code Editor must have content
                  </FormHelperText>
                )}
              </div>
            )}
            {currentEditor === "categories" && (
              <NarrativeCategories
                name={"template"}
                categories={sentenceFormValues.template}
                onChange={event => {
                  const newFormValues = {
                    ...sentenceFormValues,
                    template: event.target.value
                  }
                  if (sentenceFormValues.template !== event.target.value) {
                    setSentenceFormValues(newFormValues)
                    setLastTemplateEdit("Categories")
                  }
                }}
              />
            )}
            {currentEditor === "tags" && (
              <NarrativeTags
                name={"template"}
                tags={sentenceFormValues.template || "[]"}
                onChange={event => {
                  const newFormValues = {
                    ...sentenceFormValues,
                    template: event.target.value
                  }
                  setSentenceFormValues(newFormValues)
                }}
              />
            )}
          </Box>
        )}

        {loadingSentence && <LinearProgress />}
        <Grid className={classes.modalButton}>
          <>
            {!sentenceFormValues.isLibraryPlaceholder && (
              <>
                <Button
                  className={classes.buttonStyle}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                    setDoValidateJSON(false)
                    buildPreview()
                  }}
                  disabled={loadingSentence || !narrative.queryId}
                  title={
                    !narrative.queryId
                      ? "Select a Query to Preview"
                      : "Preview Sentence"
                  }
                >
                  Preview
                </Button>
                <Button
                  className={classes.buttonStyle}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={() => {
                    setDoValidateJSON(true)
                    buildPreview()
                  }}
                  disabled={loadingSentence || !narrative.queryId}
                  title={
                    !narrative.queryId
                      ? "Select a Query to Preview"
                      : "Preview Sentence"
                  }
                >
                  Check JSON
                </Button>
              </>
            )}
            {narrative.id !== sentenceFormValues.narrative_Id && (
              <Button
                title="Opens new window"
                onClick={() => {
                  window.open(
                    `/portal/narrative/${sentenceFormValues.narrative_Id}/edit?view=sentenceid-${sentenceFormValues.id}`,
                    "_blank"
                  )
                }}
                color="primary"
              >
                View In Library
              </Button>
            )}
            <Button
              className={classes.buttonStyle}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            {narrative.id === sentenceFormValues.narrative_Id && (
              <Button
                className={classes.buttonStyle}
                variant="contained"
                size="medium"
                color="primary"
                type="submit"
                onClick={() => {
                  handleSave()
                }}
                //   disabled={disableSaveButton}
              >
                Save
              </Button>
            )}
          </>
        </Grid>
      </ThemeProvider>
      {isLoadingPreview && <LinearProgress />}
      {!sentenceFormValues.isLibraryPlaceholder && (
        <React.Fragment>
          <div style={{ marginTop: "10px" }} />
          <WordCounter
            title="Metrics"
            inputString={previewResponse.preview}
            renderTimeInMilliseconds={
              previewResponse.previewResponsepreviewElapsedTimeMilliseconds
            }
          />
          <Card>
            <Grid container spacing={1} item xs={12}>
              <Grid item>
                {/* {isLoadingPreview && <CircularProgress />} */}
                {!isLoadingPreview && (
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      position: "relative",
                      marginLeft: "5px"
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        previewResponse.preview &&
                        Helpers.constructPreviewText(
                          doValidateJSON,
                          previewResponse.preview
                        )
                    }}
                    ref={contentRef}
                  />
                )}
              </Grid>
            </Grid>
            {doValidateJSON && !isLoadingPreview && (
              <p
                style={{
                  color: Helpers.parseJSON(previewResponse.preview).isValidJSON
                    ? "#66BB6A"
                    : "#D01111"
                }}
              >
                {!Helpers.parseJSON(previewResponse.preview).isValidJSON && (
                  <Report />
                )}
                {Helpers.parseJSON(previewResponse.preview).isValidJSON && (
                  <Check />
                )}
                &nbsp;
                {Helpers.parseJSON(previewResponse.preview).invalidJSONMessage}
              </p>
            )}
          </Card>
        </React.Fragment>
      )}
    </>
  )
}

export default SentenceEditor
