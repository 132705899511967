import React, {
  useEffect,
  useState,
  useMemo //, useCallback
} from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
//import RealTimeDAO from "daos/realTimeDAO"
//import { makeStyles } from "@mui/styles"
import {
  Autocomplete,
  Card,
  CardContent,
  CardActions,
  //Dialog,
  //DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material"

import Button from "components/CustomButtons/Button"
import RealTimeLibraryStatus from "./RealTimeLibraryStatus"
//import { useHistory } from "react-router-dom"
import { Cookies } from "tools/storage"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getRealTimeLibraryById: store.realTimeStore.getRealTimeLibraryById,
    realTimeLibraryById: store.realTimeStore.realTimeLibraryById,
    getRealTimeLibraries: store.realTimeStore.getRealTimeLibraries,
    realTimeLibraries: store.realTimeStore.realTimeLibraries,
    putLibraryById: store.realTimeStore.putLibraryById,
    getRealTimeLeague: store.realTimeStore.getRealTimeLeague,
    insightLeague: store.realTimeStore.insightLeague,
    getVariantVerticals: store.ctav2Store.getVariantVerticals,
    verticals: store.ctav2Store.verticals,
    getOrganizations: store.organizationStore.getOrganizations,
    organizations: store.organizationStore.organizations,
    // outputAttributes: store.narrativeStore.outputAttributes,
    // getOutputAttributes: store.narrativeStore.getOutputAttributes,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen,
    getRealTimeOutputTypes: store.realTimeStore.getRealTimeOutputTypes,
    getRealTimeDeliveryTypes: store.realTimeStore.getRealTimeDeliveryTypes,
    realTimeDeliveryTypes: store.realTimeStore.realTimeDeliveryTypes,
    realTimeOutputTypes: store.realTimeStore.realTimeOutputTypes
  }))
}

const RealTimeOutputForm = ({
  mode,
  onClose,
  step,
  closePage,
  errorValues,
  onSave,
  onModeChange,
  libraryData,
  sendFormValues,
  setActivateSave
}) => {
  const inCreateMode = mode === "create"
  const inEditMode = mode === "edit"
  //const history = useHistory()
  const _SPORTS = 1
  const cookies = new Cookies()
  const cCurrentOrg = Number(cookies.get("currentorg")) || 0
  let initProp = cookies.get("currentProperty") || ""
  //const classes = useStyles()
  const {
    getRealTimeLibraryById,
    realTimeLibraryById,
    getRealTimeLibraries,
    realTimeLibraries,
    getRealTimeLeague,
    insightLeague,
    verticals,
    getOrganizations,
    organizations,
    getVariantVerticals,
    getRealTimeOutputTypes,
    getRealTimeDeliveryTypes,
    realTimeDeliveryTypes,
    realTimeOutputTypes
  } = useStoreData()
  const defaultVerticalOption = { id: 1, name: "Sports" }
  // eslint-disable-next-line no-unused-vars
  const insightTypeIds = []
  libraryData?.insightTypes?.forEach(x => {
    insightTypeIds.push(x.id)
  })
  const initialFormValues = {
    id: libraryData?.id || 0,
    libraryId: libraryData?.id || 0,
    organizationId: initProp || cCurrentOrg,
    name: libraryData?.name || "",
    status: libraryData?.status || { id: null, name: "" },
    verticalId: defaultVerticalOption,
    insightTypeIds: insightTypeIds || [],
    leagueId: libraryData?.league || null
  }

  const [formValues, setFormValues] = useState(initialFormValues)
  const [formErrors, setFormErrors] = useState({})
  const [formStep, setFormStep] = useState(step)
  const [reRenderStepForm, setReRenderStepForm] = useState(false)
  const [showInsigthTuning, setShowInsigthTuning] = useState(false)

  //const [outputFormErrors, setOutputFormErrors] = useState({})

  useEffect(() => {
    getRealTimeOutputTypes()
    getRealTimeDeliveryTypes()
    getRealTimeLeague()
    if (!verticals || verticals?.length === 0) {
      getVariantVerticals()
    }
    const tempOrgs = organizations?.toJS()
    if (!tempOrgs || tempOrgs?.length === 0) {
      getOrganizations()
    }
    //getOutputAttributes()
    getRealTimeLibraries()
  }, [])

  useEffect(() => {
    if (libraryData) {
      const insightTypeIds = []
      libraryData?.insightTypes?.forEach(x => {
        insightTypeIds.push(x.id)
      })
      setFormValues({
        id: libraryData.id,
        organizationId: initProp || cCurrentOrg,
        name: libraryData.name || "No Name from data",
        status: libraryData.status || { id: null, name: "" },
        verticalId: libraryData.vertical?.id || defaultVerticalOption,
        leagueId: libraryData.league || null,
        insightTypeIds: insightTypeIds || [],
        deliveryType: { id: null, name: "" },
        outputType: { id: null, name: "" }
      })
    }
  }, [libraryData])

  useEffect(() => {
    if (errorValues) {
      console.log("We have error values", errorValues)
      const errObj = {}
      errorValues?.forEach(x => {
        errObj[x.name] = x.msg
      })
      setFormErrors(errObj)
    } else {
      setFormErrors({})
    }
  }, [errorValues])

  useEffect(() => {
    if (formValues) {
      sendFormValues(formValues)
    }
  }, [formValues])

  useEffect(() => {
    if (
      realTimeLibraryById?.id > 0 &&
      realTimeLibraryById.id === formValues.libraryId?.id
    ) {
      setShowInsigthTuning(true)
    } else {
      setShowInsigthTuning(false)
    }
  }, [realTimeLibraryById])

  useEffect(() => {
    if (step > -1) {
      console.log(`We are now on step ${step}`)
      setFormStep(step)
      if (step === 1) {
        if (realTimeLibraryById?.id === formValues.libraryId?.id) {
          setShowInsigthTuning(true)
        } else {
          setShowInsigthTuning(false)
          if (formValues.libraryId?.id > 0) {
            getRealTimeLibraryById(formValues.libraryId?.id)
          }
        }
      } else if (step === 2) {
      }
    } else {
      console.log(`Bad value for step: ${step}`)
    }
  }, [step])

  useEffect(() => {
    if (reRenderStepForm) {
      setReRenderStepForm(false)
    }
  }, [reRenderStepForm])

  const handleCancel = () => {
    if (inCreateMode) {
      setFormValues({
        name: "",
        status: { id: null, name: "" },
        verticalId: null,
        leagueId: null,
        deliveryType: { id: null, name: "" },
        outputType: { id: null, name: "" }
      })
    }
    if (closePage) {
      closePage()
    }
    if (onClose) {
      onClose()
    } else {
      setFormValues(initialFormValues)
      if (onModeChange) {
        onModeChange("view")
      }
    }
  }

  const handleFieldChanges = (field, value) => {
    setFormValues(prevValues => ({
      ...prevValues,
      [field]: value
    }))
  }

  const handleAutocompleteChangeOrg = (event, newValue, reason, name) => {
    if (reason === "clear") {
      const newFormValues = {
        ...formValues,
        [name]: null
      }
      setFormValues(newFormValues)
    } else {
      const newFormValues = {
        ...formValues,
        [name]: newValue?.id || null
      }
      setFormValues(newFormValues)
    }
  }

  const handleSave = () => {}

  const renderViewField = (label, value) => (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">
          {typeof value === "object" && value !== null ? value.name : value}
        </Typography>
      </Grid>
    </Grid>
  )

  const isSaveValid = fv => {
    const insightTypeId = []
    fv.libraryId?.insightTypes?.forEach(x => {
      if (fv[x.name] === true) {
        insightTypeId.push(x.id)
      }
    })
    return insightTypeId.length > 0 ? true : false
  }

  const handleCheckboxChange = name => e => {
    const newFormValues = {
      ...formValues,
      disableSaveButton: false,
      [e.target.id]: e.target.checked
    }
    setFormValues(newFormValues)
    setActivateSave(isSaveValid(newFormValues))
  }

  const orgsAndChildProperties = useMemo(() => {
    const temp = []
    organizations?.toJS().forEach(x => {
      temp.push({ ...x, parentName: x.name })
      x.properties?.forEach(y => {
        temp.push({ ...y, parentName: x.name })
      })
    })
    return temp
  }, [organizations])

  const formContentStep1 = (
    <>
      <CardContent aria-label="RealTime Output CreatorForm FormCard Step 1">
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <TextField
                aria-label="RealTime Output CreatorForm NameField"
                sx={{ width: "100%" }}
                name="name"
                id="realTimeName"
                label="NAME"
                value={formValues.name}
                error={!!formErrors.name}
                helperText={formErrors.name ? "Name required" : ""}
                required
                disabled={!inEditMode && !inCreateMode}
                onChange={e => handleFieldChanges("name", e.target.value)}
              />
            ) : (
              renderViewField("NAME: ", formValues.name)
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <Autocomplete
                aria-label="RealTime Output CreatorForm OutputTypeField"
                readOnly={!inCreateMode && !inEditMode}
                id="league"
                options={realTimeOutputTypes}
                value={formValues.outputType}
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="OUTPUT TYPE"
                    required={true}
                    error={!!formErrors.outputType}
                    helperText={formErrors.outputType}
                    disabled={!inEditMode && !inCreateMode}
                  />
                )}
                onChange={(event, newValue) => {
                  handleFieldChanges("outputType", newValue)
                }}
              />
            ) : (
              renderViewField("OUTPUT TYPE: ", formValues.outputType)
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <Autocomplete
                aria-label="RealTime Output CreatorForm DeliveryTypeField"
                readOnly={!inCreateMode && !inEditMode}
                id="league"
                options={realTimeDeliveryTypes}
                value={formValues.deliveryType}
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="DELIVERY TYPE"
                    required={true}
                    error={!!formErrors.deliveryType}
                    helperText={formErrors.deliveryType}
                    disabled={!inEditMode && !inCreateMode}
                  />
                )}
                onChange={(event, newValue) => {
                  handleFieldChanges("deliveryType", newValue)
                }}
              />
            ) : (
              renderViewField("DELIVERY TYPE: ", formValues.deliveryType)
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <>
                {orgsAndChildProperties?.length > 0 && (
                  <Autocomplete
                    aria-label="RealTime Output CreatorForm OrganizationidField"
                    fullWidth
                    options={
                      orgsAndChildProperties?.slice().sort(function (a, b) {
                        return a.parentName &&
                          a.parentName.localeCompare(b.parentName) !== 0
                          ? a.parentName.localeCompare(b.parentName)
                          : a.name === a.parentName
                          ? -1
                          : b.name === b.parentName
                          ? 1
                          : a.name && a.name.localeCompare(b.name)
                      }) || []
                    }
                    required={true}
                    getOptionLabel={option => option.name}
                    renderOption={(props, option) =>
                      option.parentName !== option.name ? (
                        <li
                          {...props}
                          style={{
                            //textDecoration: "underline",
                            //listStyleType: "circle",
                            marginLeft: "10px" //,
                            //color: "#234"
                            //display: "inline-block"
                          }}
                        >
                          {"  "}
                          {option.name}
                        </li>
                      ) : (
                        <span {...props}>{option.name}</span>
                      )
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="ORGANIZATION"
                        variant="outlined"
                        key={params.id}
                        value={params.name}
                        required={true}
                        error={formErrors.organizationId}
                        helperText={
                          formErrors.organizationId
                            ? "Organization is Required"
                            : ""
                        }
                      />
                    )}
                    value={orgsAndChildProperties?.find(org => {
                      if (org.id === formValues.organizationId) {
                        return true
                      } else {
                        return false
                      }
                    })}
                    onChange={(event, newValue, reason) => {
                      handleAutocompleteChangeOrg(
                        event,
                        newValue,
                        reason,
                        "organizationId"
                      )
                    }}
                    disabled={false}
                  />
                )}
              </>
            ) : (
              renderViewField(
                "Organization name: ",
                formValues.organizationName
              )
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <Autocomplete
                aria-label="RealTime Output CreatorForm VerticalField"
                readOnly={!inCreateMode && !inEditMode}
                id="vertical"
                options={verticals}
                value={formValues.verticalId}
                getOptionLabel={option => option.name || ""}
                // isOptionEqualToValue={(option, value) =>
                //   option.id === value?.id
                // }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="VERTICAL"
                    required
                    error={!!formErrors.verticalId}
                    helperText={formErrors.verticalId}
                    disabled={!inEditMode && !inCreateMode}
                  />
                )}
                onChange={(e, newValue) =>
                  handleFieldChanges("verticalId", newValue)
                }
              />
            ) : (
              renderViewField("VERTICAL: ", defaultVerticalOption.name)
            )}
          </Grid>
          {formValues.verticalId?.id === _SPORTS && (
            <Grid item xs={12}>
              {inCreateMode || inEditMode ? (
                <Autocomplete
                  aria-label="RealTime Output CreatorForm LeagueField"
                  readOnly={!inCreateMode && !inEditMode}
                  id="leagueId"
                  options={insightLeague}
                  value={formValues.leagueId}
                  getOptionLabel={option => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="ASSOCIATED LEAGUE"
                      required={true}
                      error={!!formErrors.leagueId}
                      helperText={formErrors.leagueId}
                      disabled={!inEditMode && !inCreateMode}
                    />
                  )}
                  onChange={(event, newValue) => {
                    handleFieldChanges("leagueId", newValue)
                  }}
                />
              ) : (
                renderViewField("ASSOCIATED LEAGUE: ", formValues.leagueId)
              )}
            </Grid>
          )}
          {realTimeLibraries?.length > 0 && (
            <Grid item xs={12}>
              {inCreateMode || inEditMode ? (
                <Autocomplete
                  readOnly={!inCreateMode && !inEditMode}
                  aria-label="RealTime Output CreatorForm libraryIdField"
                  id="libraryId"
                  options={realTimeLibraries}
                  value={formValues.libraryId}
                  getOptionLabel={option =>
                    option?.name && option?.id
                      ? `${option.id} - ${option.name}`
                      : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="ASSOCIATED LIBRARY"
                      required={true}
                      error={!!formErrors.libraryId}
                      helperText={formErrors.libraryId}
                      disabled={!inEditMode && !inCreateMode}
                    />
                  )}
                  onChange={(event, newValue) => {
                    handleFieldChanges("libraryId", newValue)
                  }}
                />
              ) : (
                renderViewField("ASSOCIATED LIBRARY: ", formValues.libraryId)
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <RealTimeLibraryStatus
                aria-label="RealTime Output CreatorForm StatusField"
                value={formValues.status}
                onChange={newValue => {
                  setFormValues(prev => ({
                    ...prev,
                    status: newValue
                      ? { id: newValue.id, name: newValue.name }
                      : null
                    //status: newValue ? newValue.name : null
                  }))
                }}
                statusErrorMsg={formErrors.status}
              />
            ) : (
              renderViewField("STATUS: ", formValues.status)
            )}
          </Grid>
        </Grid>
      </CardContent>
    </>
  )

  const formContentStep2 = (
    <>
      {showInsigthTuning && (
        <>
          <CardContent aria-label="RealTime Output CreatorForm FormCard Step 2">
            {formErrors.insightTypeIds && (
              <div
                style={{
                  display: "inline-block",
                  color: "#c00",
                  border: "2px solid #c00",
                  fontWeight: "bold",
                  padding: "5px"
                }}
              >
                {formErrors.insightTypeIds?.msg}
              </div>
            )}
            {realTimeLibraryById?.insightTypes?.map(x => (
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    aria-label={`RealTime Output CreatorForm InsightCheckbox ${x.name}Field`}
                    key={x.name}
                    control={
                      <Checkbox
                        id={x.name}
                        checked={formValues[x.name]}
                        onChange={handleCheckboxChange(x.name)}
                        value={x.id}
                        //aria-label="org-internal-checkbox"
                      />
                    }
                    label={
                      <Typography variant="body2" color="secondary">
                        {x.name}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </CardContent>
        </>
      )}
    </>
  )

  return (
    <>
      {inCreateMode ? (
        // <Dialog open={true} onClose={handleCancel} fullWidth maxWidth="md">
        <>
          <DialogTitle>Real Time Output Create</DialogTitle>
          {!reRenderStepForm && (
            <DialogContent>
              {formStep === 0
                ? formContentStep1
                : formStep === 1
                ? formContentStep2
                : null}
            </DialogContent>
          )}
          {/* <CardActions style={{ justifyContent: "flex-end" }}>
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          </CardActions> */}
        </> // </Dialog>
      ) : (
        <Card style={{ maxWidth: "100%" }} elevation={0}>
          <Typography variant="h6" component="div" style={{ padding: "16px" }}>
            Real Time Output Info - {inEditMode ? "Edit" : "View"}
          </Typography>
          {formStep === 0
            ? formContentStep1
            : formStep === 1
            ? formContentStep2
            : null}
          {inEditMode && (
            <CardActions style={{ justifyContent: "flex-end" }}>
              <Button color="secondary" onClick={handleCancel} size="sm">
                Cancel
              </Button>
              <Button color="primary" onClick={handleSave} size="sm">
                Save
              </Button>
            </CardActions>
          )}
        </Card>
      )}
    </>
  )
}
export default RealTimeOutputForm
