import React, { useEffect, useState } from "react"
import { IconButton, Switch, TextField, Chip } from "@mui/material"
import { makeStyles } from "@mui/styles"
import Autocomplete from "@mui/material/Autocomplete"
import { Link } from "react-router-dom"
import { CircularProgress } from "@mui/material"

import { Cookies } from "tools/storage"
import CustomPopper from "components/Popper"
import SaturationChip from "components/ContentSaturation/SaturationChip"
import TestResults from "components/NarrativeAdmin/Data/TestResults"

import { Save, Cancel } from "@mui/icons-material"

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAutocomplete-endAdornment": {
      position: "absolute"
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    },
    "& input:disabled": {
      //opacity: "0.7 !important",
      color: "#333"
    }
  },
  inputRoot: {
    fontWeight: 400,
    fontSize: "13px",
    height: "32px",
    paddingLeft: "5px"
  },
  acOption: {
    fontWeight: 400,
    fontSize: "13px",
    '&[aria-selected="true"]': {
      backgroundColor: "#bdbdbd"
    }
  },
  cellContent: {
    fontSize: "inherit",
    padding: 5,
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  cellContentDisabled: {
    fontSize: "inherit",
    padding: 5,
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    opacity: 0.7
  },
  editableDisabled: {
    opacity: 0.8
  },
  editable: {
    opacity: 1
  }
}))

// Create an editable cell renderer
export const EditableCell = ({
  getValue,
  row,
  column: { id, type },
  table
}) => {
  const { editable, editableDisabled } = useStyles()
  const { index } = row
  const initialValue = getValue()
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    table.options.meta?.handleDataChange(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  let inputObject = (
    <input
      type={type}
      value={value}
      min={0}
      onChange={onChange}
      onBlur={onBlur}
      className={row.getIsSelected() ? editable : editableDisabled}
      readOnly={!row.getIsSelected()}
    />
  )
  if (type === "checkbox") {
    return (
      <div
        style={{
          padding: "5px",
          textAlign: "center"
        }}
      >
        {inputObject}
      </div>
    )
  }
  return inputObject
}

// Create an editable cell renderer
export const SwitchInput = ({ getValue, row, column: { id }, table }) => {
  const { index } = row
  const initialValue = getValue() || false
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)
  const { editable, editableDisabled } = useStyles()

  const onChange = () => {
    const newValue = !value
    setValue(newValue)
    table.options.meta?.handleDataChange(index, id, newValue)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <div
      style={{
        paddingTop: "3px",
        paddingBottom: "4px",
        textAlign: "center"
      }}
    >
      {value ? "False" : <strong>False</strong>}
      <Switch
        size="small"
        checked={value}
        onClick={onChange}
        disabled={!row.getIsSelected()}
        className={row.getIsSelected() ? editable : editableDisabled}
      />
      {value ? <strong>True</strong> : "True"}
    </div>
  )
}

export const BasicCell = ({ getValue, row }) => {
  const { cellContent, cellContentDisabled } = useStyles()
  return (
    <div className={row.getIsSelected() ? cellContent : cellContentDisabled}>
      {getValue()}
    </div>
  )
}

export const LinkParagraph = ({ row }) => {
  const { id, isFromLibrary, narrative_Id } = row.original
  const { editable, editableDisabled } = useStyles()
  let titleText = `Open Paragraph ${id}`
  if (isFromLibrary) {
    titleText = `Open Library Paragraph ${id} in new window`
  }
  const linkTo =
    row.original.narrativeOutlineBlockId &&
    Number(row.original.narrativeOutlineBlockId) > 0
      ? `/portal/narrative/${narrative_Id}/edit?view=outlineparagraphid-${row.original.id}`
      : `/portal/narrative/${narrative_Id}/edit?view=paragraphid-${row.original.id}`
  const target = isFromLibrary ? "_blank" : "_self"

  return (
    <div className="tool-cell">
      <Link
        title={titleText}
        to={linkTo}
        target={target}
        className={row.getIsSelected() ? editable : editableDisabled}
      >
        {row.original.id}
      </Link>
    </div>
  )
}

export const LinkSentence = ({ row }) => {
  const { id, isFromLibrary, narrative_Id } = row.original
  const { editable, editableDisabled } = useStyles()
  let titleText = `Open Sentence ${id}`
  if (isFromLibrary) {
    titleText = `Open Library Sentence ${id} in new window`
  }
  const linkTo = `/portal/narrative/${narrative_Id}/edit?view=sentenceid-${row.original.id}`
  const target = isFromLibrary ? "_blank" : "_self"

  return (
    <div className="tool-cell">
      <Link
        title={titleText}
        to={linkTo}
        target={target}
        className={row.getIsSelected() ? editable : editableDisabled}
      >
        {row.original.id}
      </Link>
    </div>
  )
}

export const LinkTriggerCell = ({ row }) => {
  const { id, isFromLibrary, narrative_Id } = row.original
  const { editable, editableDisabled } = useStyles()
  let titleText = `Open Trigger ${id}`
  if (isFromLibrary) {
    titleText = `Open Library Trigger ${id} in new window`
  }
  const linkTo = `/portal/narrative/${narrative_Id}/edit?view=triggerid-${row.original.id}`
  const target = isFromLibrary ? "_blank" : "_self"

  return (
    <div className="tool-cell">
      <Link
        title={titleText}
        to={linkTo}
        target={target}
        className={row.getIsSelected() ? editable : editableDisabled}
      >
        {row.original.id}
      </Link>
    </div>
  )
}

export const SaturationChipInput = ({ row }) => (
  <SaturationChip
    saturation={row.getValue("saturation")}
    utilization={row.getValue("utilization")}
  />
)

export const RenderTypeInput = ({ getValue, row, column: { id }, table }) => {
  const { root, inputRoot, acOption, muiD } = useStyles()
  const { index } = row
  const onChange = (e, option) => {
    table.options.meta?.handleDataChange(index, id, option)
  }
  const options = [
    "",
    "none",
    "p",
    "div",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "ol",
    "ul",
    "li",
    "aside",
    "figure",
    "footer",
    "header",
    "section",
    "time",
    "apxh:p",
    "apxh:div"
  ]

  // Render a multi-select box
  return (
    <Autocomplete
      classes={{ root, inputRoot, option: acOption, noOptions: acOption, muiD }}
      id={id}
      value={getValue()}
      options={options}
      onChange={onChange}
      isOptionEqualToValue={(option, value) =>
        option && value ? option === value : false
      }
      renderInput={params => <TextField {...params} />}
      disabled={!row.getIsSelected()}
    />
  )
}

export const SelectRowCell = ({ row, column, table }) => {
  const { changedRows = [] } = table.options.meta
  const {
    removeJustSavedRowFromFilter,
    saveItem,
    cancelChanges,
    loadSelectedRow
  } = column.columnDef.meta
  return (
    <div className="tool-cell">
      <span style={{ paddingRight: "5px" }}>
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          onChange={() => {
            row.original.editEnabled = !row.getIsSelected()
            row.toggleSelected()
            row.getToggleSelectedHandler()
            loadSelectedRow(row)
            //}
          }}
        />
      </span>
      {(changedRows.includes(row.index) || row.original.id === null) && (
        <>
          <IconButton
            size="small"
            style={{ padding: "0" }}
            aria-label="Save Changes"
            onClick={() => {
              removeJustSavedRowFromFilter(row)
              return saveItem(row)
            }}
          >
            <Save />
          </IconButton>
          <IconButton
            size="small"
            style={{ padding: "0" }}
            aria-label="Cancel Changes"
            onClick={() => cancelChanges(row)}
          >
            <Cancel />
          </IconButton>
        </>
      )}
      {(row.original.isTesting || row.original.testComplete) && (
        <TestResults isSentence={true} row={row} />
      )}
      {(row.original.isLoadingRow || row.isLoadingRow) && (
        <CircularProgress thickness={4} size={20} />
      )}
    </div>
  )
}

export const ContentElementInput = ({
  getValue,
  row,
  column: { id },
  table
}) => {
  const { index } = row
  const value = getValue()
  // We need to keep and update the state of the cell normally
  const { root, inputRoot, acOption, muiD } = useStyles()

  const onChange = (e, option) => {
    table.options.meta?.handleDataChange(
      index,
      id,
      option ? option.value : null
    )
  }
  const options = [
    { label: "EntryId", value: "entryid" },
    { label: "Title", value: "title" },
    { label: "SEOTitle", value: "seotitle" },
    { label: "SEOSlug", value: "seoslug" },
    { label: "Link", value: "link" },
    { label: "Expired On", value: "expiredon" },
    { label: "Summary", value: "summary" },
    { label: "Meta Description", value: "metadescription" },
    { label: "Rights", value: "rights" },
    { label: "Library", value: "library" },
    { label: "Categories (JSON)", value: "categories" },
    { label: "DS Categories (JSON)", value: "dscategories" },
    { label: "Output Experiment Log (JSON)", value: "outputexperimentlog" },
    { label: "Tags (JSON)", value: "tags" },
    { label: "Featured Image Object (JSON)", value: "featuredimagejson" },
    { label: "Intro Paragraph", value: "introparagraph" },
    { label: "Content", value: "content" },
    { label: "Bottom Heading", value: "bottomheading" },
    { label: "Bottom Paragraph", value: "bottomparagraph" },
    { label: "SchemaOrg", value: "schemaorg" },
    { label: "AP ByLine", value: "byline" },
    {
      label: "AP Characteristic Media Type",
      value: "apcontentmanagementcharacteristicsmediatype"
    },
    { label: "AP DateLine", value: "apdateline" },
    { label: "AP Extended Headline", value: "extendedheadline" },
    { label: "AP Headline", value: "headline" },
    { label: "AP Management Id", value: "managementid" },
    { label: "AP Management Type", value: "managementtype" },
    {
      label: "AP Publishing Special Instructions",
      value: "apnewsmanagementpublishingspecialinstructions"
    },
    {
      label: "AP Publishing Status",
      value: "apnewsmanagementpublishingstatus"
    },
    {
      label: "AP Sequence Number",
      value: "apnewsmanagementmanagementsequencenumber"
    },
    { label: "AP SlugLine", value: "slugline" },
    {
      label: "Message Type ID",
      value: "messagetypeid"
    },
    {
      label: "Insight Score",
      value: "insightscore"
    },
    {
      label: "Delivery Type ID",
      value: "deliverytypeid"
    },
    {
      label: "Media URL",
      value: "mediaurl"
    },
    {
      label: "EntryTags (JSON)",
      value: "entrytags"
    },
    {
      label: "Metadata (JSON)",
      value: "metadata"
    },
    {
      label: "Interest Groups (JSON)",
      value: "interestgroups"
    }
  ]

  const selected = options.find(option => {
    if (option && value === option.value) {
      return option
    }
    return false
  })

  // Render a multi-select box
  return (
    <Autocomplete
      classes={{ root, inputRoot, option: acOption, noOptions: acOption, muiD }}
      id={id}
      value={value}
      options={options}
      onChange={onChange}
      PopperComponent={CustomPopper}
      inputValue={(selected && selected.label) || ""}
      getOptionLabel={option => (option && option.label) || ""}
      isOptionEqualToValue={(option, value) =>
        option && value && option.value === value
      }
      renderInput={params => <TextField {...params} />}
      disabled={!row.getIsSelected()}
    />
  )
}

export const SentenceTypeInput = ({ getValue, row, column: { id }, table }) => {
  const { index } = row
  const value = getValue()
  const { root, inputRoot, acOption, muiD } = useStyles()

  const onChange = (e, option) => {
    table.options.meta?.handleDataChange(
      index,
      id,
      option ? option.value : null
    )
  }
  const options = [
    { label: "Intro/Title", value: "Introduction" },
    { label: "Supporting", value: "Supporting Sentence" },
    { label: "Transition", value: "Transition" },
    { label: "Conclusion", value: "Conclusion" }
  ]

  const selected = options.find(o => o.value === value)
  // Render a multi-select box
  return (
    <Autocomplete
      classes={{ root, inputRoot, option: acOption, noOptions: acOption, muiD }}
      id={id}
      value={selected}
      options={options}
      onChange={onChange}
      inputValue={(selected && selected.label) || ""}
      getOptionLabel={option => (option ? option.label : "")}
      isOptionEqualToValue={(option, value) =>
        option && value ? option.value === value.value : false
      }
      renderInput={params => <TextField {...params} />}
      disabled={!row.getIsSelected()}
    />
  )
}

export const ParagraphTypeInput = ({
  getValue,
  row,
  column: { id },
  table
}) => {
  const { index } = row
  const value = getValue()
  const { root, inputRoot, acOption, muiD } = useStyles()

  const onChange = (e, option) => {
    table.options.meta?.handleDataChange(
      index,
      id,
      option ? option.value : null
    )
  }

  const options = table.options.meta?.primaryTagOptions || [
    { label: "Old", value: "Old" },
    { label: "Intro", value: "Intro" },
    { label: "Team", value: "Team" },
    { label: "Player", value: "Player" },
    { label: "Match", value: "Match" },
    { label: "League", value: "League" },
    { label: "Non-Content/Meta Content", value: "Non Content" },
    { label: "Misc", value: "Misc" }
  ]

  const selected = options.find(o => o.value === value)
  // Render a multi-select box
  return (
    <Autocomplete
      classes={{ root, inputRoot, option: acOption, noOptions: acOption, muiD }}
      id={id}
      value={selected}
      options={options}
      onChange={onChange}
      inputValue={(selected && selected.label) || ""}
      getOptionLabel={option => (option ? option.label : "")}
      isOptionEqualToValue={(option, value) =>
        option && value ? option.value === value.value : false
      }
      renderInput={params => <TextField {...params} />}
      disabled={!row.getIsSelected()}
    />
  )
}

export const AutocompleteInput = (
  { getValue, row, column: { id }, table },
  options
) => {
  const { index } = row
  const value = getValue()
  const { root, inputRoot, acOption, muiD } = useStyles()

  const onChange = (e, option) => {
    table.options.meta?.handleDataChange(
      index,
      id,
      option ? option.value : null
    )
  }

  // Render a multi-select box
  return (
    <Autocomplete
      classes={{ root, inputRoot, option: acOption, noOptions: acOption, muiD }}
      id={id}
      value={options?.find(o => o.value === value)}
      options={options || []}
      onChange={onChange}
      getOptionLabel={option => (option ? option.label : "")}
      isOptionEqualToValue={(option, value) =>
        option && value ? option.value === value.value : false
      }
      renderInput={params => <TextField {...params} />}
      disabled={!row.getIsSelected()}
    />
  )
}

export const AutocompleteMultiInput = (
  { getValue, row, column: { id }, table },
  options
) => {
  const { index } = row
  const value = getValue()
  const { root, inputRoot, acOption, muiD } = useStyles()

  const onChange = (e, option) => {
    table.options.meta?.handleDataChange(
      index,
      id,
      option ? option.map(obj => obj.value) : null
    )
  }

  // Render a multi-select box
  return (
    <Autocomplete
      multiple
      limitTags={1}
      classes={{ root, inputRoot, option: acOption, noOptions: acOption, muiD }}
      id={id}
      value={options?.filter(({ id: tagId }) =>
        value?.some(id => id === tagId)
      )}
      options={options || []}
      onChange={onChange}
      disabled={!row.getIsSelected()}
      getOptionLabel={option => (option ? option.label : "")}
      isOptionEqualToValue={(option, value) =>
        option && value ? option.value === value.value : false
      }
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: null,
            endAdornment: (
              <div className="MuiAutocomplete-endAdornment">
                {params.InputProps.startAdornment}
                {params.InputProps.endAdornment.props.children}
              </div>
            )
          }}
          inputProps={{
            ...params.inputProps,
            style: { width: "50%" }
          }}
        />
      )}
      renderTags={(value, getTagProps) => {
        const numTags = value.length
        const limitTags = 1

        return (
          <>
            {value?.slice(0, limitTags).map((option, index) => (
              <Chip
                style={{ fontSize: 13 }}
                size="small"
                {...getTagProps({ index })}
                key={index}
                label={option.name}
              />
            ))}

            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        )
      }}
      ChipProps={{
        size: "small",
        style: {
          fontSize: 13
        }
      }}
    />
  )
}
export const StatusTypeInput = ({ getValue, row, column: { id }, table }) => {
  const { index } = row
  const value = getValue()
  const { root, inputRoot, acOption, muiD } = useStyles()

  const onChange = (e, option) => {
    table.options.meta?.handleDataChange(
      index,
      id,
      option ? option.value : null
    )
  }

  const options = [
    { label: "Draft", value: 1 },
    { label: "Review", value: 2 },
    { label: "Published", value: 3 },
    { label: "Planned", value: 4 },
    { label: "Sample", value: 5 }
  ]

  const selected = options.find(o => o.value === value)
  // Render a multi-select box
  return (
    <Autocomplete
      classes={{ root, inputRoot, option: acOption, noOptions: acOption, muiD }}
      id={id}
      value={selected}
      options={options}
      onChange={onChange}
      inputValue={(selected && selected.label) || ""}
      getOptionLabel={option => (option ? option.label : "")}
      isOptionEqualToValue={(option, value) =>
        option && value ? option.value === value.value : false
      }
      renderInput={params => <TextField {...params} />}
      disabled={!row.getIsSelected()}
    />
  )
}

export const OwnerInput = ({ getValue, row, column: { id }, table }) => {
  const { index } = row
  const value = getValue()
  const { root, inputRoot, acOption, muiD } = useStyles()

  const onChange = (e, option) => {
    table.options.meta?.handleDataChange(index, id, option ? option.id : null)
  }

  let cookies = new Cookies()
  let accountsForOrg = cookies.get("AccountsForOrg") || []
  accountsForOrg.unshift({ name: "SYSTEM", username: "SYSTEM", id: 1 })

  const selected =
    accountsForOrg.find(option => {
      if (value === option.id) {
        return option
      }
      return false
    }) || null

  // Render a multi-select box
  return (
    <Autocomplete
      classes={{
        root,
        inputRoot,
        option: acOption,
        noOptions: acOption,
        muiD
      }}
      id={id}
      value={selected}
      options={accountsForOrg}
      onChange={onChange}
      PopperComponent={CustomPopper}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => <TextField {...params} />}
      disabled={!row.getIsSelected()}
    />
  )
}

export const NarrativeInput = ({ getValue, row, column: { id }, table }) => {
  const { index } = row
  const initialValue = getValue() || ""
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
    table.options.meta?.handleDataChange(index, id, e.target.value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  const cookies = new Cookies()
  const accountsForOrg = cookies.get("AccountsForOrg") || []
  // Render a multi-select box
  return (
    <select
      value={value || ""}
      onChange={onChange}
      disabled={!row.getIsSelected()}
    >
      <option value="">Unknown</option>
      <option value="1">SYSTEM</option>
      {accountsForOrg.map((option, i) => (
        <option key={i} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  )
}

export const TriggerInput = ({ getValue }) => {
  const initialValue = getValue() || ""
  const [value, setValue] = useState(initialValue)

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // Render a multi-select box
  return (
    <div
      style={{
        cursor: "pointer"
      }}
    >
      <input value={value} readOnly />
    </div>
  )
}

export const LibrarySentencePositionCell = ({
  getValue,
  row,
  column: { id },
  table
}) => {
  const { index } = row
  const initialValue = getValue() || ""
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    let myTotal = e.target.value
    if (myTotal) {
      //Check for any character not a number and reject it
      if (
        myTotal.replace(/\d|,/g, "") ||
        myTotal.match(/,,/) ||
        myTotal.match(/^,/)
      ) {
        setValue(value || "")
        return
      }
    }
    setValue(e.target.value)
    table.options.meta?.handleDataChange(index, id, e.target.value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // Render a multi-select box
  return (
    <div
      style={{
        cursor: "pointer"
      }}
    >
      <input
        value={value}
        onChange={onChange}
        disabled={!row.getIsSelected()}
      />
    </div>
  )
}

export const LibraryBlockPositionsFilterCell = ({
  getValue,
  row,
  column: { id },
  table
}) => {
  const { editable, editableDisabled } = useStyles()
  const { index } = row
  const initialValue = getValue() || ""
  const [value, setValue] = useState(initialValue)

  const onChange = e => {
    let myTotal = e.target.value
    if (myTotal) {
      //Check for any character not a number or "," OR two straight ",," and reject it
      if (
        myTotal.replace(/\d|,/g, "") ||
        myTotal.match(/,,/) ||
        myTotal.match(/^,/)
      ) {
        setValue(value || "")
        return
      }
    }
    setValue(e.target.value)
    table.options.meta?.handleDataChange(index, id, e.target.value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  // Render a multi-select box
  return (
    <div
      style={{
        cursor: "pointer"
      }}
    >
      <input
        value={value}
        onChange={onChange}
        className={row.getIsSelected() ? editable : editableDisabled}
        readOnly={!row.getIsSelected()}
      />
    </div>
  )
}

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
