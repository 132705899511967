import React, {
  useEffect,
  useState //useMemo
} from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { TabPanel } from "components/TabPanel/TabPanel"
import AppBar from "@mui/material/AppBar"
import ScratchPadForRealTime from "components/RealTime/EditorRealTimeLibrary/ScratchPadForRealTime"
import ReactJson from "react-json-view"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getLeagueModelTypes: store.realTimeStore.getLeagueModelTypes,
    leagueModelTypes: store.realTimeStore.leagueModelTypes,
    previewModel: store.realTimeStore.previewModel,
    setPreviewModel: store.realTimeStore.setPreviewModel
  }))
}

export default function RealTimeDataBar({ sentenceModelType, leagueId }) {
  const {
    getLeagueModelTypes,
    leagueModelTypes,
    previewModel,
    setPreviewModel
  } = useStoreData()

  const [modelSelected] = useState(sentenceModelType?.id || 0)

  const [currentView, setCurrentView] = useState(0)

  const getSnapshots = () => {
    if (!leagueModelTypes || leagueModelTypes.length === 0) {
      getLeagueModelTypes(leagueId)
    }
  }

  const onFullyUpdate = updateObject => {
    let { updated_src, new_value } = updateObject
    if (new_value) {
      setPreviewModel(updated_src)
    }
  }
  if (!leagueModelTypes || leagueModelTypes.length < 1) {
    getLeagueModelTypes(leagueId)
  }

  const handleTabChange = (event, newValue) => {
    getSnapshots()
    if (newValue !== currentView) {
      setCurrentView(newValue)
    }
  }

  const modelSelectedValue = modelSelected
    ? modelSelected
    : sentenceModelType?.id > 0
    ? sentenceModelType.id
    : 0

  useEffect(() => {
    const modelSel = modelSelected || modelSelectedValue
    if (modelSel > 0) {
      if (leagueModelTypes?.length > 0) {
        leagueModelTypes.forEach(x => {
          if (x.id === modelSel) {
            const preview = JSON.parse(x.preview)
            setPreviewModel(preview)
          }
        })
      }
    }
  }, [modelSelected, modelSelectedValue, leagueModelTypes])

  return (
    <>
      <AppBar
        position="static"
        color="default"
        enableColorOnDark
        aria-label="Real Time Outputs Tabs App Bar"
        style={{ margin: "10px 0 15px 0" }}
      >
        <Tabs
          value={currentView}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
          aria-label="eal Time Outputs Tabs "
        >
          <Tab label="Real Time Model Information" />
          <Tab label="Scratch Pad" />
        </Tabs>
      </AppBar>
      <TabPanel
        value={currentView}
        index={0}
        aria-label="Real Time Outputs Tabs Output Preference Container"
      >
        {Object.keys(previewModel).length > 0 && (
          <ReactJson
            theme="monokai"
            enableClipboard={false}
            displayObjectSize={true}
            displayDataTypes={false}
            src={previewModel}
            sortKeys={true}
            collapsed={false}
            onEdit={edit => onFullyUpdate(edit)}
          />
        )}
      </TabPanel>
      <TabPanel
        value={currentView}
        index={1}
        aria-label="Real Time Outputs Tabs Output Container"
      >
        <>
          <ScratchPadForRealTime
            modelId={modelSelectedValue}
            previewModel={previewModel}
          />
        </>
      </TabPanel>
    </>
  )
}
