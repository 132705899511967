import React, { useState } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
//import StepContent from "components/StepContentForStepper"
import RealTimeOutputCreatorForm from "components/RealTime/RealTimeOutputCreatorForm"
import Button from "components/CustomButtons/Button"
import RealTimeDAO from "daos/realTimeDAO"
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  CardActions,
  IconButton,
  //StepContent,
  Typography
} from "@mui/material"
import Close from "@mui/icons-material/Close"
import { useHistory } from "react-router-dom"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen
  }))
}
const steps = ["Select Options", "Insight Tuning"]

export default function OutputCreateForm({ mode, closePage }) {
  const [activeStep, setActiveStep] = useState(0)
  const [formValues, setFormValues] = useState({})
  const [errorValues, setErrorValues] = useState([])
  const [activateSave, setActivateSave] = useState(false)

  const {
    setDialogSuccessMessage,
    setDialogSuccessOpen,
    setDialogFailMessage,
    setDialogFailOpen
  } = useStoreData()
  const history = useHistory()
  //const isStepOptional = step => step === 1
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSave = async val => {
    if (!val) {
      alert("just for Testing")
      return
    }
    const insightTypeId = []
    formValues.libraryId?.insightTypes?.forEach(x => {
      if (formValues[x.name] === true) {
        insightTypeId.push(x.id)
      }
    })
    const outputData = {
      name: formValues.name,
      description: formValues.description || formValues.name,
      statusId: formValues.statusId || 3,
      id: formValues.id || 0,
      libraryId: formValues.libraryId?.id,
      isArchived: formValues.isArchived || false,
      insightTypeIds: insightTypeId,
      leagueId: formValues.leagueId?.id || 1,
      organizationId: formValues.organizationId,
      verticalId: formValues.verticalId?.id,
      deliveryTypeId: formValues.deliveryType?.id,
      outputTypeId: formValues.outputType?.id
    }

    try {
      //let savedOutput
      let successMessage = ""

      if (outputData.id) {
        // Update existing sentence
        const response = await RealTimeDAO.putRealTimeOutput(
          outputData,
          outputData.id
        )
        if (response.ok) {
          successMessage = `Output with ID:${formValues.id} updated successfully`
          //savedOutput = formValues
          //setInitialFormValues(sentenceFormValues)
        } else {
          setDialogFailMessage(`Failed to update Output`)
          setDialogFailOpen(true)
        }
      } else {
        // Create new sentence
        const response = await RealTimeDAO.postRealTimeOutput(outputData)
        if (response && response.id) {
          successMessage = `New Output with ID: ${response.id} created successfully`
          history.push(`/portal/real-time-output/${response.id}`)
          // setInitialFormValues({ ...formValues, id: response.id })
          // setSentenceFormValues({ ...formValues, id: response.id })
        } else {
          setDialogFailMessage(`Failed to save Output`)
          setDialogFailOpen(true)
        }
      }
      // Handle success
      if (successMessage) {
        setDialogSuccessMessage(successMessage)
        setDialogSuccessOpen(true)
        //getInsightSentence(libraryId)
      }
      // Call onSave callback
      // if (onSave) {
      //   onSave(savedOutput)
      // }
      // Update form values and reset unsavedChanges flag
      // setSentenceFormValues(prevValues => ({
      //   ...prevValues,
      //   ...savedOutput
      // }))
      // setUnsavedChanges(false)
    } catch (error) {
      console.error("Error saving sentence:", error)
      // Show an error message to the user
      setDialogFailMessage(`Failed to save sentence: ${error.message}`)
      setDialogFailOpen(true)
    }
  }

  const handleNext = () => {
    console.log(`formValues for step:${activeStep}`, formValues)
    const result = formValueValidation(formValues, activeStep)
    if (result) {
      setErrorValues(result)
      console.log("We have error values in StepperForm", result)
    } else {
      setErrorValues([])
      console.log("Validation passed, moving on to next step")
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleCancel = () => {}

  const checkForInsights = () => {
    const insightTypeId = []
    formValues.libraryId?.insightTypes?.forEach(x => {
      if (formValues[x.name] === true) {
        insightTypeId.push(x.id)
      }
    })
    if (insightTypeId.length === 0) {
      return {
        name: "Insight Tuning",
        msg: `Please add at least one Insight Type`
      }
    } else {
      return false
    }
  }

  const leagueIdIfSports = (leagueId, itm) => {
    if (itm.verticalId?.id === 1) {
      if (!leagueId?.id) {
        return `Please add a League`
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const validationObject = {
    hasOwnProperty: [
      { variable: "name", name: "Name", step: 1 },
      { variable: "verticalId", name: "Vertical", step: 1 },
      { variable: "organizationId", name: "Organization", step: 1 },
      {
        variable: "leagueId",
        name: "League",
        step: 1,
        validation: leagueIdIfSports
      },
      { variable: "libraryId", name: "Library", step: 1 },
      {
        variable: "insightTypeIds",
        name: "Insight Tuning",
        step: 2,
        validation: checkForInsights
      }
    ]
  }

  const formValueValidation = (itm, step) => {
    let result = []
    validationObject.hasOwnProperty?.forEach(field => {
      console.log("field is:", field)
      if (itm.hasOwnProperty(field.variable) && field.step === step + 1) {
        console.log(`field is in step:${step}`, field)
        if (typeof field.validation === "function") {
          const errorResult = field.validation(itm[field.variable], itm)
          if (errorResult) {
            result.push({
              name: field.variable,
              msg: errorResult
            })
          }
        } else if (field.array && !itm[field.variable].length) {
          result.push({
            name: field.variable,
            msg: `Please add a value for "${field.name}"`
          })
          // } else if (field.object && !itm[field.variable][field.object]) {
          //   result.push({
          //     name: field.variable,
          //     msg: `Please add a value for "${field.name}"`
          //   })
        } else if (!itm[field.variable]) {
          result.push({
            name: field.variable,
            msg: `Please add a value for "${field.name}"`
          })
        } else {
          console.log("field is fine:", field)
        }
      } else {
        console.log(`field is NOT in step:${step}`, field)
      }
    })
    if (result.length) {
      return result
    } else {
      return false
    }
  }

  return (
    <div>
      {/* <RealTimeOutputCreatorForm mode={mode} /> */}
      <>
        <Dialog open={true} onClose={handleCancel} fullWidth maxWidth="md">
          <IconButton
            onClick={() => {
              closePage()
            }}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              padding: "0px"
            }}
          >
            <Close />
          </IconButton>
          {/* <Box> */}
          <Stepper
            activeStep={activeStep}
            style={{ margin: "15px 10px 0 10px" }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <Typography>Completed steps - Save</Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              <RealTimeOutputCreatorForm
                step={activeStep}
                mode="create"
                setActivateSave={val => {
                  setActivateSave(val)
                }}
                sendFormValues={val => {
                  setFormValues(val)
                  console.log("sendFormValues", val)
                }}
                setFormValues={val => {
                  setFormValues(val)
                  console.log("setFormValues", val)
                }}
                errorValues={errorValues}
              />
              <CardActions style={{ display: "flex" }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "50%"
                  }}
                >
                  <Button
                    color="secondary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "50%"
                  }}
                >
                  {activeStep === steps.length - 1 && (
                    <Button
                      color="primary"
                      onClick={handleSave}
                      disabled={!activateSave}
                    >
                      Save
                    </Button>
                  )}
                  {activeStep !== steps.length - 1 && (
                    <Button color="primary" onClick={handleNext}>
                      Next
                    </Button>
                  )}
                </Box>
              </CardActions>
              {/* </Dialog> */}
            </>
          )}
          {/* </Box> */}
        </Dialog>
      </>
    </div>
  )
}
