import { globalSettings } from "variables/general"
import { get, put, post, del } from "../tools/request"
export default class RealTimeDAO {
  //library endpoints
  static getRealTimeOutputTypes = async () => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/output/outputtypes`
    )
    return await res.json()
  }

  static getRealTimeDeliveryTypes = async () => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/output/deliverytypes`
    )
    return await res.json()
  }

  static getRealTimeOutputs = async orgId => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/output?orgId=${orgId}`
    )
    return await res.json()
  }

  static getRealTimeOutput = async id => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/output/${id}`
    )
    return await res.json()
  }

  static getAllRealTimeOutputs = async () => {
    const res = await get(`${globalSettings.realtimeAdminBaseUrl}/api/output`)
    return await res.json()
  }

  static postRealTimeOutput = async body => {
    const res = await post(
      `${globalSettings.realtimeAdminBaseUrl}/api/output`,
      null,
      body
    )
    return await res.json()
  }

  static putRealTimeOutput = async (body, id) => {
    const res = await put(
      `${globalSettings.realtimeAdminBaseUrl}/api/output/${id}`,
      null,
      body
    )
    return await res
  }

  static getRealTimeLibraries = async () => {
    const res = await get(`${globalSettings.realtimeAdminBaseUrl}/api/library`)
    return await res.json()
  }

  static getRealTimeLibraryById = async id => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/library/${id}`
    )
    return await res.json()
  }

  static postRealTimeLibrary = async body => {
    const res = await post(
      `${globalSettings.realtimeAdminBaseUrl}/api/library`,
      null,
      body
    )
    return await res.json()
  }

  static putRealTimeLibraryById = async (body, id) => {
    const res = await put(
      `${globalSettings.realtimeAdminBaseUrl}/api/library/${id}`,
      null,
      body
    )
    return await res
  }

  static archiveLibrary = async id => {
    const res = await del(
      `${globalSettings.realtimeAdminBaseUrl}/api/library/${id}`
    )
    return await res
  }

  //InsightType endpoints
  static getInsightTypes = async leagueId => {
    const res = await get(
      leagueId
        ? `${globalSettings.realtimeAdminBaseUrl}/api/insighttype?leagueId=${leagueId}`
        : `${globalSettings.realtimeAdminBaseUrl}/api/insighttype`
    )
    return await res.json()
  }

  static getInsightType = async id => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/insighttype/${id}`
    )
    return await res.json()
  }

  static archiveInsightType = async id => {
    const res = await del(
      `${globalSettings.realtimeAdminBaseUrl}/api/insighttype/${id}`
    )
    return await res
  }
  //InsightGroup endpoints
  static getInsightGroup = async libraryId => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/insightgroup?libraryId=${libraryId}`
    )
    return await res.json()
  }

  static getInsightGroupById = async id => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/insightgroup/${id}`
    )
    return await res.json()
  }

  static postInsightGroup = async body => {
    const res = await post(
      `${globalSettings.realtimeAdminBaseUrl}/api/insightgroup`,
      null,
      body
    )
    return await res.json()
  }

  static updateInsightGroup = async (body, id) => {
    const res = await put(
      `${globalSettings.realtimeAdminBaseUrl}/api/insightgroup/${id}`,
      null,
      body
    )
    return await res
  }

  static archiveInsightGroup = async id => {
    const res = await del(
      `${globalSettings.realtimeAdminBaseUrl}/api/insightgroup/${id}`
    )
    return await res
  }
  //InsightSentence endpoints
  static getInsightSentence = async libraryId => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/sentence?libraryId=${libraryId}`
    )
    return await res.json()
  }

  static getInsightSentenceById = async id => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/sentence/${id}`
    )
    return await res.json()
  }

  static postInsightSentence = async body => {
    const res = await post(
      `${globalSettings.realtimeAdminBaseUrl}/api/sentence`,
      null,
      body
    )
    return await res.json()
  }

  static updateInsightSentence = async (body, id) => {
    const res = await put(
      `${globalSettings.realtimeAdminBaseUrl}/api/sentence/${id}`,
      null,
      body
    )
    return await res
  }

  static archiveInsightSentence = async id => {
    const res = await del(
      `${globalSettings.realtimeAdminBaseUrl}/api/sentence/${id}`
    )
    return await res
  }
  //Insight Status
  static getInsightStatus = async () => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/insightstatus`
    )
    return await res.json()
  }

  static getInsightStatusById = async id => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/insightstatus/${id}`
    )
    return await res.json()
  }

  static postInsightStatus = async body => {
    const res = await post(
      `${globalSettings.realtimeAdminBaseUrl}/api/insightstatus`,
      null,
      body
    )
    return await res.json()
  }
  //Library Status
  static getLibraryStatus = async () => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/librarystatus`
    )
    return await res.json()
  }

  static getLibraryStatusById = async id => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/librarystatus/${id}`
    )
    return await res.json()
  }

  static postLibraryStatus = async body => {
    const res = await post(
      `${globalSettings.realtimeAdminBaseUrl}/api/librarystatus`,
      null,
      body
    )
    return await res.json()
  }
  //ModelType
  static getModelTypesByLeague = async leagueId => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/modeltype?leagueId=${leagueId}`
    )
    return await res.json()
  }

  static getModelTypes = async () => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/modeltype`
    )
    return await res.json()
  }

  static getModelTypeById = async id => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/modeltype/${id}`
    )
    return await res.json()
  }

  static postModelType = async body => {
    const res = await post(
      `${globalSettings.realtimeAdminBaseUrl}/api/modeltype`,
      null,
      body
    )
    return await res.json()
  }
  //Reference Period
  static getReferencePeriod = async () => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/referenceperiod`
    )
    return await res.json()
  }

  static getReferencePeriodById = async id => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/refereceperiod${id}`
    )
    return await res.json()
  }

  static postReferencePeriod = async body => {
    const res = await post(
      `${globalSettings.realtimeAdminBaseUrl}/api/referenceperiod`,
      null,
      body
    )
    return await res.json()
  }
  //League
  static getRealTimeLeague = async () => {
    const res = await get(`${globalSettings.realtimeBaseUrl}/api/league`)
    return await res.json()
  }
  //Insights
  static getInsights = async (matchId, sinceId) => {
    const res = await get(
      `${globalSettings.realtimeBaseUrl}/api/insights/${matchId}`
    )
    return await res.json()
  }

  //Model Criteria
  static putModelCriteria = async (body, sentenceId) => {
    const res = await put(
      `${globalSettings.realtimeAdminBaseUrl}/api/modelcriteria/${sentenceId}`,
      null,
      body
    )
    return res
  }

  static getModelCriteriaBySentence = async sentenceId => {
    const res = await get(
      `${globalSettings.realtimeAdminBaseUrl}/api/modelcriteria?sentenceId=${sentenceId}`
    )
    const response = await res
    if (response.ok) {
      return response.json()
    } else {
      console.log(
        `This call has failed (HTTP ${response.status}) so we received no model criteria`
      )
      return {}
    }
    //return (await res.ok) ? res.json() : {}
  }
  //Preview
  static putPreviewModelTypeId = async (body, modelTypeId) => {
    const res = await put(
      `${globalSettings.realtimeRenderingBaseUrl}/preview/${modelTypeId}`,
      null,
      body
    )
    return await res
  }
}
