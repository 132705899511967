import React, { useState } from "react"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import Card from "components/Card/Card"
import { Editor } from "@tinymce/tinymce-react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import {
  Box,
  CardActions,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup
} from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"
import Button from "components/CustomButtons/Button"
import RenderType from "./Types/RenderType"
import NarrativeCategories from "./NarrativeCategories"
import NarrativeTags from "./NarrativeTags"
import clsx from "clsx"
import { ExpandMore } from "@mui/icons-material"
import { sectionsToRenderAsHtml } from "./Data/contentSections"
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `horizontal-tab-${index}`,
    "aria-controls": `horizontal-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  outlineBlockCard: {
    borderRadius: "1px",
    marginTop: 0,
    position: "relative",
    backgroundColor: "#f2f2f2",
    boxShadow: "none"
  },
  mainForm: {
    "& div.MuiFormControl-root": {
      backgroundColor: "#fff",
      padding: "0 5px",
      border: "1px solid #fff",
      "&:hover": {
        border: "1px solid #ccc"
      }
    },
    "& div.MuiSelect-select:focus": {
      backgroundColor: "#fff"
    },
    "& div.MuiInput-underline:before, & .MuiInput-underline:after, & div.MuiInput-underline:hover":
      {
        border: "none",
        content: "none"
      }
  },
  paragraphCard: {
    borderRadius: "1px",
    marginTop: 0,
    position: "relative",
    backgroundColor: "#ffffff",
    boxShadow: "none"
  },
  cardFooter: {
    borderTop: "none",
    justifyContent: "space-between",
    "& > a:hover": {
      cursor: "pointer"
    }
  },
  largeInput: {
    "& .MuiInput-input": {
      minHeight: "200px"
    }
  },
  mediumInput: {
    "& .MuiInput-input": {
      minHeight: "100px"
    }
  },
  textAreaHolder: {
    backgroundColor: "#ffffff",
    "& > textarea": {
      width: "100%"
    }
  },
  advancedOptionsHeader: {
    display: "block",
    textAlign: "left",
    position: "relative",
    padding: "0",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderTop: "none",
    "& > label": {
      margin: "5px",
      marginRight: 0,
      fontSize: "12px",
      fontFamily: "Helvetica",
      color: "rgba(34,47,62,.7)",
      textTransform: "capitalize",
      "& > a": {
        color: "#fff"
      },
      "& > a:hover": {
        textDecoration: "underline"
      },
      "& > span": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#fff",
        padding: "0 5px",
        borderRadius: "20px"
      }
    },
    "& > button": {
      position: "absolute",
      right: "10px",
      padding: 0,
      top: 0
    }
  },
  expand: {
    color: "rgba(34,47,62,.7)",
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  advancedOptionsHolder: {
    padding: "20px"
  },
  sectionHeader: {
    padding: "0",
    marginBottom: "0"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    postingNarrativeOutlineBlock:
      store.narrativeStore.postingNarrativeOutlineBlock,
    postNarrativeOutlineBlock: store.narrativeStore.postNarrativeOutlineBlock,
    postParagraph: store.narrativeStore.postParagraph,
    reloadNarrativeBrief: store.narrativeStore.reloadNarrativeBrief,
    narrative: store.narrativeStore.narrative,
    postingNarrativeBlock: store.narrativeStore.postingNarrativeBlock
  }))
}

const NarrativeOutlineBlockEdit = props => {
  const classes = useStyles()
  const {
    postNarrativeOutlineBlock,
    postingNarrativeOutlineBlock,
    postParagraph,
    reloadNarrativeBrief,
    narrative,
    postingNarrativeBlock
  } = useStoreData()

  const { block, canContainMarkup, blockLevel = "outline", hasChildren } = props
  const { buildFromOutline } = narrative

  const [currentTab, setCurrentTab] = useState(0)
  const [isDirty, setIsDirty] = useState(false)
  const [showRemoveAlert, setShowRemoveAlert] = useState(false)
  const [showNotes, setShowNotes] = useState(false)
  const [showAdvancedSave] = useState(!hasChildren)
  const [useWYSIWYG, setUseWYSIWYG] = useState(false)

  const handleTabChange = (_event, newValue) => {
    setCurrentTab(newValue)
  }

  const saveChanges = () => {
    const newBlock = {
      ...block,
      createdParagraphStatusId: block.createdParagraphStatusId || 3,
      createdSentenceStatusId: block.createdSentenceStatusId || 3,
      inEditMode: false
    }
    if (blockLevel === "outline") {
      postNarrativeOutlineBlock(newBlock)
    }
    if (blockLevel === "paragraph") {
      postParagraph(newBlock)
    }
    if (block.shouldCreateParagraph) {
      newBlock.shouldCreateParagraph = true
      newBlock.shouldCreateSentence = false
      props.handleChange && props.handleChange(newBlock)
      setTimeout(() => reloadNarrativeBrief(block.narrative_Id), 1000)
    }
    setIsDirty(false)
    props.handleSave && props.handleSave()
  }

  const handleNameChange = event => {
    const newBlock = {
      ...block,
      contentSection: event.target.value
    }
    setIsDirty(true)
    props.handleChange && props.handleChange(newBlock)
  }

  const handleChange = event => {
    const newBlock = {
      ...block,
      [event.target.id || event.target.name]: event.target.value
    }
    setIsDirty(true)
    props.handleChange && props.handleChange(newBlock)
  }

  const handleCheckChange = event => {
    const newBlock = {
      ...block,
      [event.target.id || event.target.name]: event.target.checked
    }
    setIsDirty(true)
    props.handleChange && props.handleChange(newBlock)
  }

  const handleTinyMCEEditorChange = event => {
    const newBlock = {
      ...block,
      sampleResult: event.target.getContent()
    }
    setIsDirty(true)
    props.handleChange && props.handleChange(newBlock)
  }
  const handleTextEditorChange = event => {
    const newBlock = {
      ...block,
      sampleResult: event.target.value
    }
    setIsDirty(true)
    props.handleChange && props.handleChange(newBlock)
  }

  //Special Handler for RenderType component
  const handleChangeRenderType = renderType => {
    const newBlock = {
      ...block,
      renderType
    }
    setIsDirty(true)
    props.handleChange && props.handleChange(newBlock)
  }

  const alertRemoveBlock = () => {
    setShowRemoveAlert(true)
  }

  const removeBlock = () => {
    props.removeBlock && props.removeBlock()
  }

  const closeAlert = () => {
    setShowRemoveAlert(false)
  }

  const isContentBlock = sectionsToRenderAsHtml.includes(block.contentBlock)
  let contentWidth = isContentBlock ? 9 : 12
  if (!narrative.buildFromOutline) {
    contentWidth = contentWidth - 2
  }

  const blockRulesTitle = !buildFromOutline
    ? "Block Rules are only available when Building from Outline"
    : ""
  return (
    <div className={classes.root}>
      {postingNarrativeBlock && <LinearProgress />}
      <Card
        className={
          blockLevel === "outline"
            ? classes.outlineBlockCard
            : classes.paragraphCard
        }
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          centered
          scrollButtons
          indicatorColor="primary"
          textColor="primary"
          aria-label="outline block tabs"
          title={blockRulesTitle}
          allowScrollButtonsMobile
        >
          <Tab label="Content" {...a11yProps(0)} />
          <Tab
            label="Block Rules"
            {...a11yProps(1)}
            disabled={!buildFromOutline}
          />
        </Tabs>
        <div className={classes.mainForm}>
          {postingNarrativeOutlineBlock && (
            <CircularProgress
              style={{
                position: "absolute",
                left: "48%",
                top: "100px",
                zIndex: "101"
              }}
            />
          )}
          <TabPanel value={currentTab} index={0}>
            <GridContainer spacing={3}>
              {!narrative.buildFromOutline && (
                <GridItem xs={2}>
                  <TextField
                    fullWidth
                    id="position"
                    label="position"
                    defaultValue={block.position}
                    type="number"
                    onBlur={handleChange}
                  />
                </GridItem>
              )}
              <GridItem xs={contentWidth}>
                <TextField
                  fullWidth
                  id="contentSection"
                  label="Block Title"
                  defaultValue={block.contentSection}
                  onBlur={handleNameChange}
                />
              </GridItem>
              {isContentBlock && (
                <GridItem sm={3}>
                  <RenderType
                    fullWidth
                    id="RenderType"
                    value={block.renderType || ""}
                    onChange={handleChangeRenderType}
                  />
                </GridItem>
              )}
              <GridItem xs={12}>
                <RadioGroup
                  row
                  value={useWYSIWYG}
                  onChange={e => {
                    const shouldUseWYSIWYG =
                      e.target.value === "true" ? true : false
                    setUseWYSIWYG(shouldUseWYSIWYG)
                  }}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio size="small" />}
                    label="Raw"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio size="small" />}
                    label="WYSIWYG"
                    labelPlacement="start"
                  />
                </RadioGroup>
                {block.contentBlock &&
                  !(
                    block.contentBlock === "categories" ||
                    block.contentBlock === "dscategories" ||
                    block.contentBlock === "tags" ||
                    block.contentBlock === "outputexperimentlog" ||
                    block.contentBlock === "entrytags" ||
                    block.contentBlock === "metadata" ||
                    block.contentBlock === "interestgroups"
                  ) &&
                  (useWYSIWYG ? (
                    <div className={classes.textAreaHolder}>
                      <InputLabel style={{ fontSize: "11px", padding: "2px" }}>
                        Sample Text / Sentence Starter
                      </InputLabel>
                      <Editor
                        apiKey="rpxxr36ah4ei6v91l9blwt1ceamufuog4ta1906jtt9by41t"
                        initialValue={block.sampleResult}
                        id={`.${block.id}-${block.position}-sample-editor`}
                        init={{
                          selector: `.${block.id}-${block.position}`,
                          height: 300,
                          menubar: false,
                          statusbar: true,
                          plugins: [
                            "advlist autolink code lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount"
                          ],
                          spellchecker_dialog: false,
                          spellchecker_on_load: false,
                          toolbar: canContainMarkup
                            ? "undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | code | help"
                            : "undo redo | removeformat | code | help",
                          protect: [
                            /<\/?(if|endif)>/g, // Protect <if> & </endif>
                            /<xsl:[^>]+>/g, // Protect <xsl:...>
                            /<\?php.*?\?>/g, // Protect php code
                            /<\/?(apxh:div|apxh:p)>/g // Protect <apxh:div /> & <apxh:p />
                          ],
                          force_br_newlines: false,
                          force_p_newlines: false,
                          forced_root_block: "",
                          remove_linebreaks: false,
                          convert_newlines_to_brs: false,
                          preformatted: true
                        }}
                        onChange={() => !isDirty && setIsDirty(true)}
                        onBlur={handleTinyMCEEditorChange}
                      />
                    </div>
                  ) : (
                    <TextField
                      type="text"
                      fullWidth
                      label="Sample Text / Sentence Starter"
                      id={`${block.id}-${block.position}-text-editor`}
                      multiline
                      defaultValue={block.sampleResult || ""}
                      onBlur={handleTextEditorChange}
                      margin="normal"
                    />
                  ))}
                {block.contentBlock &&
                  (block.contentBlock === "categories" ||
                    block.contentBlock === "dscategories" ||
                    block.contentBlock === "outputexperimentlog" ||
                    block.contentBlock === "entrytags" ||
                    block.contentBlock === "metadata" ||
                    block.contentBlock === "interestgroups") && (
                    <div className={classes.textAreaHolder}>
                      <InputLabel style={{ fontSize: "11px", padding: "2px" }}>
                        Sample Categories
                      </InputLabel>
                      <NarrativeCategories
                        name={"sampleResult"}
                        id={"sampleResult"}
                        categories={block.sampleResult}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                {block.contentBlock && block.contentBlock === "tags" && (
                  <div className={classes.textAreaHolder}>
                    <InputLabel style={{ fontSize: "11px", padding: "2px" }}>
                      Sample Tags
                    </InputLabel>
                    <NarrativeTags
                      name={"sampleResult"}
                      id={"sampleResult"}
                      tags={block.sampleResult}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </GridItem>
              <GridItem xs={12}>
                <CardActions className={classes.sectionHeader} disableSpacing>
                  <strong>Block Notes</strong>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: showNotes
                    })}
                    onClick={() => setShowNotes(!showNotes)}
                    aria-expanded={showNotes}
                    aria-label="show more"
                    size="large"
                  >
                    <ExpandMore />
                  </IconButton>
                </CardActions>
                <Collapse in={showNotes}>
                  <TextField
                    fullWidth
                    className={classes.mediumInput}
                    id="comments"
                    label="Block Notes"
                    multiline
                    maxRows={4}
                    defaultValue={block.comments}
                    onBlur={handleChange}
                  />
                </Collapse>
              </GridItem>
            </GridContainer>
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <GridContainer spacing={1}>
              <GridItem xs={2}>
                <FormControl fullWidth>
                  <InputLabel>Update Handling</InputLabel>
                  <Select
                    type="select"
                    name="updateHandling"
                    id="updateHandling"
                    value={block.updateHandling || 0}
                    onChange={handleChange}
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="0">Replace</MenuItem>
                    <MenuItem value="1">Reuse</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={2}>
                <FormControl fullWidth>
                  <InputLabel>Required Block</InputLabel>
                  <Select
                    type="select"
                    name="isRequiredBlock"
                    value={block.isRequiredBlock || false}
                    onChange={handleChange}
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                    <MenuItem value={true}>True</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={8}>
                <TextField
                  fullWidth
                  id="blockAttributes"
                  label="Block Attributes"
                  defaultValue={block.blockAttributes}
                  onBlur={handleChange}
                />
              </GridItem>
            </GridContainer>
          </TabPanel>
        </div>
        {showAdvancedSave && (
          <div className={classes.advancedOptionsHolder}>
            <GridContainer spacing={3}>
              <GridItem xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      title="Create a Paragraph for this Block"
                      id="shouldCreateParagraph"
                      checked={block.shouldCreateParagraph}
                      onChange={handleCheckChange}
                    />
                  }
                  label="Create Paragraph"
                />
              </GridItem>
              <GridItem xs={3}>
                {block.shouldCreateParagraph && (
                  <FormControl fullWidth>
                    <InputLabel>Paragraph Status</InputLabel>
                    <Select
                      type="select"
                      name="createdParagraphStatusId"
                      id="createdParagraphStatusId"
                      value={block.createdParagraphStatusId || 3}
                      onChange={handleChange}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value={1}>Draft</MenuItem>
                      <MenuItem value={2}>Review</MenuItem>
                      <MenuItem value={3}>Published</MenuItem>
                      <MenuItem value={4}>Planned</MenuItem>
                      <MenuItem value={5}>Sample</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </GridItem>
              <GridItem xs={2}>
                {block.shouldCreateParagraph && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="shouldCreateSentence"
                        checked={block.shouldCreateSentence}
                        onChange={handleCheckChange}
                      />
                    }
                    label="Create Sentence"
                  />
                )}
              </GridItem>
              <GridItem xs={3}>
                {block.shouldCreateSentence && (
                  <FormControl fullWidth>
                    <InputLabel>Sentence Status</InputLabel>
                    <Select
                      type="select"
                      name="createdSentenceStatusId"
                      id="createdSentenceStatusId"
                      value={block.createdSentenceStatusId || 3}
                      onChange={handleChange}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value={1}>Draft</MenuItem>
                      <MenuItem value={2}>Review</MenuItem>
                      <MenuItem value={3}>Published</MenuItem>
                      <MenuItem value={4}>Planned</MenuItem>
                      <MenuItem value={5}>Sample</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </GridItem>
              <GridItem xs={2} />
            </GridContainer>
          </div>
        )}
        <CardActions className={classes.cardFooter}>
          <Button
            title={`Remove This Block From The Outline`}
            onClick={hasChildren ? alertRemoveBlock : removeBlock}
          >
            Delete Block
          </Button>
          <Button color={isDirty ? "primary" : "info"} onClick={saveChanges}>
            Save Outline Block
          </Button>
        </CardActions>
        <Dialog
          open={showRemoveAlert}
          onClose={closeAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Remove this Block?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You're about to remove this block from the outline and all related
              content. Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlert}>Cancel</Button>
            <Button onClick={removeBlock}>Remove</Button>
          </DialogActions>
        </Dialog>
      </Card>
    </div>
  )
}
export default NarrativeOutlineBlockEdit
