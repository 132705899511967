import { Map, List, fromJS } from "immutable" //TODO: Remove this dependency
import { action, observable, decorate, reaction, computed } from "mobx"
import NarrativeDAO from "../daos/narrativeDAO"
import TriggerDAO from "../daos/triggerDAO"
import NarrativePreviewDAO from "../daos/narrativePreviewDAO"
import { groupCollectionBy } from "tools/CollectionHelper"

export default class NarrativeStore {
  rootStore
  loading
  loadingNarrative
  loadingParagraph
  loadingSentence
  loadingBlockSamples
  loadingSourceNarrative
  loadingTargetNarrative
  loadingOutlineBlocks
  narrativeParagraphTags
  narrativeParagraphTagsSummary
  loadingSearchResults
  loadedSearchResults
  searchString
  searchResults
  starters
  narrativeId
  narrative
  outlineBlocks
  libraryParagraphs
  blockSamples
  postingNarrativeBlock
  outlineBlocksOriginal
  sourceNarrativeId
  sourceNarrative
  targetNarrative
  narratives
  modelTypes
  triggers
  triggersBrief
  triggerTypeCategories
  snapshotTypes
  narrativeModelTokens
  narrativeModelSnapshots
  feedPreview
  triggersPreview
  starterPreview
  contentTypes
  datasources
  bookmakers
  articleTypes
  isNarrativeBuilderOpen
  isDirty
  responseCode
  responseMessage
  copiedElements
  narrativeMissingTriggersMetadata
  healthCheckLogTableMetadata
  narrativeMissingTriggersUpdatedMetadata
  narrativeMissingTriggersTableData
  openModal
  openLogModal
  errorCode
  healthCheckState
  healthCheckLogState
  narrativeStatuses
  verticals
  outputAttributes
  libraryNarratives
  //not observable, used to track narrative library association changes
  prevNarrativeLibraryIds
  isCopyingNarrative

  constructor(rootStore) {
    this.rootStore = rootStore
    this.dehydrate()
    // NOTE: We do have a [narrativestatus] table
    this.narrativeStatuses = [
      { id: 1, name: "Inactive" },
      { id: 2, name: "Validate" },
      { id: 3, name: "Active" }
    ]
  }

  /**
   * Computeds
   */
  get isNarrativeSelected() {
    return !!this.narrativeId
  }

  /**
   * Methods
   */
  getNarratives = async () => {
    try {
      this.setLoading(true)

      const narratives = fromJS(await NarrativeDAO.getNarratives())
      let narrativesList = narratives.get("content", List())
      this.setNarratives(narrativesList)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getLibraryNarratives = async () => {
    try {
      this.setLoading(true)

      const libraryNarratives = fromJS(
        await NarrativeDAO.getLibraryNarratives()
      )
      let libraryNarrativesList = libraryNarratives.get("content", List())
      this.setLibraryNarratives(libraryNarrativesList)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  putNarrative = async narrative => {
    try {
      this.setLoading(true)

      let updatedNarrative = fromJS(
        await NarrativeDAO.putNarrative(this.narrativeId, narrative)
      )

      this.updatedNarratve(updatedNarrative.get("content", Map()))

      // TODO: once the current users org id is in a session store, validate
      // here if we need to update the observed object. If this is a different
      // org id in the post, there is no need to update the observable
      this.updateNarratives(updatedNarrative)

      this.dehydrateNarrative()
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setIsDirty(false)
      this.setLoading(false)
    }
  }

  postNarrative = async narrative => {
    try {
      this.setLoading(true)
      // We'd like to assume we're building from outline but we will only automatically
      // if this is not a library and it's a new narrative or
      // or they're passing html and there aren't any blocks yet
      let myNarrativeOutputAttribute = []
      //Remove ID from narrativeOutputAttribute, being saved via the API and it does not want it
      narrative.narrativeOutputAttribute?.forEach(itm => {
        myNarrativeOutputAttribute.push({
          narrativeId: itm.narrativeId,
          outputAttributeId: itm.outputAttributeId
        })
      })
      const dbNarrative = {
        id: narrative.id || 0,
        name: narrative.name,
        parentId: narrative.parentNarrativeId,
        feedFields: narrative.feedFields,
        organization: narrative.organization,
        modelType: narrative.modelType,
        modelTypeId: narrative.modelTypeId,
        productionFeed_Id: narrative.productionFeed_Id,
        uatFeed_Id: narrative.uATFeed_Id,
        developmentFeed_Id: narrative.developmentFeed_Id,
        leagueId: narrative.leagueId,
        narrativeStatusId: narrative.narrativeStatusId,
        canAppearInFileManager: narrative.canAppearInFileManager,
        description: narrative.description,
        categoryTerm: narrative.categoryTerm,
        categoryLabel: narrative.categoryLabel,
        categoryScheme: narrative.categoryScheme,
        dataSourceSample: narrative.dataSourceSample,
        bookmakerSample: narrative.bookmakerSample,
        rawStory: narrative.rawStory,
        buildFromOutline: narrative.buildFromOutline,
        queryId: narrative.queryId,
        refreshQueryId: narrative.refreshQueryId,
        libraryNarrative_Ids: narrative.libraryNarrative_Ids,
        isArchived: narrative.isArchived,
        categories: narrative.categories,
        narrativeContentTypeId: narrative.narrativeContentTypeId,
        articleTypeId: narrative.articleTypeId,
        isLibrary: narrative.isLibrary,
        wordCount: narrative.wordCount,
        paragraphCount: narrative.paragraphCount,
        tablesAllowed: narrative.tablesAllowed,
        graphicsAllowed: narrative.graphicsAllowed,
        videoAllowed: narrative.videoAllowed,
        sportsDatasource1Id: narrative.sportsDatasource1Id,
        sportsDatasource2Id: narrative.sportsDatasource2Id,
        bettingDatasource1Id: narrative.bettingDatasource1Id,
        bettingDatasource2Id: narrative.bettingDatasource2Id,
        bookmaker1Id: narrative.bookmaker1Id,
        bookmaker2Id: narrative.bookmaker2Id,
        imageSource1Id: narrative.imageSource1Id,
        imageSource2Id: narrative.imageSource2Id,
        cmsId: narrative.cmsId,
        webAnalyticsSource1Id: narrative.webAnalyticsSource1Id,
        behaviorAnalyticsSource1Id: narrative.behaviorAnalyticsSource1Id,
        narrativeKeyword1: narrative.narrativeKeyword1,
        narrativeKeyword2: narrative.narrativeKeyword2,
        narrativeKeyword3: narrative.narrativeKeyword3,
        narrativeKeyword4: narrative.narrativeKeyword4,
        auditInfo: narrative.auditInfo,
        recommendedGraphicName: narrative.recommendedGraphicName,
        conferenceIds: narrative.conferenceIds || [],
        divisionIds: narrative.divisionIds || [],
        teamIds: narrative.teamIds || [],
        verticalId: narrative.verticalId,
        narrativeOutputAttribute: myNarrativeOutputAttribute
      }
      let updatedNarrative = fromJS(dbNarrative)
      updatedNarrative = updatedNarrative.set(
        "parentId",
        narrative.parentNarrativeId
      )

      if (narrative.organizationId) {
        updatedNarrative = updatedNarrative.set(
          "organization",
          Map({ id: narrative.organizationId })
        )
      }
      // All of this Immutable.js stuff should be ripped out
      // const newNarrative = fromJS(
      //   await NarrativeDAO.postNarrative(updatedNarrative.toJS())
      // )
      const narrativeResponse = await NarrativeDAO.postNarrative(
        updatedNarrative.toJS()
      )
      let isNarrativeLibraryAssociationUpdated = false
      // Add the child objects onto the updated narrative if they didnt come back
      if (narrativeResponse.content) {
        const {
          id: newNarrativeId,
          paragraphs,
          outlineBlocks,
          triggers,
          libraryParagraphs,
          libraryNarrative_Ids
        } = narrativeResponse.content

        if (narrative.starter) {
          await NarrativeDAO.setStarterForNarrative({
            id: newNarrativeId,
            starter: narrative.starter
          })
        }
        narrativeResponse.content.paragraphs = paragraphs?.length
          ? paragraphs
          : narrative.paragraphs
        narrativeResponse.content.outlineBlocks = outlineBlocks?.length
          ? outlineBlocks
          : narrative.outlineBlocks
        narrativeResponse.content.triggers = triggers?.length
          ? triggers
          : narrative.triggers
        narrativeResponse.content.libraryParagraphs = libraryParagraphs?.length
          ? libraryParagraphs
          : narrative.libraryParagraphs?.length
          ? narrative.libraryParagraphs
          : this.libraryParagraphs

        if (this.prevNarrativeLibraryIds !== libraryNarrative_Ids) {
          isNarrativeLibraryAssociationUpdated = true
        }
        this.prevNarrativeLibraryIds = libraryNarrative_Ids
      }

      //Create the immutable map for downstream processing
      const immutableNarrativeReponse = fromJS(narrativeResponse)

      this.updateNarratives(immutableNarrativeReponse.get("content"))

      this.closeNarrativeBuilderModal()
      this.setResponseCode(immutableNarrativeReponse.get("responseCode"))
      this.setResponseMessage(
        `${immutableNarrativeReponse
          .get("content")
          .get("name")} ${immutableNarrativeReponse.get("responseMessage")}`
      )

      this.setIsDirty(false)
      this.setNarrative(immutableNarrativeReponse.get("content").toJS())

      if (isNarrativeLibraryAssociationUpdated) {
        let narrativeLibraryId = narrativeResponse.content.libraryNarrative_Ids
        this.getNarrativeParagraphTagsSummary(narrativeLibraryId)
        this.getNarrativeParagraphTags(narrativeLibraryId)
        this.reloadNarrativeLibraryParagraphs()
      }

      return immutableNarrativeReponse.get("content", Map())
    } catch (err) {
      console.error("Error: ", err)
      this.setResponseMessage(err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postPreRenderNarrative = async narrative => {
    try {
      this.setLoading(true)

      const preRenderRequest = {
        name: narrative.name,
        orgId: narrative.organizationId,
        leagueId: narrative.leagueId,
        verticalId: narrative.verticalId,
        narrativeOutputAttribute: narrative.narrativeOutputAttribute,
        prodFeedId: narrative.productionFeed_Id,
        queryId: narrative.queryId,
        narrativeStatusId: narrative.narrativeStatusId,
        narrativeContentTypeId: narrative.narrativeContentTypeId
      }

      const narrativeResponse = await NarrativeDAO.cloneNarrativeToOrg(
        preRenderRequest
      )
      let isNarrativeLibraryAssociationUpdated = false
      // Add the child objects onto the updated narrative if they didnt come back
      if (narrativeResponse.content) {
        const {
          id: newNarrativeId,
          paragraphs,
          outlineBlocks,
          triggers,
          libraryParagraphs,
          libraryNarrative_Ids
        } = narrativeResponse.content

        if (narrative.starter) {
          await NarrativeDAO.setStarterForNarrative({
            id: newNarrativeId,
            starter: narrative.starter
          })
        }
        narrativeResponse.content.paragraphs = paragraphs?.length
          ? paragraphs
          : narrative.paragraphs
        narrativeResponse.content.outlineBlocks = outlineBlocks?.length
          ? outlineBlocks
          : narrative.outlineBlocks
        narrativeResponse.content.triggers = triggers?.length
          ? triggers
          : narrative.triggers
        narrativeResponse.content.libraryParagraphs = libraryParagraphs?.length
          ? libraryParagraphs
          : narrative.libraryParagraphs?.length
          ? narrative.libraryParagraphs
          : this.libraryParagraphs

        if (this.prevNarrativeLibraryIds !== libraryNarrative_Ids) {
          isNarrativeLibraryAssociationUpdated = true
        }
        this.prevNarrativeLibraryIds = libraryNarrative_Ids
      }

      //Create the immutable map for downstream processing
      const immutableNarrativeReponse = fromJS(narrativeResponse)

      this.updateNarratives(immutableNarrativeReponse.get("content"))

      this.closeNarrativeBuilderModal()
      this.setResponseCode(immutableNarrativeReponse.get("responseCode"))
      this.setResponseMessage(
        `${immutableNarrativeReponse
          .get("content")
          .get("name")} ${immutableNarrativeReponse.get("responseMessage")}`
      )

      this.setIsDirty(false)
      this.setNarrative(immutableNarrativeReponse.get("content").toJS())

      if (isNarrativeLibraryAssociationUpdated) {
        let narrativeLibraryId = narrativeResponse.content.libraryNarrative_Ids
        this.getNarrativeParagraphTagsSummary(narrativeLibraryId)
        this.getNarrativeParagraphTags(narrativeLibraryId)
        this.reloadNarrativeLibraryParagraphs()
      }

      return immutableNarrativeReponse.get("content", Map())
    } catch (err) {
      console.error("Error: ", err)
      this.setResponseMessage(err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postParagraph = async paragraph => {
    try {
      this.setPostingBlock(true)
      const newParagraphId = await NarrativeDAO.postParagraph(paragraph)
      paragraph.id = newParagraphId
      this.updateParagraphs(paragraph)
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setPostingBlock(false)
    }
  }

  removeLibraryParagraphAssociation = paragraph => {
    if (paragraph.libraryParagraph_Id) {
      const getParagraph = NarrativeDAO.getParagraph(paragraph.id)
      getParagraph
        .then(dbParagraph => {
          const newParagraph = {
            ...dbParagraph,
            libraryParagraph_Id: null
          }
          this.postParagraph(newParagraph)
        })
        .catch(error => {
          console.error(
            `Error on POST to /api/paragraph for paragraph ${paragraph.id}`,
            error
          )
        })
        .finally(() => this.reloadNarrativeBrief(paragraph.narrative_Id))
    }
  }

  getParagraph = async id => {
    this.setLoadingParagraph(true)
    const paragraph = await NarrativeDAO.getParagraph(id)
    this.updateParagraphs(paragraph)
    this.setLoadingParagraph(false)
  }

  getSentence = async id => {
    this.setLoadingSentence(true)
    const sentence = await NarrativeDAO.getSentence(id)
    this.updateSentences(sentence)
    this.setLoadingSentence(false)
  }

  postSentence = async sentence => {
    try {
      this.setLoading(true)
      this.setLoadingSentence(true)
      let updatedSentence = fromJS(sentence)
      const newSentenceId = fromJS(await NarrativeDAO.postSentence(sentence))
      updatedSentence.id = newSentenceId
      this.updateSentences(sentence)
      return updatedSentence
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
      this.setLoadingSentence(false)
    }
  }

  getModelTypes = async () => {
    try {
      this.setLoading(true)
      const modelTypes = fromJS(await NarrativeDAO.getModelTypes())

      this.setModelTypes(modelTypes.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getArticleTypes = async () => {
    try {
      this.setLoading(true)

      const articleTypes = fromJS(await NarrativeDAO.getArticleTypes())
      this.setArticleTypes(articleTypes.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getDatasources = async () => {
    try {
      this.setLoading(true)

      const datasources = fromJS(await NarrativeDAO.getDatasources())

      this.setDatasources(datasources.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getBookmakers = async () => {
    try {
      this.setLoading(true)

      const bookmakers = fromJS(await NarrativeDAO.getBookmakers())

      this.setBookmakers(bookmakers.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getContentTypes = async () => {
    try {
      this.setLoading(true)

      const contentTypes = fromJS(await NarrativeDAO.getContentTypes())
      this.setContentTypes(contentTypes)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getVerticals = async () => {
    try {
      this.setLoading(true)

      const verticals = fromJS(await NarrativeDAO.getVerticals())
      this.setVerticals(verticals.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getOutputAttributes = async () => {
    try {
      this.setLoading(true)

      const verticals = fromJS(await NarrativeDAO.getOutputAttributes())
      this.setOutputAttributes(verticals.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getTriggers = async () => {
    try {
      this.setLoading(true)

      const triggers = fromJS(await TriggerDAO.getTriggers())

      this.setTriggers(triggers)

      const triggerTypeCategories = fromJS(
        this.getTriggerTypeCategories(triggers.toJS())
      )
      this.setTriggerTypeCategories(triggerTypeCategories)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getTriggersBrief = async narrativeIds => {
    try {
      this.setLoading(true)
      const triggers = fromJS(await TriggerDAO.getTriggersBrief(narrativeIds))
      this.setTriggersBrief(triggers)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getTriggerTypeCategories(triggerTypes) {
    const triggerTypeCategories = Object.keys(
      groupCollectionBy(triggerTypes, t => t.category.trim())
    )
    let sortedCategories = triggerTypeCategories.sort((a, b) => {
      // Use toUpperCase() to ignore character casing
      const catA = a.toUpperCase()
      const catB = b.toUpperCase()
      let comparison = 0
      if (catA > catB) {
        comparison = 1
      } else if (catA < catB) {
        comparison = -1
      }
      return comparison
    })
    let triggerTypeList = []
    if (sortedCategories && sortedCategories.length > 0) {
      sortedCategories.forEach(category => {
        if (category && category !== "") {
          triggerTypeList.push({
            id: category,
            name: category
          })
        }
      })
    }
    return triggerTypeList
  }

  getNarrativeMissingTriggers = async narrativeId => {
    try {
      this.setHealthCheckState("loading")

      const missingTriggers = await NarrativeDAO.getMissingNarrativeTriggers(
        narrativeId
      )
      const missingTriggerMetadata = {
        paragraphMissingTriggersCount:
          missingTriggers.content.paragraphMissingTriggersCount,
        paragraphSentenceMissingTriggersCount:
          missingTriggers.content.paragraphSentenceMissingTriggersCount,
        sentenceMissingTriggersCount:
          missingTriggers.content.sentenceMissingTriggersCount
      }
      this.setNarrativeMissingTriggersMetadata(missingTriggerMetadata)
      this.setHealthCheckState("checked")
    } catch (err) {
      console.error("Error: ", err)
      this.setErrorCode(err.cause)
      this.setHealthCheckState("error")
    } finally {
      this.setOpenModal(true)
    }
  }

  getHealthCheckLogTable = async narrativeId => {
    try {
      this.setHealthCheckLogState("loading")

      const healthCheckLogTable = await NarrativeDAO.getHealthCheckLogTable(
        narrativeId
      )

      const healthCheckLogTableData = healthCheckLogTable.content
      this.setHealthCheckLogTableMetadata(healthCheckLogTableData)
      this.setHealthCheckLogState("checked")
    } catch (err) {
      console.error("Error: ", err)
      this.setErrorCode(err.cause)
      this.setHealthCheckLogState("error")
    } finally {
      this.setOpenLogModal(true)
    }
  }

  updateNarrativeMissingTriggers = async narrativeId => {
    try {
      this.setHealthCheckState("loading")

      const missingTriggers = await NarrativeDAO.updateMissingNarrativeTriggers(
        narrativeId
      )

      const missingTriggerMetadata = {
        paragraphMissingTriggersCount:
          missingTriggers.content.paragraphMissingTriggersCount,
        paragraphSentenceMissingTriggersCount:
          missingTriggers.content.paragraphSentenceMissingTriggersCount,
        sentenceMissingTriggersCount:
          missingTriggers.content.sentenceMissingTriggersCount,
        paragraphUpdatedMissingTriggersCount:
          missingTriggers.content.paragraphUpdatedMissingTriggersCount,
        paragraphUpdatedSentenceMissingTriggersCount:
          missingTriggers.content.paragraphUpdatedSentenceMissingTriggersCount,
        sentenceUpdatedMissingTriggersCount:
          missingTriggers.content.sentenceUpdatedMissingTriggersCount
      }

      const tableData = []
      missingTriggers.content.paragraphs.forEach(paragraph => {
        if (paragraph.sentences.length > 0) {
          paragraph.sentences.forEach(sentence => {
            const obj = {}
            obj.paragraphId = paragraph.id
            obj.triggerType = paragraph.triggerType
            obj.sentenceId = sentence.id
            obj.triggerType = sentence.triggerType
            tableData.push(obj)
          })
        } else {
          const obj = {}
          obj.paragraphId = paragraph.id
          obj.triggerType = paragraph.triggerType
          tableData.push(obj)
        }
      })

      this.setNarrativeMissingTriggersUpdatedMetadata(missingTriggerMetadata)
      this.setNarrativeMissingTriggersTableData(tableData)
      this.setHealthCheckState("updated")
    } catch (err) {
      console.error("Error: ", err)
      this.setErrorCode(err.cause)
      this.setHealthCheckState("error")
    }
  }

  postTrigger = async (narrativeId, trigger) => {
    try {
      this.setLoading(true)

      const newTrigger = fromJS(
        await NarrativeDAO.postTrigger(narrativeId, trigger)
      )

      this.updateTriggers(newTrigger.get("content", Map()))
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postCopySentence = async (sourceId, targetParagraphId, targetNarrativeId) => {
    try {
      this.setLoading(true)
      const copyRequest = {
        sourceId,
        targetParagraphId,
        targetNarrativeId
      }
      const newSentence = fromJS(
        await NarrativeDAO.postCopySentence(copyRequest)
      )
      let cp = this.copiedElements || []
      const copyResult = {
        type: "Copy Sentence",
        sourceId,
        targetParagraphId,
        targetNarrativeId,
        newId: newSentence.toJS().id
      }
      cp.push(copyResult)
      this.setCopiedElements(cp)
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postMoveSentence = async (sourceId, targetParagraphId, targetNarrativeId) => {
    try {
      this.setLoading(true)
      const copyRequest = {
        SourceId: sourceId,
        TargetParagraphId: targetParagraphId,
        TargetNarrativeId: targetNarrativeId
      }
      fromJS(await NarrativeDAO.postMoveSentence(copyRequest))
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postCopyParagraph = async (sourceId, targetNarrativeId) => {
    try {
      this.setLoading(true)
      const copyRequest = {
        SourceId: sourceId,
        TargetNarrativeId: targetNarrativeId
      }
      const newParagraph = fromJS(
        await NarrativeDAO.postCopyParagraph(copyRequest)
      )
      let cp = this.copiedElements || []
      const copyResult = {
        type: "Copy Paragraph",
        sourceId,
        targetParagraphId: "",
        targetNarrativeId,
        newId: newParagraph
      }
      cp.push(copyResult)
      this.setCopiedElements(cp)
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postCopyTrigger = async (sourceId, targetNarrativeId) => {
    try {
      this.setLoading(true)
      const copyRequest = {
        SourceId: sourceId,
        TargetNarrativeId: targetNarrativeId
      }
      const newTrigger = fromJS(await NarrativeDAO.postCopyTrigger(copyRequest))
      let cp = this.copiedElements || []
      const copyResult = {
        type: "Copy Trigger",
        sourceId,
        targetParagraphId: "",
        targetNarrativeId,
        newId: newTrigger
      }
      cp.push(copyResult)
      this.setCopiedElements(cp)
      return newTrigger
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postDuplicateNarrativeOutlineBlock = async (sourceId, targetNarrativeId) => {
    try {
      this.setLoading(true)
      const duplicateRequest = {
        SourceId: sourceId,
        TargetNarrativeId: targetNarrativeId
      }
      fromJS(
        await NarrativeDAO.postDuplicateNarrativeOutlineBlock(duplicateRequest)
      )
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postCopyNarrativeOutlineBlock = async (sourceId, targetNarrativeId) => {
    try {
      this.setLoading(true)
      const copyRequest = {
        SourceId: sourceId,
        TargetNarrativeId: targetNarrativeId
      }
      const copiedTo = fromJS(
        await NarrativeDAO.postCopyNarrativeOutlineBlock(copyRequest)
      )
      return copiedTo
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  copyNarrative = async narrativeId => {
    try {
      this.setIsCopyingNarrative(true)
      const response = fromJS(await NarrativeDAO.copyNarrative(narrativeId))
      return response
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setIsCopyingNarrative(false)
    }
  }

  getSnapshotTypes = async () => {
    try {
      this.setLoading(true)

      const snapshotTypes = fromJS(await NarrativePreviewDAO.getSnapshotTypes())

      this.setSnapshotTypes(snapshotTypes.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  postArchiveParagraph = async paragraphId => {
    try {
      this.setLoading(true)
      fromJS(await NarrativeDAO.postArchiveParagraph(paragraphId))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  postArchiveParagraphAndSentences = async paragraphId => {
    try {
      this.setLoading(true)
      fromJS(await NarrativeDAO.postArchiveParagraphAndSentences(paragraphId))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  postArchiveSentencesUnderParagraph = async paragraphId => {
    try {
      this.setLoading(true)
      fromJS(await NarrativeDAO.postArchiveSentencesUnderParagraph(paragraphId))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  postArchiveSentence = async sentenceId => {
    try {
      this.setLoading(true)
      fromJS(await NarrativeDAO.postArchiveSentence(sentenceId))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }
  getNarrativeModelTokens = async (narrativeId, contentId, contentType) => {
    try {
      this.setLoading(true)

      const tokens = fromJS(
        await NarrativeDAO.getNarrativeModelTokens(
          narrativeId,
          contentId,
          contentType
        )
      )

      this.setNarrativeModelTokens(tokens.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  getNarrativeModelSnapshots = async snapshotId => {
    try {
      this.setLoading(true)

      const narrativeModelSnapshots = fromJS(
        await NarrativePreviewDAO.getNarrativeModelSnapshots(snapshotId)
      )

      this.setNarrativeModelSnapshots(
        narrativeModelSnapshots.get("content", List())
      )
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoading(false)
    }
  }

  previewOutline = async narrativePreview => {
    try {
      this.setLoading(true)

      const feedPreview = fromJS(
        await NarrativePreviewDAO.postPreviewFeedEntry(narrativePreview)
      )

      this.setFeedPreview(feedPreview.get("content", ""))
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  previewFeedEntry = async narrativePreview => {
    try {
      this.setLoading(true)

      const feedPreview = fromJS(
        await NarrativePreviewDAO.postPreviewFeedEntry(narrativePreview)
      )

      this.setFeedPreview(feedPreview.get("content", ""))
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  postFeedEntry = async narrativePreview => {
    try {
      this.setLoading(true)

      await NarrativePreviewDAO.postFeedEntry(narrativePreview)
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  evaluateTriggersMetadata = async renderInstructions => {
    try {
      this.setLoading(true)

      const triggers = fromJS(
        await NarrativePreviewDAO.evaluateTriggers(renderInstructions)
      )

      this.setTriggersPreview(triggers.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }
  evaluateSentence = async renderInstructions => {
    try {
      this.setLoading(true)

      const responseText = fromJS(
        await NarrativePreviewDAO.evaluateSentence(renderInstructions)
      )
      this.setStarterPreview(responseText)
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }
  updateTriggersMetadata = async renderInstructions => {
    try {
      this.setLoading(true)

      const triggers = fromJS(
        await NarrativePreviewDAO.updateTriggers(renderInstructions)
      )

      this.setTriggersPreview(triggers.get("content", List()))
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  openNarrativeBuilderModal = () => {
    this.setIsNarrativeBuilderOpen(true)
  }

  closeNarrativeBuilderModal = () => {
    this.setIsNarrativeBuilderOpen(false)
  }

  getSourceNarrative = async id => {
    try {
      if (!id) {
        return
      }

      this.setSourceNarrative(Map())
      this.setLoadingSourceNarrative(true)

      const narrative = Map(await NarrativeDAO.getNarrative(id))
      let narrativeObj = narrative.get("content")

      this.setSourceNarrative(narrativeObj)

      this.setLoadingSourceNarrative(false)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getTargetNarrative = async id => {
    try {
      if (!id) {
        return
      }

      this.setTargetNarrative(Map())
      this.setLoadingTargetNarrative(true)

      const narrative = Map(await NarrativeDAO.getNarrative(id))
      let narrativeObj = narrative.get("content")
      this.setTargetNarrative(narrativeObj)
      this.setLoadingTargetNarrative(false)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getSourceNarrativeBrief = async id => {
    try {
      if (!id) {
        return
      }

      this.setSourceNarrative(Map())
      this.setLoadingSourceNarrative(true)

      const narrative = Map(await NarrativeDAO.getNarrativeBrief(id))
      let narrativeObj = narrative.get("content")

      this.setSourceNarrative(narrativeObj)

      this.setLoadingSourceNarrative(false)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getTargetNarrativeBrief = async id => {
    try {
      if (!id) {
        return
      }

      this.setTargetNarrative(Map())
      this.setLoadingTargetNarrative(true)

      const narrative = Map(await NarrativeDAO.getNarrativeBrief(id))
      let narrativeObj = narrative.get("content")
      this.setTargetNarrative(narrativeObj)
      this.setLoadingTargetNarrative(false)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getSourceNarrativeBriefLibrary = async id => {
    try {
      if (!id) {
        return
      }

      this.setSourceNarrative(Map())
      this.setLoadingSourceNarrative(true)

      const narrative = Map(await NarrativeDAO.getNarrativeBriefLibrary(id))
      let narrativeObj = narrative.get("content")

      this.setSourceNarrative(narrativeObj)

      this.setLoadingSourceNarrative(false)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getTargetNarrativeBriefLibrary = async id => {
    try {
      if (!id) {
        return
      }

      this.setTargetNarrative(Map())
      this.setLoadingTargetNarrative(true)

      const narrative = Map(await NarrativeDAO.getNarrativeBriefLibrary(id))
      let narrativeObj = narrative.get("content")
      this.setTargetNarrative(narrativeObj)
      this.setLoadingTargetNarrative(false)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getNarrativeBase = async id => {
    try {
      if (!id) {
        return
      }
      this.setLoadingNarrative(true)
      const narrative = fromJS(await NarrativeDAO.getNarrativeBase(id))
      this.setNarrative(narrative.toJS())
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoadingNarrative(false)
    }
  }

  //Loads the brief directly
  getNarrativeBrief = async id => {
    try {
      if (!id) {
        return
      }
      this.dehydrateNarrative()
      this.setLoadingNarrative(true)
      const responseMap = Map(await NarrativeDAO.getNarrativeBrief(id))
      const narrative = responseMap.get("content")
      const libraryIds = narrative.libraryNarrative_Ids?.split(/[, ]+/)
      const shouldReload = true
      if (libraryIds?.length > 0) {
        const libraryParagraphs = await this.getLibraryParagraphsBrief(
          libraryIds,
          shouldReload
        )
        narrative.libraryParagraphs = libraryParagraphs
      }
      this.setNarrative(narrative)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoadingNarrative(false)
    }
  }
  //Loads the brief withoutdehydratings
  reloadNarrativeBrief = async id => {
    try {
      if (!id) {
        return
      }
      this.setLoadingNarrative(true)
      const responseMap = Map(await NarrativeDAO.getNarrativeBrief(id))
      const narrative = responseMap.get("content")
      const libraryIds = narrative.libraryNarrative_Ids?.split(/[, ]+/)
      const shouldReload = true
      if (libraryIds?.length > 0) {
        const libraryParagraphs =
          this.libraryParagraphs ||
          (await this.getLibraryParagraphsBrief(libraryIds, shouldReload))
        if (!this.libraryParagraphs) {
          this.setLibraryParagraphs(libraryParagraphs)
        }
        narrative.libraryParagraphs = libraryParagraphs
      }
      this.setNarrative(narrative)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoadingNarrative(false)
    }
  }

  reloadNarrativeLibraryParagraphs = async () => {
    try {
      this.setLoadingNarrative(true)
      const libraryIds = this.narrative.libraryNarrative_Ids?.split(/[, ]+/)
      if (libraryIds?.length > 0) {
        const libraryParagraphs = await this.getLibraryParagraphsBrief(
          libraryIds,
          true
        )
        if (libraryParagraphs) {
          this.setLibraryParagraphs(libraryParagraphs)
        }
        this.narrative.libraryParagraphs = libraryParagraphs
      }
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoadingNarrative(false)
    }
  }

  getLibraryParagraphsBrief = async (ids, includeVariationScores = false) => {
    try {
      const data = await Promise.all(
        ids.map(async id => {
          this.getNarrativeParagraphTags(id)
          this.getNarrativeParagraphTagsSummary(id)
          const { content } = await NarrativeDAO.getLibraryParagraphsBrief(
            id,
            includeVariationScores
          )
          return content
        })
      )
      return data.flat()
    } catch (err) {
      console.error(`Error retrieving Library ${ids} paragraphs brief.`)
    }
  }

  getNarrativeParagraphTags = async id => {
    try {
      const narrativeParagraphTags = fromJS(
        await NarrativeDAO.getNarrativeParagraphTags(id)
      )
      const narrativeParagraphTagsSorted =
        narrativeParagraphTags?.toJS().sort(function (a, b) {
          if (a.paragraphId < b.paragraphId) {
            return -1
          } else if (a.paragraphId > b.paragraphId) {
            return 1
          } else {
            return a.tagName && a.tagName.localeCompare(b.tagName)
          }
        }) || []
      this.setNarrativeParagraphTags(narrativeParagraphTagsSorted)
    } catch (err) {
      console.error(`Error retrieving Library ${id} paragraphs Tags.`)
    }
  }

  getNarrativeParagraphTagsSummary = async id => {
    try {
      const narrativeParagraphTagsSummary = fromJS(
        await NarrativeDAO.getNarrativeParagraphTagsSummary(id)
      )
      const narrativeParagraphTagsSummarySorted =
        narrativeParagraphTagsSummary?.toJS().sort(function (a, b) {
          if (a.tagTypeId < b.tagTypeId) {
            return -1
          } else if (a.tagTypeId > b.tagTypeId) {
            return 1
          } else {
            return a.tagName && a.tagName.localeCompare(b.tagName)
          }
        }) || []
      this.setNarrativeParagraphTagsSummary(narrativeParagraphTagsSummarySorted)
    } catch (err) {
      console.error(`Error retrieving Library ${id} paragraphs Tags Summary.`)
    }
  }

  /**
   * Reactions
   */
  getNarrative = reaction(
    () => this.narrativeId,
    async id => {
      try {
        if (!id) {
          return
        }
        this.dehydrateNarrative()
        this.setLoadingNarrative(true)
        const shouldReload = true
        const { content } = await NarrativeDAO.getNarrativeBrief(id)
        const ids = content.libraryNarrative_Ids?.split(/[, ]+/)
        const narrative = { ...content }
        if (ids?.length > 0) {
          const libraryParagraphs =
            this.libraryParagraphs ||
            (await this.getLibraryParagraphsBrief(ids, shouldReload))
          if (!this.libraryParagraphs) {
            this.setLibraryParagraphs(libraryParagraphs)
          }
          narrative.libraryParagraphs = libraryParagraphs
        }
        this.prevNarrativeLibraryIds = narrative.libraryNarrative_Ids
        this.setNarrative(narrative)
        this.rootStore.organizationStore.setOrganizationId(
          narrative.organization.id
        )
      } catch (err) {
        console.error("Error: ", err)
        this.rootStore.uiStore.setIsError(true)
        this.rootStore.uiStore.setErrorMessage(
          `Narrative ${this.narrativeId} does not exist.`
        )
      } finally {
        this.setIsDirty(false)
        this.setLoadingNarrative(false)
      }
    }
  )

  getSnapshotDerivedOutlineBlocks = async narrativeId => {
    try {
      if (!narrativeId) {
        return
      }
      this.setNarrativeOutlineBlocks(this.outlineBlocksOriginal || Map())
      this.setRenderTemplate(Map())
      this.setLoadingOutlineBlocks(true)
      const responseMap = Map(
        await NarrativeDAO.getSnapshotDerivedOutlineBlocks(narrativeId)
      )
      const renderTemplate = responseMap.get("content")
      this.setRenderTemplate(renderTemplate)
      const tmpBlocks = this.processDerivedSampleBlocks(
        renderTemplate.outlineBlocks
      )
      const mergedBlocks = this.mergeOutlineBlocks(tmpBlocks)
      this.setNarrativeOutlineBlocks(mergedBlocks)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoadingOutlineBlocks(false)
    }
  }

  getBrokenBlockSnapShot = async entryId => {
    if (!entryId) {
      return
    }
  }

  getFeedEntryDerivedOutlineBlocks = async feedEntryId => {
    try {
      if (!feedEntryId) {
        return
      }
      this.setNarrativeOutlineBlocks(this.outlineBlocksOriginal || Map())
      this.setRenderTemplate(Map())
      this.setLoadingOutlineBlocks(true)
      const responseMap = Map(
        await NarrativeDAO.getFeedEntryDerivedOutlineBlocks(feedEntryId)
      )
      const renderTemplate = responseMap.get("content")
      this.setRenderTemplate(renderTemplate)
      const tmpBlocks = this.processDerivedSampleBlocks(
        renderTemplate.outlineBlocks
      )
      const mergedBlocks = this.mergeOutlineBlocks(tmpBlocks)
      this.setNarrativeOutlineBlocks(mergedBlocks)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoadingOutlineBlocks(false)
    }
  }

  postNarrativeOutlineBlock = async outlineBlock => {
    try {
      this.setPostingBlock(true)
      let updatedNarrativeOutlineBlock = fromJS(outlineBlock)
      const newNarrativeOutlineBlockId = fromJS(
        await NarrativeDAO.postNarrativeOutlineBlock(outlineBlock)
      )
      if (!outlineBlock.isArchived) {
        const updateBlockObject = updatedNarrativeOutlineBlock.toObject()
        updateBlockObject.id = newNarrativeOutlineBlockId
        let outlineBlocks = [...this.outlineBlocks]
        const existingIndex = outlineBlocks.findIndex(
          b =>
            b.id === newNarrativeOutlineBlockId ||
            (outlineBlock.tmpId && outlineBlock.tmpId === b.tmpId)
        )
        if (existingIndex < 0) {
          outlineBlocks.push(updateBlockObject)
        } else {
          outlineBlocks[existingIndex] = updateBlockObject
        }
        this.mergeOutlineBackups(updateBlockObject)
        this.setNarrativeOutlineBlocks(outlineBlocks)
      } else {
        let tmpOutlineBlocks = [...this.outlineBlocks]
        //Remove the block and paragraphs from the store collections
        tmpOutlineBlocks = tmpOutlineBlocks.filter(
          b => b.id !== newNarrativeOutlineBlockId
        )
        let paragraphs =
          this.narrative.paragraphs &&
          this.narrative.paragraphs.filter(
            p => p.narrativeOutlineBlockId !== newNarrativeOutlineBlockId
          )
        let newNarrative = {
          ...this.narrative,
          outlineBlocks: tmpOutlineBlocks,
          clearingOutlineBlocks: tmpOutlineBlocks.length === 0 ? true : false,
          paragraphs
        }
        this.setNarrative(newNarrative)
        // Remove the block from the orginal narrative collection
        const outlineBlocksOriginal = this.outlineBlocksOriginal.filter(
          b => b.id !== newNarrativeOutlineBlockId
        )
        this.setNarrativeOutlineBlocksOriginal(outlineBlocksOriginal)
        if (newNarrative.buildFromOutline) {
          const elementBlocks = tmpOutlineBlocks.filter(
            b => b.contentBlock === outlineBlock.contentBlock
          )
          this.updateElementOutlineBlockIndexes(elementBlocks)
        }
      }
    } catch (err) {
      console.error("Error: ", err)
      throw err
    } finally {
      this.setPostingBlock(false)
    }
  }

  // Reorder all blocks, update the outline blocks, and
  // update the child paragraphs for backward compatibility
  updateElementOutlineBlockIndexes = newOrder => {
    this.setLoadingOutlineBlocks(true)
    let sortedBlocks =
      this.outlineBlocks.sort((a, b) => {
        let comparison = 0
        if (a.position > b.position) {
          comparison = 1
        } else if (a.position < b.position) {
          comparison = -1
        }
        return comparison
      }) || []
    newOrder.forEach((nb, index) => {
      let oldBlock = sortedBlocks.find(ob => ob.id === nb.id)
      if (!oldBlock || oldBlock.position !== index) {
        nb.position = index
        this.postNarrativeOutlineBlock(nb)
      }
    })
    this.setLoadingOutlineBlocks(false)
  }

  getParagraphDerivedOutlineBlocks = async narrativeId => {
    try {
      if (!narrativeId) {
        return
      }
      this.setNarrativeOutlineBlocks(this.outlineBlocksOriginal || Map())
      this.setRenderTemplate(Map())
      this.setLoadingOutlineBlocks(true)
      const responseMap = Map(
        await NarrativeDAO.getParagraphDerivedOutlineBlocks(narrativeId)
      )

      const renderTemplate = responseMap.get("content")
      this.setRenderTemplate(renderTemplate)
      const tmpBlocks = this.processDerivedSampleBlocks(
        renderTemplate.outlineBlocks
      )
      const mergedBlocks = this.mergeOutlineBlocks(tmpBlocks)
      this.setNarrativeOutlineBlocks(mergedBlocks)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoadingOutlineBlocks(false)
    }
  }

  getBlockSamples = async narrativeId => {
    try {
      if (!narrativeId) {
        return
      }
      this.setNarrativeBlockSamples(this.outlineBlocksOriginal || Map())
      this.setLoadingBlockSamples(true)
      const responseMap = Map(await NarrativeDAO.getBlockSamples(narrativeId))

      const samples = responseMap.get("content")
      this.setNarrativeBlockSamples(samples)
    } catch (err) {
      console.error("Error: ", err)
    } finally {
      this.setLoadingBlockSamples(false)
    }
  }

  processDerivedSampleBlocks = incomingBlocks => {
    try {
      if (!incomingBlocks) {
        return
      }
      incomingBlocks.forEach((item, index) => {
        item.tmpId = index + 1
        item.shouldCreateParagraph = false
      })
      return incomingBlocks
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  updateOutlineBlocks = incomingBlock => {
    let mergedOutlineBlocks = [...this.outlineBlocks]

    if (mergedOutlineBlocks.length > 0) {
      const existingIndex = mergedOutlineBlocks.findIndex(
        b =>
          (incomingBlock.id &&
            incomingBlock.id !== 0 &&
            b.id === incomingBlock.id) ||
          (b.position === incomingBlock.position &&
            b.contentBlock === incomingBlock.contentBlock)
      )
      if (existingIndex < 0) {
        mergedOutlineBlocks.push(incomingBlock)
      } else {
        mergedOutlineBlocks[existingIndex] = incomingBlock
      }
      return mergedOutlineBlocks
    }
    return [...incomingBlock]
  }

  // Merge outline blocks will take derived blocks and put them alongside saved blocks
  mergeOutlineBlocks = incomingBlocks => {
    let mergedOutlineBlocks = [...this.outlineBlocks]

    if (mergedOutlineBlocks.length > 0) {
      incomingBlocks.forEach(block => {
        const existingIndex = mergedOutlineBlocks.findIndex(
          b =>
            b.position === block.position &&
            b.contentBlock === block.contentBlock
        )
        if (existingIndex < 0) {
          mergedOutlineBlocks.push(block)
        } else {
          let existingBlock = mergedOutlineBlocks[existingIndex]
          if (existingBlock.id) {
            if (block.sampleResult && !existingBlock.sampleResult) {
              existingBlock.sampleResult = block.sampleResult
            }
            mergedOutlineBlocks[existingIndex] = existingBlock
          } else {
            mergedOutlineBlocks[existingIndex] = block
          }
        }
      })
      return mergedOutlineBlocks
    }
    return [...incomingBlocks]
  }

  // The Backups are a copy of the original blocks we get from the server
  mergeOutlineBackups = incomingBlock => {
    let mergedOutlineBlocks = [...this.outlineBlocksOriginal]

    if (mergedOutlineBlocks.length > 0) {
      const existingIndex = mergedOutlineBlocks.findIndex(
        b => b.id === incomingBlock.id
      )
      if (existingIndex < 0) {
        mergedOutlineBlocks.push(incomingBlock)
      } else {
        mergedOutlineBlocks[existingIndex] = incomingBlock
      }
      this.outlineBlocksOriginal = mergedOutlineBlocks
      return mergedOutlineBlocks
    }
    return [incomingBlock]
  }

  removeOutlineBlock = block => {
    let outlineBlocks = this.outlineBlocks
    //Handle saved blocks differently that
    if (block.id && block.id > 0) {
      // Set the block to archived and post it
      block.isArchived = true
      this.postNarrativeOutlineBlock(block)
    } else {
      // This is to support building outlines from existing narratives
      if (block.tmpId && block.tmpId > 0) {
        // If there is a tmp id we can remove it that way
        outlineBlocks = outlineBlocks.filter(b => b.tmpId !== block.tmpId)
      } else {
        // Try to match on position and block (could remove multiple)
        outlineBlocks = outlineBlocks.filter(
          b =>
            !(b.id === null,
            b.tmpId === null,
            b.position === block.position &&
              b.contentBlock === block.contentBlock)
        )
      }
      this.setNarrativeOutlineBlocks(outlineBlocks)
      this.updateElementOutlineBlockIndexes(outlineBlocks)
    }
  }

  getStarters = async id => {
    try {
      if (!id) {
        return
      }

      //this.setStarters(Map())
      this.setLoadingStarters(true)

      const starter = Map(await NarrativeDAO.getStarters(id))
      let startersObj = starter.get("content", List())
      this.setStarters([startersObj])
      this.setLoadingStarters(false)
    } catch (err) {
      console.error("Error: ", err)
    }
  }

  getSearchResults = reaction(
    () => this.searchString,
    async searchString => {
      try {
        if (!searchString) {
          return
        }
        this.setSearchResults(Map())
        this.setLoadingSearchResults(true)
        this.setLoadedSearchResults(false)
        const responseMap = Map(
          await NarrativeDAO.getSearchResults(searchString)
        )
        const searchResults = responseMap.get("content")
        this.setSearchResults(searchResults)
      } catch (err) {
        console.error("Error: ", err)
      } finally {
        this.setLoadedSearchResults(true)
        this.setLoadingSearchResults(false)
      }
    }
  )
  /**
   * Internal Actions
   */
  dehydrate() {
    this.dehydrateNarrative()
    this.setLoading(false)
    this.setLoadingBlockSamples(false)
    this.setLoadingOutlineBlocks(false)
    this.setLoadingNarrative(false)
    this.setLoadingSourceNarrative(false)
    this.setLoadingTargetNarrative(false)
    this.setNarratives(List())
    this.setLibraryNarratives(List())
    this.setModelTypes(List())
    this.setTriggers(List())
    this.setTriggersBrief(List())
    this.setTriggerTypeCategories(List())
    this.setSnapshotTypes(List())
    this.setNarrativeModelSnapshots(List())
    this.setFeedPreview(null)
    this.setTriggersPreview(List())
    this.setStarterPreview(null)
    this.setOpenModal(false)
    this.setOpenLogModal(false)
    this.setErrorCode(null)
  }

  dehydrateNarrative() {
    this.setNarrative(Map())
    this.setRenderTemplate(Map())
    this.setNarrativeBlockSamples(Map())
    this.setNarrativeOutlineBlocks(Map())
    this.setNarrativeOutlineBlocksOriginal(Map())
    this.setSourceNarrative(Map())
    this.setTargetNarrative(Map())
    this.setIsDirty(false)
    this.setNarrativeModelTokens(List())
    this.setLibraryParagraphs(null)
  }

  setLoading(value) {
    this.loading = value
  }

  setPostingBlock(value) {
    this.postingNarrativeBlock = value
  }

  setLoadingParagraph(value) {
    this.loadingParagraph = value
  }

  setLoadingSentence(value) {
    this.loadingSentence = value
  }

  setLoadingNarrative(value) {
    this.loadingNarrative = value
  }

  setLoadingSourceNarrative(value) {
    this.loadingSourceNarrative = value
  }

  setLoadingTargetNarrative(value) {
    this.loadingTargetNarrative = value
  }

  setNarrativeId(value) {
    this.narrativeId = value
  }

  setIsDirty(value) {
    this.isDirty = value
  }

  setNarratives(value) {
    this.narratives = value
  }

  setLibraryNarratives(value) {
    this.libraryNarratives = value
  }

  setNarrative(value) {
    this.narrative = value
    this.outlineBlocksOriginal =
      value.outlineBlocks && value.outlineBlocks.length > 0
        ? value.outlineBlocks
        : Map()
    this.outlineBlocks =
      value.outlineBlocks && value.outlineBlocks.length > 0
        ? value.outlineBlocks
        : value.clearingOutlineBlocks
        ? value.outlineBlocks
        : this.outlineBlocks
  }

  setNarrativeParagraphTags(value) {
    this.narrativeParagraphTags = value
  }

  setNarrativeParagraphTagsSummary(value) {
    this.narrativeParagraphTagsSummary = value
  }

  setLoadingOutlineBlocks(value) {
    this.loadingOutlineBlocks = value
  }

  setLoadingBlockSamples(value) {
    this.loadingBlockSamples = value
  }

  setRenderTemplate(value) {
    this.renderTemplate = value
  }

  setNarrativeOutlineBlocks(value) {
    const newNarrative = {
      ...this.narrative,
      outlineBlocks: value
    }
    this.narrative = newNarrative
    this.outlineBlocks = value
  }

  setNarrativeBlockSamples(value) {
    this.blockSamples = value
  }

  setNarrativeOutlineBlocksOriginal(value) {
    this.outlineBlocksOriginal = value
  }

  setLoadingSearchResults(value) {
    this.loadingSearchResults = value
  }

  setLoadingStarters(value) {
    this.loadingStarters = value
  }

  setLoadedSearchResults(value) {
    this.loadedSearchResults = value
  }

  setSearchString(value) {
    this.searchString = value
  }

  setSearchResults(value) {
    this.searchResults = value
  }

  setStarters(value) {
    this.starters = value
  }

  setCopiedElements(value) {
    this.copiedElements = value
  }

  setSourceNarrative(value) {
    this.sourceNarrative = value
  }

  setTargetNarrative(value) {
    this.targetNarrative = value
  }

  setModelTypes(value) {
    this.modelTypes = value
  }

  setTriggers(value) {
    this.triggers = value
  }

  setTriggersBrief(value) {
    this.triggersBrief = value
  }

  setTriggerTypeCategories(value) {
    this.triggerTypeCategories = value
  }

  setSnapshotTypes(value) {
    this.snapshotTypes = value
  }

  setNarrativeModelSnapshots(value) {
    this.narrativeModelSnapshots = value
  }

  setNarrativeModelTokens(value) {
    this.narrativeModelTokens = value
  }

  setFeedPreview(value) {
    this.feedPreview = value
  }

  setTriggersPreview(value) {
    this.triggersPreview = value
  }

  setStarterPreview(value) {
    this.starterPreview = value
  }

  setLibraryParagraphs(value) {
    this.libraryParagraphs = value
  }

  setArticleTypes(value) {
    this.articleTypes = value
  }

  setContentTypes(value) {
    this.contentTypes = value
  }

  setVerticals(value) {
    this.verticals = value
  }

  setOutputAttributes(value) {
    this.outputAttributes = value
  }

  setDatasources(value) {
    this.datasources = value
  }

  setBookmakers(value) {
    this.bookmakers = value
  }

  setIsNarrativeBuilderOpen(value) {
    this.isNarrativeBuilderOpen = value
  }

  setResponseCode(value) {
    this.responseCode = value
  }

  setResponseMessage(value) {
    this.responseMessage = value
  }

  setNarrativeMissingTriggersMetadata(value) {
    this.narrativeMissingTriggersMetadata = value
  }

  setHealthCheckLogTableMetadata(value) {
    this.healthCheckLogTableMetadata = value
  }

  setNarrativeMissingTriggersUpdatedMetadata(value) {
    this.narrativeMissingTriggersUpdatedMetadata = value
  }

  setNarrativeMissingTriggersTableData(value) {
    this.narrativeMissingTriggersTableData = value
  }

  setOpenModal(value) {
    this.openModal = value
  }

  setOpenLogModal(value) {
    this.openLogModal = value
  }
  setErrorCode(value) {
    this.errorCode = value
  }

  setHealthCheckState(value) {
    this.healthCheckState = value
  }

  setHealthCheckLogState(value) {
    this.healthCheckLogState = value
  }

  setIsCopyingNarrative(value) {
    this.isCopyingNarrative = value
  }

  updateNarratives(narrative) {
    const narrativeIdx = this.narratives.findIndex(
      a => a.get("id") === narrative.get("id")
    )

    // update narrative in state
    if (narrativeIdx !== -1) {
      this.setNarratives(this.narratives.set(narrativeIdx, narrative))
    } else {
      this.setNarratives(this.narratives.push(narrative))
    }
  }

  updateParagraphs(paragraph) {
    if (!this.narrative?.paragraphs) {
      return
    }
    const narrative = this.narrative
    const { paragraphs } = narrative
    const paragraphIdx = paragraphs.findIndex(a => a.id === paragraph.id)
    // update paragraphs in state
    if (paragraphIdx !== -1) {
      const thisParagraph = paragraphs[paragraphIdx]
      if (!paragraph.sentences && thisParagraph.sentences?.length > 0) {
        paragraph.sentences = thisParagraph.sentences
      }
      paragraphs[paragraphIdx] = paragraph
      narrative.paragraphs = paragraphs
    } else {
      narrative.paragraphs = [...paragraphs, paragraph]
    }
    narrative.paragraphs = narrative.paragraphs.filter(np => !np.isArchived)
    this.setNarrative(narrative)
  }

  updateSentences(updatedSentence) {
    const sid = updatedSentence?.id || updatedSentence?.createdSentenceId
    const pid = updatedSentence?.paragraph_Id
    let found = false
    let refetch = false
    const paragraph = this.narrative.paragraphs.find(p => p.id === pid)
    if (paragraph) {
      paragraph.sentences.forEach((itm2, j) => {
        if (itm2.id === sid) {
          found = true
          let currentSentence = paragraph.sentences[j]
          if (
            currentSentence.isLibraryPlaceholder !==
              updatedSentence.isLibraryPlaceholder ||
            currentSentence.libraryParagraph_Id !==
              updatedSentence.libraryParagraph_Id ||
            currentSentence.librarySentencePosition !==
              updatedSentence.librarySentencePosition
          ) {
            refetch = true
            return
          } else {
            paragraph.sentences[j] = updatedSentence
            paragraph.sentences = [...paragraph.sentences]
          }
        }
      })
      if (!found) {
        const newSentenceValue = {
          ...updatedSentence,
          id: sid
        }
        paragraph.sentences = [...paragraph.sentences, newSentenceValue]
        found = true
      }
    }
    if (found) {
      this.updateParagraphs(paragraph)
    }
    if (refetch) {
      this.reloadNarrativeBrief(this.narrative.id)
    }
  }

  updateTriggers(trigger) {
    const triggerIdx = this.triggers.findIndex(
      a => a.get("id") === trigger.get("id")
    )

    // update triggers in state
    if (triggerIdx !== -1) {
      this.setTriggers(this.triggers.set(triggerIdx, trigger))
    } else {
      this.setTriggers(this.triggers.push(trigger))
    }
  }
}

/**
 * object decorators
 */
decorate(NarrativeStore, {
  // computeds
  isNarrativeSelected: computed,

  // observables
  loading: observable,
  postingNarrativeBlock: observable,
  loadingSentence: observable,
  loadingParagraph: observable,
  loadingNarrative: observable,
  loadingSourceNarrative: observable,
  loadingTargetNarrative: observable,
  loadingOutlineBlocks: observable,
  narrativeParagraphTags: observable,
  narrativeParagraphTagsSummary: observable,
  outlineBlocks: observable,
  copiedElements: observable,
  loadingBlockSamples: observable,
  blockSamples: observable,
  searchString: observable,
  loadingSearchResults: observable,
  loadedSearchResults: observable,
  searchResults: observable,
  starters: observable,
  narrativeId: observable,
  narrative: observable,
  sourceNarrative: observable,
  targetNarrative: observable,
  narratives: observable,
  libraryNarratives: observable,
  modelTypes: observable,
  triggers: observable,
  triggersBrief: observable,
  snapshotTypes: observable,
  narrativeModelTokens: observable,
  narrativeModelSnapshots: observable,
  feedPreview: observable,
  triggersPreview: observable,
  starterPreview: observable,
  isNarrativeBuilderOpen: observable,
  isDirty: observable,
  articleTypes: observable,
  datasources: observable,
  bookmakers: observable,
  contentTypes: observable,
  responseCode: observable,
  responseMessage: observable,
  narrativeMissingTriggersMetadata: observable,
  healthCheckLogTableMetadata: observable,
  narrativeMissingTriggersTableData: observable,
  openModal: observable,
  openLogModal: observable,
  isError: observable,
  healthCheckState: observable,
  healthCheckLogState: observable,
  verticals: observable,
  outputAttributes: observable,
  isCopyingNarrative: observable,

  // actions
  setLoading: action.bound,
  setPostingBlock: action.bound,
  setLoadingSentence: action.bound,
  setLoadingParagraph: action.bound,
  setLoadingNarrative: action.bound,
  setLoadingSourceNarrative: action.bound,
  setLoadingTargetNarrative: action.bound,
  setLoadingOutlineBlocks: action.bound,
  setNarrativeParagraphTags: action.bound,
  setNarrativeParagraphTagsSummary: action.bound,
  setLoadingBlockSamples: action.bound,
  setNarrativeBlockSamples: action.bound,
  setNarrativeId: action.bound,
  setNarrative: action.bound,
  setNarrativeOutlineBlocks: action.bound,
  setNarratives: action.bound,
  setLibraryNarratives: action.bound,
  setSourceNarrative: action.bound,
  setTargetNarrative: action.bound,
  setModelTypes: action.bound,
  setTriggers: action.bound,
  setTriggersBrief: action.bound,
  setSnapshotTypes: action.bound,
  setNarrativeModelTokens: action.bound,
  setNarrativeModelSnapshots: action.bound,
  setFeedPreview: action.bound,
  setTriggersPreview: action.bound,
  setStarterPreview: action.bound,
  setIsNarrativeBuilderOpen: action.bound,
  setSearchString: action.bound,
  setLoadingSearchResults: action.bound,
  setLoadingStarters: action.bound,
  setLoadedSearchResults: action.bound,
  setSearchResults: action.bound,
  setStarters: action.bound,
  setCopiedElements: action.bound,
  updateParagraphs: action.bound,
  setIsDirty: action.bound,
  setArticleTypes: action.bound,
  setContentTypes: action.bound,
  setDatasources: action.bound,
  setBookmakers: action.bound,
  setResponseCode: action.bound,
  setResponseMessage: action.bound,
  setNarrativeMissingTriggersMetadata: action.bound,
  setHealthCheckLogTableMetadata: action.bound,
  setNarrativeMissingTriggersUpdatedMetadata: action.bound,
  setNarrativeMissingTriggersTableData: action.bound,
  setOpenModal: action.bound,
  setOpenLogModal: action.bound,
  setHealthCheckState: action.bound,
  setHealthCheckLogState: action.bound,
  setVerticals: action.bound,
  setOutputAttributes: action.bound,
  removeLibraryParagraphAssociation: action.bound,
  setIsCopyingNarrative: action.bound
})
