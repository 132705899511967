import React, { useMemo, useEffect, useState } from "react" //useEffect, useState
import { DataGrid } from "@mui/x-data-grid"
import makeStyles from "@mui/styles/makeStyles"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import { Card, Typography, IconButton } from "@mui/material"
import { Edit } from "@mui/icons-material"
import { Add } from "@mui/icons-material"
import Button from "components/CustomButtons/Button"
import CardHeader from "components/Card/CardHeader.jsx"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import Tooltip from "@mui/material/Tooltip"
import Grid from "@mui/material/Grid"
import GridItem from "components/Grid/GridItem"
import { useHistory } from "react-router-dom"
import { Cookies } from "tools/storage"
import TimeAgo from "react-timeago"
import Helpers from "tools/Helpers"
import LinearProgress from "@mui/material/LinearProgress"
import OutputCreateForm from "components/RealTime/OutputCreateForm"

const useStyles = makeStyles(() => ({
  archiveSwitch: {
    display: "flex",
    justifyContent: "flex-end"
  },
  libraryGrid: {
    maxWidth: "100%",
    marginLeft: "75px",
    marginRight: "75px"
  },
  libraryRTCard: {
    paddingTop: "20px",
    //width: "1500px",
    paddingBottom: "24px"
  },
  tableLabel: {
    marginLeft: "50px",
    fontWeight: "bold",
    fontSize: "1.15rem"
  },
  libaryBtn: {
    paddingLeft: "16px"
  },
  cardTop: {
    backgroundColor: "#144e68",
    color: "white"
  },
  myFlex: {
    display: "flex",
    padding: "10px"
  },
  noDataGridLayout: {
    textAlign: "center",
    padding: "20px 50px",
    border: "1px solid #888",
    maxWidth: "1500px",
    margin: "0 100px",
    backgroundColor: "#ddeaef"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getRealTimeConfig: store.appConfigurationStore.getRealTimeConfig,
    displayRealTimeLibrary: store.appConfigurationStore.displayRealTimeLibrary,
    realTimeOutputs: store.realTimeStore.realTimeOutputs,
    getRealTimeOutputs: store.realTimeStore.getRealTimeOutputs,
    getAllRealTimeOutputs: store.realTimeStore.getAllRealTimeOutputs,
    allRealTimeOutputs: store.realTimeStore.allRealTimeOutputs,
    organizations: store.organizationStore.organizations
  }))
}

function RealTimeOutputs() {
  const classes = useStyles()
  const [openOutputFormDialog, setOpenOutputFormDialog] = useState(false)
  const [showArchivedOutputs, setShowArchivedOutputs] = useState(false)
  const [fetchingOutputs, setFetchingOutputs] = useState(false)
  const [fetchingAllOutputs, setFetchingAllOutputs] = useState(false)
  const [returnedOutputs, setReturnedOutputs] = useState([])
  const history = useHistory()

  const {
    getRealTimeConfig,
    displayRealTimeLibrary,
    getRealTimeOutputs,
    getAllRealTimeOutputs,
    realTimeOutputs,
    allRealTimeOutputs,
    organizations
  } = useStoreData()

  const handleOpenAddOutputPageClick = () => {
    setOpenOutputFormDialog(true)
  }
  const handleOutputsArchiveCheckboxChange = event => {
    setShowArchivedOutputs(!showArchivedOutputs)
  }

  const handleEditClick = (id, rowInfo) => {
    history.push(
      `/portal/real-time-output/${id}${
        rowInfo?.library?.id ? `?libraryId=${rowInfo.library.id}` : ``
      }`
    )
  }

  const RealTimeOutputsColumns = useMemo(() => [
    {
      headerName: "ID",
      field: "id",
      width: 50
    },
    {
      headerName: "Action",
      field: "action",
      renderCell: params => (
        <IconButton
          color="primary"
          onClick={() => handleEditClick(params.row.id, params.row)}
        >
          <Edit />
        </IconButton>
      ),
      width: 50
    },
    {
      headerName: "Name",
      field: "name",
      width: 200
    },
    {
      headerName: "Output Type",
      field: "outputType",
      renderCell: cell => (
        <div>
          {cell?.row?.outputType?.name ? cell.row.outputType.name : null}
        </div>
      ),
      width: 100
    },
    {
      headerName: "Delivery Type",
      field: "deliveryType",
      renderCell: cell => (
        <div>
          {cell?.row?.deliveryType?.name ? cell.row.deliveryType.name : null}
        </div>
      ),
      width: 100
    },
    {
      headerName: "Organization",
      field: "organizationId",
      renderCell: cell => (
        <div>
          {cell?.row?.organization?.name ? cell.row.organization.name : null}
        </div>
      ),
      width: 180
    },
    {
      headerName: "Vertical",
      field: "verticalId",
      renderCell: cell => (
        <div>{cell?.row?.vertical?.name ? cell.row.vertical.name : null}</div>
      ),
      width: 100
    },
    {
      headerName: "League",
      field: "league",
      renderCell: cell => (
        <div>{cell?.row?.league?.name ? cell.row.league.name : null}</div>
      ),
      width: 150
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: cell => (
        <div>{cell?.row?.status?.name ? cell.row.status.name : null}</div>
      ),
      width: 100
    },
    {
      headerName: "Audit Info",
      field: "auditInfo",
      renderCell: cell => (
        <span>
          Last modified{" "}
          <TimeAgo
            date={cell?.row?.modifiedDateTime}
            title={Helpers.prettyDateTimeinPacificTimeZone(
              cell?.row?.modifiedDateTime
            )}
          />{" "}
          by {cell?.row?.modifiedByAccount?.name}
        </span>
      ),
      width: 300
    }
  ])

  let cookies = new Cookies()
  const orgId = cookies.get("currentorg")
  const propertyId = cookies.get("currentProperty")

  const getValidOutputsByPropetyOfOrg = () => {
    const myProperties = [Number(orgId)]
    const orgs = organizations?.toJS()
    orgs?.forEach(org => {
      if (org.id === Number(orgId)) {
        org.properties?.forEach(prp => {
          myProperties.push(prp.id)
        })
      }
    })
    if (myProperties.length && allRealTimeOutputs.length) {
      const myOutputs = []
      allRealTimeOutputs.forEach(x => {
        if (x.organization?.id && myProperties.includes(x.organization.id)) {
          myOutputs.push(x)
        }
      })
      setReturnedOutputs(myOutputs)
    } else {
      setReturnedOutputs([])
    }
  }

  useEffect(() => {
    if (orgId && propertyId) {
      if (propertyId === "All Properties") {
        setFetchingAllOutputs(true)
        getAllRealTimeOutputs()
      } else {
        setFetchingOutputs(true)
        getRealTimeOutputs(propertyId)
      }
    } else if (orgId) {
      setFetchingAllOutputs(true)
      getAllRealTimeOutputs()
      // setFetchingOutputs(true)
      // getRealTimeOutputs(orgId)
    }
  }, [])

  useEffect(() => {
    if (orgId && propertyId) {
      if (propertyId === "All Properties") {
        setFetchingAllOutputs(true)
        getAllRealTimeOutputs()
      } else {
        setFetchingOutputs(true)
        getRealTimeOutputs(propertyId)
      }
    } else if (orgId) {
      setFetchingAllOutputs(true)
      getAllRealTimeOutputs()
      // setFetchingOutputs(true)
      // getRealTimeOutputs(orgId)
    }
  }, [orgId, propertyId])

  useEffect(() => {
    setReturnedOutputs(realTimeOutputs)
    setFetchingOutputs(false)
  }, [realTimeOutputs])

  useEffect(() => {
    if (allRealTimeOutputs) {
      getValidOutputsByPropetyOfOrg()
      console.log()
    }
    setFetchingAllOutputs(false)
  }, [allRealTimeOutputs])

  useEffect(() => {
    const fetchConfig = async () => {
      await getRealTimeConfig()
    }
    fetchConfig()
  }, [displayRealTimeLibrary])

  if (!displayRealTimeLibrary) {
    return "Under Construction, Manage Real Time Outputs Page"
  }

  const filteredRealTimeOutputs =
    returnedOutputs?.filter(x =>
      showArchivedOutputs ? true : !x.isArchived
    ) || []

  return (
    <>
      <Card>
        <CardHeader className={classes.cardTop}>
          <Grid container justifyContent="center" className={classes.myFlex}>
            <GridItem xs={12} sm={12} style={{ textAlign: "left" }}>
              <Typography variant="info" />
              <Button
                className={classes.libaryBtn}
                color="primary"
                onClick={() => handleOpenAddOutputPageClick()}
                startIcon={<Add />}
              >
                Add Output
              </Button>
            </GridItem>
          </Grid>
        </CardHeader>
        <Card
          elevation={0}
          aria-label="RealTimeOutputs Card DataGridContainer"
          className={classes.libraryRTCard}
        >
          <Grid container justifyContent="center" className={classes.myFlex}>
            <GridItem xs={12} sm={6}>
              <Typography className={classes.tableLabel}>
                Outputs for Real Time
              </Typography>
            </GridItem>{" "}
            <GridItem
              xs={12}
              sm={6}
              style={{ textAlign: "right", paddingRight: "50px" }}
            >
              <Tooltip title="Active" placement="top">
                <FormControlLabel
                  className={classes.styledSwitch}
                  control={
                    <Switch
                      id="showArchivedOutputs"
                      checked={showArchivedOutputs}
                      onChange={handleOutputsArchiveCheckboxChange}
                      color="primary"
                      size="small"
                    />
                  }
                  label="Include Archived?"
                />
              </Tooltip>
            </GridItem>
          </Grid>

          {!fetchingOutputs && filteredRealTimeOutputs.length > 0 && (
            <DataGrid
              aria-label="CTAV2 RealTimeOutputs Datagrid"
              className={classes.libraryGrid}
              columns={RealTimeOutputsColumns}
              pagination
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "asc" }]
                },
                pagination: { paginationModel: { pageSize: 10 } }
              }}
              pageSizeOptions={[10, 25, 50]}
              sx={{
                "& .MuiDataGrid-columnHeaders": { backgroundColor: "#ddeaef" }
              }}
              rows={filteredRealTimeOutputs}
            />
          )}
          {!fetchingOutputs && filteredRealTimeOutputs.length === 0 && (
            <div className={classes.noDataGridLayout}>
              <Typography className={classes.tableLabel}>
                No Outputs returned
              </Typography>
            </div>
          )}
          {(fetchingOutputs || fetchingAllOutputs) && <LinearProgress />}
        </Card>
      </Card>
      {openOutputFormDialog && (
        <OutputCreateForm
          mode="create"
          closePage={() => {
            setOpenOutputFormDialog(false)
          }}
        />
      )}
    </>
  )
}
export default RealTimeOutputs
