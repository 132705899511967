import React, { useState, useMemo, useEffect } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import WebhookDAO from "daos/webhookDAO"
import { Cookies } from "tools/storage"

import CustomTable from "components/Table/CustomTable"
import TimeAgo from "react-timeago"
import Helpers from "tools/Helpers.js"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import GridItem from "components/Grid/GridItem"
import { makeStyles } from "@mui/styles"
//Icons
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import CheckIcon from "@mui/icons-material/CheckCircle"
import ReportIcon from "@mui/icons-material/Report"
import LinearProgress from "@mui/material/LinearProgress"

const useStyles = makeStyles({
  archiveSwitch: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "36px"
  },
  ok: {
    color: "#66bb6a",
    height: "24px"
  },
  stop: {
    color: "#a31545",
    height: "24px"
  },
  progressQueueTable: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "primary"
  }
})
function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    organizationId: store.organizationStore.organizationId,
    account: store.accountStore.account,
    isPermissionsLoaded: store.uiStore.isPermissionsLoaded,
    accountPermissions: store.accountStore.accountPermissions,
    getOutboundWebhookByOrg: store.webhookStore.getOutboundWebhookByOrg,
    webhookId: store.webhookStore.webhookId,
    outboundWebhookQueue: store.webhookStore.outboundWebhookQueue
  }))
}

export default function OutboundWebhookTable({
  webhookId,
  onEditClick,
  editForm,
  webhookManagerState
}) {
  const classes = useStyles()
  const { setShowChangeCurrentOrganization, organizationId } = useStoreData()

  const [loading, setLoading] = useState(false)
  const [queueLoading, setQueueLoading] = useState(false)
  const [outboundWebhooks, setOutboundWebhooks] = useState([])
  const [outboundWebhookQueueSent, setOutboundWebhookQueueSent] = useState([])
  const [expanded, setExpanded] = useState({})
  const [isArchived, setIsArchived] = useState(false)

  const cookies = new Cookies()
  const currentProp = Number(cookies.get("currentProperty")) || -1
  const filteredWebhook = outboundWebhooks.filter(
    webhook => currentProp < 0 || webhook.organization?.id === currentProp
  )
  useEffect(() => {
    setShowChangeCurrentOrganization(true)
    loadOutboundWebhooks(organizationId)
  }, [organizationId, webhookId, webhookManagerState, isArchived])

  const loadOutboundWebhooks = organizationId => {
    setLoading(true)
    WebhookDAO.getOutboundWebhookByOrg(organizationId)
      .then(data => {
        //remove archived records isArchived = false
        const filteredWebhooks = isArchived
          ? data.content
          : data.content.filter(webhook => !webhook.isArchived)
        setOutboundWebhooks(filteredWebhooks)
      })
      .catch(error => {
        console.error("Error loading outbound webhook by org:", error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const loadWebhookOutboundQueueSent = async webhookId => {
    setQueueLoading(true)
    try {
      const data = await WebhookDAO.loadWebhookOutboundQueueSent(webhookId)
      setOutboundWebhookQueueSent(data.content)
    } catch (error) {
      console.error("Error loading outbound webhook queue:", error)
    } finally {
      setQueueLoading(false)
    }
  }

  const handleEditClick = rowData => {
    onEditClick(rowData)
  }

  const onExpandedQueueSentChange = (newExpanded, rowIndex, event) => {
    const webhookId = outboundWebhooks[rowIndex].id
    if (newExpanded[rowIndex] === false) {
      setExpanded({}) // Collapse all rows
    } else {
      const updatedExpanded = {}
      Object.keys(newExpanded).forEach(k => {
        updatedExpanded[k] = k === rowIndex ? {} : false
      })
      setExpanded(updatedExpanded)
      loadWebhookOutboundQueueSent(webhookId)
    }
  }

  const truncate = (value, len) => {
    if (value === null) {
      return value
    }
    return value.length > len ? `${value.substring(0, len - 3)}...` : value
  }

  const outboundWebhookFilterData = filteredWebhook?.map(webhook => ({
    ...webhook,
    isArchived: webhook.isArchived ? "True" : "False",
    webhookStatusName: Helpers.convertWebhookStatusIdToStatusName(
      webhook.webhookStatusId
    ),
    deliveryTypeName: Helpers.renderFriendlyDeliveryTypeName(
      webhook.deliveryTypeId
    ),
    authentication: webhook.authenticationRequired ? "YES" : "NO"
  }))

  const outboundColumns = useMemo(() => [
    {
      id: "expander",
      //sortable: false, // You can use any accessor
      cell: ({ row }) => (
        <div>
          <IconButton
            onClick={() =>
              onExpandedQueueSentChange(
                expanded,
                row.index,
                row.toggleExpanded()
              )
            }
            style={{ cursor: "pointer" }}
            size="large"
          >
            {row.getIsExpanded() ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
          {row.getValue()}
        </div>
      ),
      width: 25
    },
    {
      header: "ID",
      accessor: "id",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {cell.getValue()}
        </div>
      ),
      width: 50,
      sortable: true
    },
    {
      header: "Actions",
      id: "actions",
      cell: row => (
        <Tooltip title="edit" placement="right">
          <IconButton
            color="primary"
            simple
            onClick={() => {
              handleEditClick(row)
            }}
            size="large"
          >
            {row.getValue()}
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
      sortable: false,
      width: 50
    },
    {
      header: "Name",
      accessor: "description",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {cell.getValue()}
        </div>
      ),
      width: 100,
      sortable: true
    },
    {
      header: "Webhook URL",
      accessor: "externalUrl",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {cell.getValue()}
        </div>
      ),
      width: 100,
      sortable: true,
      filterable: true
    },
    {
      header: "Delivery Type",
      accessor: "deliveryTypeId",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {Helpers.renderFriendlyDeliveryTypeName(cell.getValue())}
        </div>
      ),
      width: 100,
      sortable: true,
      filterable: true
    },
    {
      header: "Delivery Cadence(Min)",
      accessor: "deliveryCadence",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {cell.getValue()}
        </div>
      ),
      width: 65,
      sortable: true,
      filterable: true
    },
    {
      header: "HTTP Method",
      accessor: "externalHTTPMethod",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {cell.getValue()}
        </div>
      ),
      width: 100,
      sortable: true,
      filterable: true
    },
    {
      header: "Auth Required",
      accessor: "authenticationRequired",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {cell.getValue() ? <span>YES</span> : <span>NO</span>}
        </div>
      ),
      width: 100
    },
    {
      header: "Audit Info",
      accessor: "auditInfo",
      cell: ({ cell, row }) => {
        const auditInfo = cell.getValue()
        if (!auditInfo) {
          return "None"
        }
        return (
          <div
            style={
              isArchived && row.original.isArchived
                ? { textDecoration: "line-through" }
                : {}
            }
          >
            created{" "}
            <TimeAgo
              date={auditInfo.createdOn}
              title={Helpers.prettyDateTimeinPacificTimeZone(
                auditInfo.createdOn
              )}
            />{" "}
            by {auditInfo.creator.username} <br />
            modified{" "}
            <TimeAgo
              date={auditInfo.modifiedOn}
              title={Helpers.prettyDateTimeinPacificTimeZone(
                auditInfo.modifiedOn
              )}
            />{" "}
            by {auditInfo.modifier.username}
          </div>
        )
      },
      width: 200,
      sortable: true,
      filterable: true
    },
    {
      header: "Webhook Status",
      accessor: "webhookStatusId",
      cell: ({ cell, row }) => (
        <div
          style={
            isArchived && row.original.isArchived
              ? { textDecoration: "line-through" }
              : {}
          }
        >
          {Helpers.convertWebhookStatusIdToStatusName(cell.getValue())}
        </div>
      ),
      width: 100,
      sortable: true,
      filterable: true
    }
  ])

  const outboundWebhookFilter = [
    {
      filterName: "ID",
      columnId: "id"
    },
    {
      filterName: "Name",
      columnId: "description"
    },
    {
      filterName: "Webhook URL",
      columnId: "externalUrl"
    },
    {
      filterName: "Delivery Type",
      columnId: "deliveryTypeName"
    },
    {
      filterName: "Delivery Cadence",
      columnId: "deliveryCadence"
    },
    {
      filterName: "HTTP Method",
      columnId: "externalHTTPMethod"
    },
    {
      filterName: "Webhook Status",
      columnId: "webhookStatusName"
    },
    {
      filterName: "Authentication Required",
      columnId: "authentication"
    }
  ].map(filter => {
    const obj = {
      ...filter
    }

    if (!filter.options) {
      obj.options = outboundWebhookFilterData
        ? outboundWebhookFilterData
            .map(
              webhook => webhook[filter.columnId] && webhook[filter.columnId]
            )
            .filter(
              (value, index, self) => value && self.indexOf(value) === index
            )
            .map((obj, index) => ({ id: index, value: obj }))
        : []
    }
    return obj
  })

  const columns = useMemo(() => [
    {
      header: "Outbound Webhook Id",
      accessor: "id",
      cell: ({ cell, row }) => <div>{cell.getValue()}</div>,
      size: 75,
      sortable: true
    },
    {
      header: "Sent Date/Time",
      size: 100,
      accessor: "sentDateTime",
      cell: cell => (
        <TimeAgo
          date={cell.getValue()}
          title={Helpers.prettyDateTimeinPacificTimeZone(cell.getValue())}
        />
      ),
      sortable: true
    },
    {
      header: "HTTP Request Body",
      accessor: "httpRequestBody",
      size: 200,
      cell: cell => {
        const cellValue = cell.getValue()
        if (cellValue === "{}") {
          return "None"
        }
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
            title={cell.getValue()}
          >
            {truncate(cell.getValue(), 100)}
          </span>
        )
      },
      sortable: false
    },
    {
      header: "HTTP Response Code",
      accessor: "httpResponseCode",
      cell: ({ cell }) => {
        const iconResponse =
          cell.getValue() === 200 ? (
            <CheckIcon className={classes.ok} />
          ) : (
            <ReportIcon className={classes.stop} />
          )
        return (
          <div>
            {iconResponse}
            {cell.getValue()}
          </div>
        )
      },
      size: 75,
      sortable: true
    },
    {
      header: "HTTP Response Body",
      accessor: "httpResponseBody",
      size: 200,
      cell: cell => {
        const cellValue = cell.getValue()
        if (cellValue === "{}") {
          return "None"
        }
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
            title={cell.getValue()}
          >
            {truncate(cell.getValue(), 100)}
          </span>
        )
      },
      sortable: false
    }
  ])

  return (
    //build outbound table
    <>
      <GridItem xs={12} className={classes.archiveSwitch}>
        <FormControlLabel
          value="start"
          control={
            <Switch
              name="includeArchived"
              color="primary"
              checked={isArchived}
              onChange={() => {
                setIsArchived(prevIsArchived => !prevIsArchived)
              }}
            />
          }
          label="Include Archived"
          labelPlacement="end"
        />
      </GridItem>
      <div>
        <CustomTable
          aria-label="OutboundWebhook Table ViewWebhooks"
          tableName="Outbound Webhooks"
          tableData={filteredWebhook}
          tableColumns={outboundColumns}
          availableFilters={outboundWebhookFilter}
          initialTableState={{ pageIndex: 0, pageSize: 10 }}
          paginated={true}
          sorted
          filtered={false}
          expanded={expanded}
          onExpandedChange={onExpandedQueueSentChange}
          isLoading={loading}
          alternateRowColor={true}
          renderSubComponent={({ row }) => (
            //outbound Queue table
            <div style={{ padding: "20px" }}>
              {queueLoading ? (
                <LinearProgress className={classes.progressQueueTable} />
              ) : (
                <CustomTable
                  tableName="OutboundQueueSent"
                  minRows={5}
                  className="-striped -highlight"
                  tableColumns={columns}
                  tableData={outboundWebhookQueueSent}
                  paginated={true}
                  sorted={true}
                  showPaginationBottom={true}
                  loading={queueLoading}
                  alternateRowColor={true}
                  noDataText={"No outbound webhook queue data found."}
                  defaultSorted={[
                    {
                      id: "id",
                      desc: true
                    }
                  ]}
                />
              )}
            </div>
          )}
        />
      </div>
    </>
  )
}
