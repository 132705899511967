import React, { useState, useMemo } from "react"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"
import makeStyles from "@mui/styles/makeStyles"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import FormLabel from "@mui/material/FormLabel"
import FormControlLabel from "@mui/material/FormControlLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Switch from "@mui/material/Switch"
import FormHelperText from "@mui/material/FormHelperText"
import IconButton from "@mui/material/IconButton"
import RadioGroup from "@mui/material/RadioGroup"
import Radio from "@mui/material/Radio"

import WebhookDAO from "daos/webhookDAO"
import Enums from "tools/Enums"
import Helpers from "tools/Helpers"
import CustomDSDialog from "components/CustomDialogs/CustomDSDialog"
import Autocomplete from "@mui/material/Autocomplete"
// MUI Icons
import Info from "@mui/icons-material/InfoOutlined"
import InfoIcon from "@mui/icons-material/Info"

const useStyles = makeStyles(() => ({
  archiveSwitch: {
    display: "flex",
    paddingBottom: "20px"
  },
  orgGrid: {
    display: "flex",
    paddingTop: "9px"
  },
  managerForm: {
    paddingBottom: "16px"
  },
  authLabelInfo: {
    display: "flex",
    alignItems: "center"
  },
  archiveSwitchEdit: {
    display: "flex",
    justifyContent: "end"
  }
}))

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization,
    organizationId: store.organizationStore.organizationId,
    organizations: store.organizationStore.organizations,
    getOrganizations: store.organizationStore.getOrganizations,
    propertyId: store.organizationStore.propertyId,
    account: store.accountStore.account,
    getOutboundWebhookByOrg: store.webhookStore.getOutboundWebhookByOrg,
    webhookId: store.webhookStore.webhookId,
    postWebhook: store.webhookStore.postWebhook
  }))
}

export default function OutboundWebhookFormDialog(props) {
  const { organizationId, account, organizations } = useStoreData()
  const {
    isCreateOutboundWebhookDialogOpen,
    closeDialogForm,
    editForm,
    triggerRerenderOutboundTable,
    setIsCreateOutboundWebhookDialogOpen,
    formValues,
    setFormValues,
    setSuccessDialogOpen,
    setFailDialogOpen
  } = props

  const classes = useStyles()
  const [infoDialogOpen, setInfoDialogOpen] = useState(false)
  const [isArchived, setIsArchived] = useState(formValues.isArchived || false)
  const [formErrors, setFormErrors] = useState({})
  const [isSaving, setIsSaving] = useState(false)

  const sortedOrganizations = useMemo(
    () => organizations && organizations?.toJS().sort(Helpers.sortByName),
    [organizations]
  )

  const selectedOrganization = sortedOrganizations.find(
    org => org.id === organizationId
  )

  const filteredProperties = useMemo(() => {
    if (organizationId) {
      const so = sortedOrganizations.filter(
        org => org.parentId === organizationId && org.isActive === true
      )
      return so
    } else {
      return []
    }
  }, [organizationId, sortedOrganizations])

  const hideCreateOutboundWebhookDialog = () => {
    setIsCreateOutboundWebhookDialogOpen(false)
  }

  const resetWebhookFormValues = () => {
    setFormValues({
      organizationId,
      filteredProperties: "",
      webhookStatusId: "",
      description: "",
      deliveryTypeId: "",
      deliveryCadence: "",
      externalUrl: "",
      externalHTTPMethod: "",
      authenticationRequired: false
    })
  }

  const handleValueOnChange = e => {
    const { name, value } = e.target
    // validation checks and update newFormErrors object
    const newFormValues = {
      ...formValues,
      [name]: value
    }
    const newFormErrors = { ...formErrors, [name]: !value }
    let validationError = false

    if (!newFormValues.webhookStatusId) {
      formErrors.webhookStatusId = true
      validationError = true
    }
    if (!newFormValues.description) {
      formErrors.description = true
      validationError = true
    }
    if (!newFormValues.externalUrl) {
      formErrors.externalUrl = true
      validationError = true
    }
    if (!newFormValues.deliveryTypeId) {
      formErrors.deliveryTypeId = true
      validationError = true
    }
    if (!newFormValues.deliveryCadence) {
      formErrors.deliveryCadence = true
      validationError = true
    }
    if (!newFormValues.externalHTTPMethod) {
      formErrors.externalHTTPMethod = true
      validationError = true
    }
    if (!newFormValues.authenticationRequired) {
      formErrors.authenticationRequired = true
      validationError = true
    }

    setFormValues(newFormValues)
    setFormErrors(newFormErrors)
    return !validationError
  }

  const onOutboundWebhookSaveButtonClick = async () => {
    const newFormErrors = {
      ...formErrors,
      externalUrl:
        !formValues.externalUrl ||
        formValues.externalUrl.indexOf("https") === -1,
      externalHTTPMethod: !formValues.externalHTTPMethod,
      webhookStatusId: !formValues.webhookStatusId,
      description: !formValues.description,
      deliveryTypeId: !formValues.deliveryTypeId,
      deliveryCadence: !formValues.deliveryCadence
    }
    const hasError = Object.values(newFormErrors).some(error => error)
    if (hasError) {
      setFormErrors(newFormErrors)
      return false
    }

    let result
    if (editForm) {
      result = await updateWebhook()
    } else {
      result = await createWebhook()
    }
    if (result) {
      resetWebhookFormValues()
    }

    if (result) {
      setTimeout(() => {
        setSuccessDialogOpen(true)
        triggerRerenderOutboundTable()
      }, 500)
    } else {
      setFailDialogOpen(true)
    }
    hideCreateOutboundWebhookDialog()
  }

  const updateWebhook = async () => {
    setIsSaving(true)
    const orgOrPropertyId = formValues.filteredProperties?.id
      ? formValues.filteredProperties.id
      : selectedOrganization.id
    try {
      let webhookUpdateRequest = {
        organizationId: orgOrPropertyId,
        id: formValues.id,
        externalUrl: formValues.externalUrl,
        externalHTTPMethod: formValues.externalHTTPMethod,
        webhookStatusId: formValues.webhookStatusId,
        description: formValues.description,
        deliveryTypeId: formValues.deliveryTypeId,
        deliveryCadence: formValues.deliveryCadence,
        filteredProperties: formValues.filteredProperties.id,
        authenticationRequired: formValues.authenticationRequired,
        createdByAccountId: account.id,
        modifiedByAccountId: account.id,
        isArchived: formValues.isArchived,
        notificationActivity: 35,
        isOutbound: true,
        guidToken: "testtoken"
      }
      const data = await WebhookDAO.putWebhook(webhookUpdateRequest)
      if (data.content !== null && !formErrors.validationHasError) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.error("Error on PUT to webhook", err)
      return false // Indicate failure
    } finally {
      setIsSaving(false)
    }
  }

  const createWebhook = async () => {
    setIsSaving(true)
    const orgOrPropertyId = formValues.filteredProperties?.id
      ? formValues.filteredProperties.id
      : selectedOrganization.id
    try {
      let webhookCreateRequest = {
        organizationId: orgOrPropertyId,
        createdByAccountId: account.id,
        filteredProperties: formValues.filteredProperties.id,
        webhookStatusId: formValues.webhookStatusId,
        description: formValues.description,
        deliveryTypeId: formValues.deliveryTypeId,
        deliveryCadence: formValues.deliveryCadence,
        externalUrl: formValues.externalUrl,
        externalHTTPMethod: formValues.externalHTTPMethod,
        authenticationRequired: formValues.authenticationRequired,
        id: 0,
        notificationActivity: 35,
        isOutbound: true,
        guidToken: "testtoken",
        isActive: true,
        modifiedByAccountId: account.id,
        isArchived: false
      }
      const data = await WebhookDAO.postWebhook(webhookCreateRequest)
      if (data.content !== null) {
        const newWebhook = {
          ...webhookCreateRequest,
          id: data.content
        }
        return newWebhook
      } else {
        return null
      }
    } catch (err) {
      console.error("Error on POST to webhook", err)
      return null
    } finally {
      setIsSaving(false)
    }
  }

  const handleOpenInfoDialog = () => {
    setInfoDialogOpen(true)
  }

  const handleCloseInfoDialog = () => {
    setInfoDialogOpen(false)
  }

  const handlePropertyOnChange = (event, newValue) => {
    const newFormValues = {
      ...formValues,
      filteredProperties: newValue
    }
    setFormValues(newFormValues)
  }

  const handleCancel = () => {
    setFormValues({
      organization: "",
      selectedOrganization: "",
      filteredProperties: "",
      webhookStatus: "",
      description: "",
      deliveryTypeId: "",
      deliveryCadence: "",
      externalUrl: "",
      externalHTTPMethod: "",
      outboundWebhookEventActivity: "",
      authenticationRequired: false,
      isArchived: false
    })
    setFormErrors({})
    closeDialogForm()
  }

  return (
    <Dialog
      onClose={closeDialogForm}
      aria-labelledby="customized-dialog-title"
      open={isCreateOutboundWebhookDialogOpen}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title">
        {editForm ? "Edit Outbound Webhook" : "Create Outbound Webhook"}
        {editForm && (
          <GridItem className={classes.archiveSwitchEdit}>
            <FormControlLabel
              name="isArchived"
              value={formValues.isArchived}
              control={
                <Switch
                  name="isArchived"
                  color="primary"
                  checked={isArchived}
                  onChange={e => {
                    setIsArchived(e.target.checked)
                    setFormValues({
                      ...formValues,
                      isArchived: e.target.checked
                    })
                  }}
                />
              }
              label="Archive"
            />
          </GridItem>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.orgGrid}>
          <GridItem xs={6} className={classes.managerForm}>
            <FormControl fullWidth>
              <TextField
                name="organizationId"
                id="organizationId"
                label="ORGANIZATION"
                variant="outlined"
                value={selectedOrganization?.name}
                inputProps={{ readOnly: true }}
              />
            </FormControl>
          </GridItem>
          {filteredProperties.length > 0 && (
            <GridItem xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  name="filteredProperties"
                  value={filteredProperties.find(
                    obj => obj.id === formValues.organization?.id
                  )}
                  onChange={handlePropertyOnChange}
                  options={
                    filteredProperties?.length > 0 ? filteredProperties : []
                  }
                  getOptionLabel={option => option?.name || ""}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="PROPERTY"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </GridItem>
          )}
        </Grid>
        <GridItem xs={8} className={classes.managerForm}>
          <FormControl
            fullWidth
            required
            error={formErrors.webhookStatusId}
            variant="outlined"
          >
            <InputLabel>WEBHOOK STATUS</InputLabel>
            <Select
              fullWidth={true}
              id="webhookStatusId"
              name="webhookStatusId"
              type="select"
              value={formValues.webhookStatusId}
              onChange={e => handleValueOnChange(e)}
              label="WEBHOOK STATUS"
            >
              <MenuItem disabled>Choose Webhook Status</MenuItem>
              <MenuItem key="Active" value={Enums.WebhookStatus.ACTIVE}>
                ACTIVE
              </MenuItem>
              <MenuItem key="Inactive" value={Enums.WebhookStatus.INACTIVE}>
                INACTIVE
              </MenuItem>
              <MenuItem key="Validate" value={Enums.WebhookStatus.VALIDATE}>
                VALIDATE
              </MenuItem>
              <MenuItem
                key="ValidateInternal"
                value={Enums.WebhookStatus.VALIDATEINTERNAL}
              >
                VALIDATE INTERNAL
              </MenuItem>
              <MenuItem
                key="PriorityEvent"
                value={Enums.WebhookStatus.PRIORITYEVENT}
              >
                Priority Event
              </MenuItem>
              <MenuItem key="Trivia" value={Enums.WebhookStatus.TRIVIA}>
                Trivia
              </MenuItem>
            </Select>
            {formErrors.webhookStatusId && (
              <FormHelperText>Webhook Status Required</FormHelperText>
            )}
          </FormControl>
        </GridItem>
        <GridItem xs={8} className={classes.managerForm}>
          <FormControl fullWidth>
            {editForm && (
              <TextField
                name="webhookId"
                id="id"
                value={formValues.id}
                label="WEBHOOK ID"
                variant="outlined"
              />
            )}
            {!editForm && (
              <TextField
                label="NEW WEBHOOK"
                id="wehookId"
                variant="outlined"
                fullWidth
                disabled={true}
              />
            )}
          </FormControl>
        </GridItem>
        <GridItem xs={8} className={classes.managerForm}>
          <FormControl fullWidth>
            <TextField
              id="description"
              name="description"
              type="text"
              fullWidth
              variant="outlined"
              label="NAME"
              value={formValues.description}
              required
              onChange={handleValueOnChange}
              error={formErrors.description}
              helperText={formErrors.description ? "Name Required" : ""}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={8} className={classes.managerForm}>
          <FormControl fullWidth>
            <TextField
              id="externalUrl"
              name="externalUrl"
              type="text"
              fullWidth
              variant="outlined"
              value={formValues.externalUrl}
              label="CLIENT WEBHOOK URL"
              required
              error={formErrors.externalUrl}
              helperText={
                formErrors.externalUrl
                  ? "Valid URL Format Required https://ex.com"
                  : ""
              }
              onChange={handleValueOnChange}
            />
          </FormControl>
        </GridItem>
        <Grid>
          <GridItem xs={8} className={classes.managerForm}>
            <FormControl
              fullWidth
              required
              error={formErrors.deliveryTypeId}
              variant="outlined"
            >
              <InputLabel>DELIVERY TYPE</InputLabel>
              <Select
                fullWidth={true}
                id="deliveryTypeId"
                name="deliveryTypeId"
                type="select"
                value={formValues.deliveryTypeId || ""}
                onChange={e => handleValueOnChange(e)}
                label="DELIVERY TYPE"
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose Delivery Type
                </MenuItem>
                <MenuItem key="Batch" value={Enums.DeliveryType.BATCH}>
                  BATCH
                </MenuItem>
                <MenuItem
                  key="Individual"
                  value={Enums.DeliveryType.INDIVIDUAL}
                >
                  INDIVIDUAL
                </MenuItem>
              </Select>
              {formErrors.deliveryTypeId && (
                <FormHelperText>Delivery Type Required</FormHelperText>
              )}
            </FormControl>
          </GridItem>
        </Grid>
        <Grid>
          <GridItem xs={8} className={classes.managerForm}>
            <FormControl fullWidth>
              <TextField
                id="deliveryCadence"
                name="deliveryCadence"
                value={formValues.deliveryCadence}
                fullWidth
                variant="outlined"
                type="number"
                label="DELIVERY CADENCE in MINUTES"
                required
                onChange={handleValueOnChange}
                error={formErrors.deliveryCadence}
                helperText={
                  formErrors.deliveryCadence ? "Delivery Cadence Required" : ""
                }
              />
            </FormControl>
          </GridItem>
        </Grid>

        <GridContainer>
          <GridItem md={8} xs={8} className={classes.managerForm}>
            <FormControl
              fullWidth
              required
              error={formErrors.externalHTTPMethod}
              variant="outlined"
            >
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                HTTP METHOD
              </InputLabel>
              <Select
                id="externalHTTPMethod"
                name="externalHTTPMethod"
                label="HTTP METHOD"
                type="select"
                value={formValues.externalHTTPMethod}
                onChange={e => handleValueOnChange(e)}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose HTTP method
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="PUT"
                >
                  PUT
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="POST"
                >
                  POST
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="GET"
                >
                  GET
                </MenuItem>
              </Select>
              {formErrors.externalHTTPMethod && (
                <FormHelperText>HTTP Method Required</FormHelperText>
              )}
            </FormControl>
          </GridItem>
        </GridContainer>
        <Grid>
          <GridItem xs={12}>
            <FormControl>
              <FormLabel className={classes.authLabelInfo}>
                Authentication Required
                <IconButton onClick={handleOpenInfoDialog} size="large">
                  <InfoIcon color="primary" />
                </IconButton>
                <CustomDSDialog
                  open={infoDialogOpen}
                  content={"Contact Engineering to set up Authentication"}
                  icon={<Info style={{ fontSize: "4rem" }} />}
                  onClose={handleCloseInfoDialog}
                />
              </FormLabel>
              <RadioGroup
                name="authenticationRequired"
                value={formValues.authenticationRequired.toString()}
                required
                onChange={handleValueOnChange}
                row={true}
              >
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="NO"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="YES"
                  labelPlacement="top"
                />
              </RadioGroup>
            </FormControl>
          </GridItem>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={onOutboundWebhookSaveButtonClick}
          color="primary"
          disabled={isSaving}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
