import React, { Component } from "react"
import ReactTable from "react-table-6"
import { Link } from "react-router-dom"
import matchSorter from "match-sorter"
import TimeAgo from "react-timeago"
import { observer, inject } from "mobx-react"
import { Add, Assignment, Schedule } from "@mui/icons-material"
import withStyles from "@mui/styles/withStyles"
import GridContainer from "components/Grid/GridContainer"
//import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import Helpers from "tools/Helpers.js"
import Dialog from "components/Dialog"
import NarrativeBuilder from "./NarrativeBuilder"
import Button from "components/CustomButtons/Button"
import Edit from "@mui/icons-material/Edit"
import Loop from "@mui/icons-material/Loop"
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"
import { Cookies } from "tools/storage"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import Tooltip from "@mui/material/Tooltip"
import CircularProgress from "@mui/material/CircularProgress"
import Modal from "@mui/material/Modal"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import NarrativeRenderScheduler from "components/NarrativeAdmin/NarrativeRenderScheduler"
import Enums from "tools/Enums.js"
import {
  defaultNarrativeStarter,
  initialHardcodedNarrativeData
} from "constants"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  alertMessageModal: {
    width: "40vw",
    height: "200px",
    margin: "34vh auto"
  },
  archiveQuestion: {
    textAlign: "right",
    paddingRight: "50px",
    "& > label": {
      marginBottom: "0"
    }
  },
  narrativeBuilderModal: {
    width: "90vw",
    height: "90vh",
    margin: "5vh auto",
    overflowY: "auto",
    padding: "0 10px",
    border: "1px solid red"
  }
}

const useStore = component =>
  inject(({ store }) => ({
    loading: store.organizationStore.loading,
    setNarrative: store.narrativeStore.setNarrative,
    narratives: store.organizationStore.narratives,
    libraryNarratives: store.narrativeStore.libraryNarratives,
    getNarratives: store.organizationStore.getNarratives,
    getLibraryNarratives: store.narrativeStore.getLibraryNarratives,
    isNarrativeBuilderOpen: store.narrativeStore.isNarrativeBuilderOpen,
    openNarrativeBuilderModal: store.narrativeStore.openNarrativeBuilderModal,
    closeNarrativeBuilderModal: store.narrativeStore.closeNarrativeBuilderModal,
    organizationId: store.organizationStore.organizationId,
    organization: store.organizationStore.organization,
    setShowChangeCurrentOrganization:
      store.uiStore.setShowChangeCurrentOrganization
  }))(observer(component))

class Narratives extends Component {
  constructor(props) {
    super(props)
    this.props = props
    let cookies = new Cookies()

    const pageSize = props.embedded
      ? 25
      : cookies.get("narrativesPageSize")
      ? Number(cookies.get("narrativesPageSize"))
      : 25
    this.state = {
      organizationId: this.props.organizationId,
      isLibrariesOnly: this.props.isLibrariesOnly ?? false,
      myCell: null,
      contentId: "",
      contentType: "",
      narrative: null,
      modelName: "",
      feeds: [],
      isRenderSchedulerOpen: false,
      doDisplayArchivedJobs: false,
      wasLibrary: false,
      pageSize
    }
  }

  componentDidMount() {
    if (this.state.isLibrariesOnly) {
      this.props.getLibraryNarratives()
      this.setState({
        lastOrgLoaded: Enums.Organizations.Libraries
      })
    } else if (this.props.organizationId !== Enums.Organizations.Libraries) {
      this.props.getNarratives(this.props.organizationId)
      this.setState({
        lastOrgLoaded: this.props.organizationId
      })
    } else {
      const correctOrgId = Helpers.determineTheCorrectOrganization(
        false,
        this.state.organizationId,
        Enums.Organizations.Libraries
      )
      if (correctOrgId) {
        this.props.getNarratives(correctOrgId)
        this.setState({
          organizationId: correctOrgId,
          lastOrgLoaded: correctOrgId
        })
      }
    }
    Helpers.didWeNavigateToLibraryEditor({
      isLibrary: !!this.props.isLibrariesOnly
    })
  }

  componentDidUpdate() {
    if (
      this.state.isLibrariesOnly &&
      this.state.lastOrgLoaded !== Enums.Organizations.Libraries
    ) {
      this.setState({
        lastOrgLoaded: Enums.Organizations.Libraries
      })
      this.props.getLibraryNarratives()
    } else if (
      this.props.organizationId !== Enums.Organizations.Libraries &&
      this.props.organizationId !== this.state.lastOrgLoaded
    ) {
      let cookies = new Cookies()
      const current = Number(cookies.get("currentorg"))
        ? Number(cookies.get("currentorg"))
        : this.state.isLibrariesOnly
        ? Enums.Organizations.Libraries
        : Enums.Organizations.DataSkrive1
      if (Number(this.state.organizationId) !== current) {
        this.props.getNarratives(current)
        cookies.set("currentorg", current)
        cookies.set("lastorg", current)
        this.setState({
          organizationId: current,
          lastOrgLoaded: current
        })
      }
    } else if (
      !this.state.isLibrariesOnly &&
      this.state.lastOrgLoaded !== Enums.Organizations.Libraries
    ) {
      const correctOrgId = Helpers.determineTheCorrectOrganization(
        false,
        this.state.organizationId,
        Enums.Organizations.Libraries
      )
      if (correctOrgId && correctOrgId !== this.state.lastOrgLoaded) {
        this.props.getNarratives(correctOrgId)
        this.setState({
          organizationId: correctOrgId,
          lastOrgLoaded: correctOrgId
        })
      }
    }
    Helpers.didWeNavigateToLibraryEditor(
      this.props.isLibrariesOnly ? { isLibrary: true } : { isLibrary: false }
    )
  }

  getContentId(orig) {
    const cId =
      orig.queryId ||
      orig.productionFeed_Id ||
      orig.ModelTypeId ||
      orig.uatFeed_Id
    return cId
  }

  getContentType(orig) {
    const cType = orig.modelType
    switch (cType) {
      case "QueryBuilder":
        return "query"
      case "TrainingModel":
        return "Training"
      case "LeagueMatchups":
        return ""
      default:
        return ""
    }
  }

  doArchiveCheck(nrr) {
    let tempNarrative = nrr.toJS()
    let tmp = []
    if (this.state.doDisplayArchivedJobs) {
      return tempNarrative
    } else {
      tempNarrative.forEach(itm => {
        if (!itm.isArchived) {
          tmp.push(itm)
        }
      })
    }
    return tmp
  }

  filterAndDoArchiveCheck(nrr, currProp) {
    let tempNarrative = nrr.toJS()
    let tmp = []
    if (this.state.doDisplayArchivedJobs) {
      tempNarrative.forEach(itm => {
        if (itm.organization && itm.organization.id === currProp) {
          tmp.push(itm)
        }
      })
    } else {
      tempNarrative.forEach(itm => {
        if (!itm.isArchived) {
          if (itm.organization && itm.organization.id === currProp) {
            tmp.push(itm)
          }
        }
      })
    }
    return tmp
  }

  rememberChanges = pageSize => {
    this.setState({ pageSize })
    let cookies = new Cookies()
    cookies.set("narrativesPageSize", pageSize, {
      path: "/",
      expires: Helpers.CookieExpiration.OneYear
    })
  }

  render() {
    if (!this.props.organization || !this.props.organization.name) {
      return "Assembling..."
    }
    const {
      classes,
      loading,
      isNarrativeBuilderOpen,
      openNarrativeBuilderModal,
      closeNarrativeBuilderModal,
      setNarrative
    } = this.props
    const narratives = this.state.isLibrariesOnly
      ? this.props.libraryNarratives
      : this.props.narratives
    const { pageSize, organizationId } = this.state
    const hideShareDialog = null
    const contentType = "query"
    const stringFilterProps = {
      filterable: true,
      filterAll: true,
      filterMethod: ({ id, value }, rows) =>
        matchSorter(rows, value, {
          keys: [id],
          threshold: matchSorter.rankings.CONTAINS
        })
    }
    const createNewNarrative = () => {
      const isLibrary = this.props.isLibrariesOnly ?? false
      const newNarrative = {
        ...initialHardcodedNarrativeData,
        organization: {
          id: isLibrary ? Enums.Organizations.Libraries : organizationId
        },
        organizationId: isLibrary
          ? Enums.Organizations.Libraries
          : organizationId,
        isLibrary: isLibrary,
        buildFromOutline: !isLibrary,
        narrativeContentTypeId: isLibrary
          ? Enums.NarrativeContentType.Library
          : 0
      }
      if (isLibrary) {
        newNarrative.starter = defaultNarrativeStarter
      }
      setNarrative(newNarrative)
      openNarrativeBuilderModal()
    }
    let cookies = new Cookies()
    let currentProperty = isNaN(cookies.get("currentProperty"))
      ? ""
      : cookies.get("currentProperty")

    return (
      <div>
        <Modal
          open={!!this.state.isAlertMessageOpen}
          onClose={() => {
            this.setState({
              isAlertMessageOpen: false,
              isRenderSchedulerOpen: false
            })
          }}
        >
          <Card className={classes.alertMessageModal}>
            <CardHeader>
              <h3>Alert</h3>
            </CardHeader>
            <CardBody>
              <h5>{this.state.alertMessage}</h5>
            </CardBody>
          </Card>
        </Modal>
        {this.state.isRenderSchedulerOpen && (
          <NarrativeRenderScheduler
            open={this.state.isRenderSchedulerOpen}
            isMyRenderSchedulerOpen={this.state.isRenderSchedulerOpen}
            setAlertMessageOpen={itm => {
              this.setState({
                isAlertMessageOpen: itm.isOpen,
                alertMessage: itm.msg
              })
            }}
            contentId={this.state.contentId}
            contentType={contentType}
            feeds={this.state.feeds}
            narrative={this.state.narrative}
            modelName={this.state.modelName}
            hideShareDialog={hideShareDialog}
            narrativeLoaded={true}
            setRenderSchedulerOpen={val => {
              this.setState({
                isRenderSchedulerOpen: val
              })
            }}
          />
        )}

        <GridContainer>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>
            {!(
              !this.state.isLibrariesOnly &&
              this.state.organizationId === Enums.Organizations.Libraries
            ) && (
              <CardBody>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => createNewNarrative()}
                  startIcon={<Add />}
                >
                  {this.state.isLibrariesOnly ? "Add Library" : "Add Output"}
                </Button>

                <ReactTable
                  loading={loading}
                  data={
                    this.state.isLibrariesOnly || !currentProperty
                      ? this.doArchiveCheck(narratives)
                      : this.filterAndDoArchiveCheck(
                          narratives,
                          currentProperty
                        )
                  }
                  noDataText={
                    this.state.loading ? (
                      <CircularProgress />
                    ) : (
                      "No output(s) found."
                    )
                  }
                  minRows={0}
                  pageSize={loading ? 5 : pageSize}
                  onPageSizeChange={(pageSize, page) => {
                    this.rememberChanges(pageSize, page)
                  }}
                  filterable={true}
                  columns={[
                    {
                      Header: "Id",
                      accessor: "id",
                      Cell: cell => (
                        <Link
                          to={
                            this.state.isLibrariesOnly
                              ? `/portal/narrative/${cell.value}/edit`
                              : `/portal/narrative/${cell.value}`
                          }
                        >
                          {cell.value}
                        </Link>
                      ),
                      width: 50,
                      ...stringFilterProps
                    },
                    {
                      Header: "Actions",
                      accessor: "id",
                      Cell: cell => (
                        <div style={{ display: "flex" }}>
                          <Link to={`/portal/narrative/${cell.value}/edit`}>
                            <Tooltip title="Edit" placement="top">
                              <Button color="primary" justIcon round simple>
                                <Edit />
                              </Button>
                            </Tooltip>
                          </Link>
                          <Tooltip title="Show Render" placement="top" arrow>
                            <Button
                              color="primary"
                              justIcon
                              round
                              simple
                              onClick={() => {
                                this.setState({
                                  isRenderSchedulerOpen: true,
                                  myCell: cell,
                                  contentId: this.getContentId(cell.original),
                                  contentType: this.getContentType(
                                    cell.original
                                  ),
                                  narrative: cell.original,
                                  modelName:
                                    cell.original.name &&
                                    cell.original.name.includes("<")
                                      ? cell.original.name.split("<")[0]
                                      : cell.original.name
                                })
                              }}
                            >
                              <Schedule />
                            </Button>
                          </Tooltip>
                          <Link
                            onClick={() => {
                              let cookies = new Cookies()
                              cookies.set(
                                "goingFromNarrativeManagerToRenderSchedules",
                                1
                              )
                            }}
                            to={`/portal/narrative/${cell.value}/schedule`}
                          >
                            <Tooltip
                              title="View Existing Schedule"
                              placement="top"
                              arrow
                            >
                              <Button color="primary" justIcon round simple>
                                <CalendarTodayIcon />
                              </Button>
                            </Tooltip>
                          </Link>
                          <Link
                            to={{
                              pathname: `/portal/narrative/${cell.value}/output`
                            }}
                            title="View Deliverables"
                          >
                            <Tooltip title="View Outputs" placement="top" arrow>
                              <Button color="primary" justIcon round simple>
                                <AssignmentTurnedInIcon />
                              </Button>
                            </Tooltip>
                          </Link>
                        </div>
                      ),
                      sortable: false,
                      filterable: false,
                      width: 160
                    },
                    {
                      Header: "Name",
                      accessor: "name",
                      ...stringFilterProps,
                      width: 400
                    },
                    {
                      Header: "Model Type",
                      accessor: "modelType",
                      ...stringFilterProps,
                      width: 125
                    },
                    {
                      Header: "Status",
                      accessor: "narrativeStatusId",
                      Cell: cell => (
                        <div>
                          {Helpers.convertNarrativeStatusIdToStatusName(
                            cell.original.narrativeStatusId
                          )}
                        </div>
                      ),
                      width: 125,
                      sortMethod: (a, b) =>
                        Helpers.convertNarrativeStatusIdToStatusName(a) >
                        Helpers.convertNarrativeStatusIdToStatusName(b)
                          ? 1
                          : -1,
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true
                        }
                        // NOTE: string versus integer comparison,
                        // don't use === operator
                        // eslint-disable-next-line eqeqeq
                        return row.narrativeStatusId == filter.value
                      },
                      Filter: ({ filter, onChange }) => {
                        if (narratives.size === 0) {
                          return null
                        }

                        const distinctNarrativeStatuses = []
                        const map = new Map()
                        for (let narrative of narratives.toJS()) {
                          if (!map.has(narrative.narrativeStatusId)) {
                            map.set(narrative.narrativeStatusId, true)
                            distinctNarrativeStatuses.push({
                              id: narrative.narrativeStatusId,
                              name: Helpers.convertNarrativeStatusIdToStatusName(
                                narrative.narrativeStatusId
                              )
                            })
                          }
                        }
                        // inline sort by status name
                        distinctNarrativeStatuses.sort((a, b) => {
                          // Use toUpperCase() to ignore character casing
                          const orgA = a.name.toUpperCase()
                          const orgB = b.name.toUpperCase()
                          let comparison = 0
                          if (orgA > orgB) {
                            comparison = 1
                          } else if (orgA < orgB) {
                            comparison = -1
                          }
                          return comparison
                        })
                        // build up the <select> element <option> element JSX style
                        let options = []
                        distinctNarrativeStatuses.forEach(status => {
                          if (status.id !== null) {
                            options.push(
                              <option value={status.id} key={status.id}>
                                {status.name}
                              </option>
                            )
                          }
                        })
                        return (
                          <select
                            onChange={event => {
                              onChange(event.target.value)
                            }}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                          >
                            <option value="all">Show All</option>
                            {options}
                          </select>
                        )
                      }
                    },
                    {
                      Header: "Audit Info",
                      accessor: "modifiedDateTime",
                      Cell: row => (
                        <div>
                          created{" "}
                          <TimeAgo
                            date={row.original.auditInfo.createdOn}
                            title={Helpers.prettyDateTimeinPacificTimeZone(
                              row.original.auditInfo.createdOn
                            )}
                          />{" "}
                          by {row.original.auditInfo.creator.username} <br />
                          modified{" "}
                          <TimeAgo
                            date={row.original.auditInfo.modifiedOn}
                            title={Helpers.prettyDateTimeinPacificTimeZone(
                              row.original.auditInfo.modifiedOn
                            )}
                          />{" "}
                          by {row.original.auditInfo.modifier.username}
                          {row.original.auditInfo.owner &&
                            row.original.auditInfo.owner.username !==
                              "system" && (
                              <div>
                                assigned{" "}
                                <TimeAgo
                                  date={row.original.auditInfo.assignedOn}
                                  title={Helpers.prettyDateTimeinPacificTimeZone(
                                    row.original.auditInfo.assignedOn
                                  )}
                                />{" "}
                                to {row.original.auditInfo.owner.username}
                              </div>
                            )}
                        </div>
                      ),
                      width: 250,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={25}
                  showPaginationTop={true}
                  showPaginationBottom={true}
                  className="-striped -highlight -scrollEntries"
                  defaultSorted={[
                    {
                      id: "narrativeStatusId",
                      desc: false
                    },
                    {
                      id: "name",
                      desc: false
                    }
                  ]}
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo) {
                      let returnObject = { style: {} }
                      returnObject.style =
                        Helpers.buildCustomStyleForNarrativeStatus(
                          rowInfo.original.narrativeStatusId
                        )
                      if (rowInfo.original.isArchived) {
                        if (this.state.doDisplayArchivedJobs) {
                          returnObject.style.textDecoration = "line-through"
                        } else {
                          return {}
                        }
                      }
                      return returnObject
                    } else {
                      return {}
                    }
                  }}
                >
                  {(state, makeTable) => {
                    let recordsInfoText = ""

                    const { filtered, pageRows, pageSize, sortedData, page } =
                      state

                    if (sortedData && sortedData.length > 0) {
                      let isFiltered = filtered.length > 0

                      let totalRecords = sortedData.length

                      let recordsCountFrom = page * pageSize + 1

                      let recordsCountTo =
                        recordsCountFrom + pageRows.length - 1

                      if (isFiltered) {
                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered`
                      } else {
                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} `
                      }
                    } else {
                      recordsInfoText = loading
                        ? "Loading, please wait"
                        : "No files"
                    }
                    return (
                      <div className="main-grid">
                        <div className="above-table text-right">
                          <div className="col-sm-12">
                            <span className={classes.archiveQuestion}>
                              <FormControlLabel
                                labelPlacement="start"
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.doDisplayArchivedJobs || false
                                    }
                                    onChange={event => {
                                      this.setState({
                                        doDisplayArchivedJobs:
                                          event.target.checked
                                      })
                                    }}
                                  />
                                }
                                label="Include Archived?"
                              />
                            </span>
                            <span className="records-info">
                              {recordsInfoText}
                              <Tooltip title="Reload">
                                <Button
                                  justIcon
                                  round
                                  simple
                                  color="primary"
                                  className="info"
                                  id="reload"
                                  onClick={() => {
                                    this.state.isLibrariesOnly
                                      ? this.props.getLibraryNarratives()
                                      : this.props.getNarratives(
                                          this.props.organizationId
                                        )
                                  }}
                                >
                                  {loading ? (
                                    <CircularProgress thickness={1} size={30} />
                                  ) : (
                                    <Loop />
                                  )}
                                </Button>
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                        {makeTable()}
                      </div>
                    )
                  }}
                </ReactTable>
                <div xs={3} sm={3} md={3}>
                  <div style={{ backgroundColor: "#ffd900" }}>
                    yellow = `Validate` status
                  </div>
                  <div style={{ backgroundColor: "#ccc" }}>
                    gray = `Inactive` status
                  </div>
                  {this.state.doDisplayArchivedJobs && (
                    <div style={{ textDecoration: "line-through" }}>
                      line-through = 'Archived'
                    </div>
                  )}
                </div>
              </CardBody>
            )}
          </Card>
          <Dialog
            isOpen={isNarrativeBuilderOpen}
            loading={loading}
            onClose={closeNarrativeBuilderModal}
            title=""
            isOnlyClosebleByClickingX={true}
            fullWidth={true}
            className={classes.narrativeBuilderModal}
            style={{ border: "1px solid blue" }}
          >
            <NarrativeBuilder isLibrary={this.state.isLibrariesOnly} />
          </Dialog>
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(styles)(useStore(Narratives))
