/*
  id: Expected by the API in some places
  renderedProperty: The property in the JS object renderedNarrative, which comes from /api/narrativepreview/feed-entry endpoint
  name: The display name
*/
export const mostUtilizedContentSections = [
  { id: "expiredon", renderedProperty: "expiredOn", name: "Expired On" },
  { id: "deliveryid", renderedProperty: "deliveryId", name: "Delivery Id" },
  { id: "entryid", renderedProperty: "entryId", name: "EntryId" },
  // {
  //   id: "dscategories",
  //   renderedProperty: "dsCategories",
  //   name: "DS Categories (JSON)"
  // },
  {
    id: "categories",
    renderedProperty: "categories",
    name: "Categories"
  },
  { id: "seotitle", renderedProperty: "seoTitle", name: "SEOTitle" },
  {
    id: "metadescription",
    renderedProperty: "metaDescription",
    name: "Meta Description"
  },
  { id: "seoslug", renderedProperty: "seoSlug", name: "SEOSlug" },
  { id: "title", renderedProperty: "title", name: "Title" },
  { id: "content", renderedProperty: "content", name: "Content" }
]

export const contentSections = [
  ...mostUtilizedContentSections,
  {
    id: "introparagraph",
    renderedProperty: "introParagraph",
    name: "Intro Paragraph"
  },
  {
    id: "bottomheading",
    renderedProperty: "bottomHeading",
    name: "Bottom Heading"
  },
  {
    id: "bottomparagraph",
    renderedProperty: "bottomParagraph",
    name: "Bottom Paragraph"
  },
  { id: "summary", renderedProperty: "summary", name: "Summary" },
  { id: "link", renderedProperty: "link", name: "Link - Canonical" },
  {
    id: "articleclass",
    renderedProperty: "articleClass",
    name: "ArticleClass"
  },
  { id: "rights", renderedProperty: "rights", name: "Rights" },
  {
    id: "dscategories",
    renderedProperty: "dsCategories",
    name: "DS Categories (JSON)"
  },
  {
    id: "outputexperimentlog",
    renderedProperty: "outputExperimentLog",
    name: "Output Experiment Log (JSON)"
  },
  { id: "tags", renderedProperty: "tags", name: "Tags (JSON)" },
  { id: "library", renderedProperty: "library", name: "Library" },
  {
    id: "featuredimagejson",
    renderedProperty: "featuredImageJSON",
    name: "Featured Image JSON"
  },
  {
    id: "featuredimage",
    renderedProperty: "featuredImage",
    name: "Featured Image (Deprecated)"
  },
  {
    id: "featuredimagecaption",
    renderedProperty: "featuredImageCaption",
    name: "Featured Image Caption"
  },
  { id: "schemaorg", name: "SchemaOrg" },
  {
    id: "clickdescription",
    renderedProperty: "clickDescription",
    name: "Click Description"
  },
  { id: "byline", renderedProperty: "byLine", name: "AP ByLine" },
  {
    id: "apcontentmanagementcharacteristicsmediatype",
    renderedProperty: "apContentManagementCharacteristicsMediaType",
    name: "AP Characteristic Media Type"
  },
  { id: "apdateline", renderedProperty: "apDateLine", name: "AP DateLine" },
  {
    id: "extendedheadline",
    renderedProperty: "extendedHeadline",
    name: "AP Extended Headline"
  },
  { id: "headline", renderedProperty: "headline", name: "AP Headline" },
  {
    id: "managementid",
    renderedProperty: "managementId",
    name: "AP Management Id"
  },
  {
    id: "managementtype",
    renderedProperty: "managementType",
    name: "AP Management Type"
  },
  {
    id: "apnewsmanagementpublishingspecialinstructions",
    renderedProperty: "apNewsManagementPublishingSpecialInstructions",
    name: "AP Publishing Special Instructions"
  },
  {
    id: "apnewsmanagementpublishingstatus",
    renderedProperty: "apNewsManagementPublishingStatus",
    name: "AP Publishing Status"
  },
  {
    id: "apnewsmanagementmanagementsequencenumber",
    renderedProperty: "apNewsManagementManagementSequenceNumber",
    name: "AP Sequence Number"
  },
  { id: "slugline", renderedProperty: "slugLine", name: "AP SlugLine" },
  {
    id: "messagetypeid",
    renderedProperty: "messageTypeId",
    name: "Message Type ID"
  },
  {
    id: "insightscore",
    renderedProperty: "insightScore",
    name: "Insight Score"
  },
  {
    id: "deliverytypeid",
    renderedProperty: "deliveryTypeId",
    name: "Delivery Type ID"
  },
  {
    id: "mediaurl",
    renderedProperty: "mediaUrl",
    name: "Media URL"
  },
  { id: "entrytags", renderedProperty: "entrytags", name: "EntryTags (JSON)" },
  { id: "metadata", renderedProperty: "metadata", name: "Metadata (JSON)" },
  {
    id: "interestgroups",
    renderedProperty: "interestgroups",
    name: "Interest Groups (JSON)"
  }
]

export const sectionsToRenderAsHtml = [
  "introparagraph",
  "content",
  "bottomparagraph",
  "bottomheading",
  "summary",
  "featuredimagejson"
]

export const sectionsWithSpecialDisplayIntructions = {
  expiredOn: { allBold: true },
  deliveryId: { allBold: true },
  entryId: { allBold: true },
  seoTitle: { nonStatsBold: true },
  seoSlug: { nonStatsBold: true },
  title: { nonStatsBold: true }
}

export const sectionsToIncludeWordCount = [
  ...sectionsToRenderAsHtml,
  "title",
  "seotitle",
  "metadescription",
  "clickdescription",
  "byline",
  "extendedheadline",
  "categories",
  "dscategories",
  "seoslug",
  "slugline",
  "apdateline",
  "headline"
]

export const onPageSections = ["title", "content"]
