import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import withStyles from "@mui/styles/withStyles"
import { Cookies } from "tools/storage"
import clsx from "clsx"
import Button from "components/CustomButtons/Button.jsx"
import {
  Box,
  Card,
  Snackbar,
  LinearProgress,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Grid,
  Collapse,
  Select,
  MenuItem,
  CircularProgress,
  AccordionSummary,
  AccordionDetails,
  OutlinedInput
} from "@mui/material"
import MUIAccordion from "@mui/material/Accordion"

//Icons
import Icon from "@mui/material/Icon"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Edit from "@mui/icons-material/Edit"
import Battery20 from "@mui/icons-material/Battery20"
import BatteryUnknown from "@mui/icons-material/BatteryUnknown"
import BlockOutlined from "@mui/icons-material/BlockOutlined"
import BookmarkBorderOutlined from "@mui/icons-material/BookmarkBorderOutlined"
import PlannedIcon from "@mui/icons-material/EmojiObjectsOutlined"

import { Autocomplete } from "@mui/material"
import GridItem from "components/Grid/GridItem.jsx"
import Accordion from "components/Accordion/Accordion"
import StatusType from "../Types/StatusType"
import TriggerTypeManager from "../Data/TriggerTypeManager"
import CodeBlock from "../Data/CodeBlock"
import CodeEditor from "components/CodeEditor"
import PropTypes from "prop-types"
import { globalSettings } from "variables/general.jsx"
import queryString from "query-string"
import Helpers from "tools/Helpers"
import WordCounter from "components/WordCounter/WordCounter.jsx"
import { NarrativeContext } from "contexts/narrative-context"
import ChangeLog from "../Data/ChangeLog"
import Trigger from "components/NarrativeAdmin/Trigger/Trigger"

import NarrativeCategories from "components/NarrativeAdmin/NarrativeCategories"
import RenderType from "../Types/RenderType"
import BlockTriggerDetails from "../BlockTriggerDetails/BlockTriggerDetails"
import ReactJson from "react-json-view"
import ReactTable from "react-table-6"
import SaturationChip from "components/ContentSaturation/SaturationChip"
import ContentSaturationSentenceModal from "components/ContentSaturation/SentenceModal"
import ContentSaturationParagraphModal from "components/ContentSaturation/ParagraphModal"
import NarrativeTags from "../NarrativeTags"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { observer, inject } from "mobx-react"
import Check from "@mui/icons-material/CheckCircleOutline"
import Report from "@mui/icons-material/Report"
import GridContainer from "components/Grid/GridContainer"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { TabPanel } from "components/TabPanel/TabPanel"
import { get, put } from "tools/request"
import CTAAccordion from "components/CTAEditorV2/CTAAccordion"

const styles = theme => ({
  sentenceHeader: {
    margin: "0 15px",
    padding: "10px 0!important",
    display: "flex",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  codePreview: {
    margin: "0 10px",
    padding: "20px!important",
    background: "#000",
    color: "#fff",
    cursor: "pointer",
    fontFamily: "monospace",
    fontSize: "12px",
    "& .highlighted": {
      backgroundColor: "#ffeaa7",
      color: "#e17055",
      boxShadow: "-3px 0 0 #ffeaa7, 3px 0 0 #ffeaa7" // Creates an illusion of a wider span, while not affecting the actual width.
    }
  },
  codeHolder: {
    margin: "0",
    padding: "0 10px"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding: "0px",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  saturationModalIcon: {
    position: "relative",
    marginTop: "-25px",
    display: "flex",
    justifyContent: "flex-end"
  },
  saturationContainer: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    marginBottom: "-16px"
  },
  saturationComponent: {
    position: "relative",
    marginTop: "-4px",
    display: "inline-block"
  },
  selected: {
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#bdbdbd"
    }
  },
  statusIcon: {
    fontSize: "1em",
    width: "28px",
    height: "25px",
    color: "#144E68",
    marginRight: "10px",
    marginBottom: "-8px"
  },
  libraryContainer: {
    display: "inline-flex",
    alignItems: "flex-end"
  },
  cloneSentenceTable: {
    display: "flex",
    flexDirection: "column !important"
  },
  categoriesGrid: {
    display: "inline-flex",
    alignItems: "center"
  },
  checkboxStyle: {
    marginTop: "16px"
  }
})

const useStore = component =>
  inject(({ store }) => ({
    narrativeParagraphTagsSummary:
      store.narrativeStore.narrativeParagraphTagsSummary,
    narrativeParagraphTags: store.narrativeStore.narrativeParagraphTags
  }))(observer(component))

const PUBLISHED = 3

class Sentence extends Component {
  constructor(props) {
    super(props)
    const queryParams = queryString.parse(props.location.search)
    this.state = {
      activeTriggers: [],
      inverseTriggers: [],
      currentNarrativeId:
        +props.match.params.id ||
        (props.searchParams && Number(props.searchParams.narrativeId)),
      narrative_Id: props.sentence.narrative_Id,
      paragraph_Id: props.sentence.paragraph_Id,
      template: props.sentence.template,
      starter: props.sentence.starter,
      position: props.sentence.position,
      sentence_Id: props.sentence.id,
      sentenceName: props.sentence.name,
      renderType: props.sentence.renderType,
      sentenceDetails: props.createMode || props.sentence.isLibraryPlaceholder,
      triggerIds: props.sentence.triggerIds || "",
      triggerType: props.sentence.triggerType || "",
      triggerValue: props.sentence.triggerValue,
      triggerWeight: props.sentence.triggerWeight || "",
      isLibraryPlaceholder: props.sentence.isLibraryPlaceholder || false,
      libraryParagraph_Id: props.sentence.libraryParagraph_Id || null,
      librarySentencePosition: props.sentence.librarySentencePosition || null,
      status_Id: props.sentence.status_Id || 3,
      ownerId: props.sentence.ownerId || 0,
      isArchived: props.sentence.isArchived,
      comments: props.sentence.comments || "",
      categories: props.sentence.categories,
      requiredData: props.sentence.requiredData || "",
      changeLogs: [],
      isHistoryVisible: false,
      isChangeLogsLoading: false,
      isChangeLogsLoaded: false,
      isCloneVisible: false,
      clonedInfo: [],
      clonedFrom_Id: props.sentence.clonedInfo,
      isCloneHistoryLoaded: false,
      isSnackbarOpen: false,
      saturation: props.sentence.saturation,
      utilization: props.sentence.utilization,
      alertMessage: "",
      edit:
        props.edit ||
        (queryParams.view && queryParams.view.startsWith("sentenceid")),
      createMode: props.createMode,
      preview: "",
      isLoadingPreview: false,
      previewElapsedTimeMilliseconds: 0,
      isRawContentVisibile: false,
      currentView:
        props.contentBlock === "categories" ||
        props.contentBlock === "dscategories" ||
        props.contentBlock === "outputexperimentlog" ||
        props.contentBlock === "tags" ||
        props.contentBlock === "entrytags" ||
        props.contentBlock === "metadata" ||
        props.contentBlock === "interestgroups"
          ? 1
          : 0, // Category or Tag Editor
      libraryOrPositionChange: false,
      includeAllStatuses: false,
      isSaving: false
    }
    this.buildPreview = this.buildPreview.bind(this)
    this.loadSentenceHistory = this.loadSentenceHistory.bind(this)
    this.fetchSentenceChangeLog = this.fetchSentenceChangeLog.bind(this)
    this.toggleRawContent = this.toggleRawContent.bind(this)
    this.previewStartDateTime = new Date()
    this.splitTriggers = this.splitTriggers.bind(this)
    this.loadCloneHistory = this.loadCloneHistory.bind(this)
    this.loadClonedFrom = this.loadClonedFrom.bind(this)
    this.maybeLoadDetails = this.maybeLoadDetails.bind(this)
    this.handleLibraryParagraphChange =
      this.handleLibraryParagraphChange.bind(this)
    this.handleLibraryPlaceholderChange =
      this.handleLibraryPlaceholderChange.bind(this)
  }

  componentDidMount() {
    //read below in componentDidUpdate(e)
    let cookies = new Cookies()
    let val = cookies.get("setEditFromExplorer")
    if (val && typeof val.toggleViewFromExplorer === "boolean") {
      if (val.toggleViewFromExplorer !== this.state.sentenceDetails) {
        this.setState({ sentenceDetails: val.toggleViewFromExplorer })
      }
    }
    this.splitTriggers()
    cookies.set("setEditFromExplorer", {})
    this.loadClonedFrom()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.isArchived &&
      nextProps.isArchived !== this.state.isArchived
    ) {
      this.setState({ isArchived: nextProps.isArchived })
    }
    if (
      nextProps.triggerType &&
      nextProps.triggerType !== this.state.triggerType
    ) {
      this.setState({ triggerType: nextProps.triggerType })
    }
    if (
      nextProps.sentence.isLibraryPlaceholder &&
      nextProps.sentence.isLibraryPlaceholder !==
        this.state.isLibraryPlaceholder
    ) {
      this.setState({
        isLibraryPlaceholder: nextProps.sentence.isLibraryPlaceholder
      })
    }
    if (
      nextProps.sentence.saturation &&
      nextProps.sentence.saturation !== this.state.saturation
    ) {
      this.setState({ saturation: nextProps.sentence.saturation })
    }
    if (
      nextProps.sentence.utilization &&
      nextProps.sentence.utilization !== this.state.utilization
    ) {
      this.setState({ utilization: nextProps.sentence.utilization })
    }
    if (nextProps.edit && this.state.edit !== nextProps.edit) {
      this.setState({ edit: nextProps.edit })
    }

    if (
      nextProps.sentence &&
      nextProps.sentence.id !== this.state.sentence_Id
    ) {
      this.setState(
        {
          sentence_Id: nextProps.sentence.id,
          narrative_Id: nextProps.sentence.narrative_Id,
          paragraph_Id: nextProps.sentence.paragraph_Id,
          template: nextProps.sentence.template,
          starter: nextProps.sentence.starter,
          position: nextProps.sentence.position,
          sentenceName: nextProps.sentence.name,
          renderType: nextProps.sentence.renderType,
          triggerIds: nextProps.sentence.triggerIds,
          triggerType: nextProps.sentence.triggerType,
          triggerValue: nextProps.sentence.triggerValue,
          triggerWeight: nextProps.sentence.triggerWeight || "",
          isLibraryPlaceholder:
            nextProps.sentence.isLibraryPlaceholder || false,
          status_Id: nextProps.sentence.status_Id || 3,
          ownerId: nextProps.sentence.ownerId || 0,
          isArchived: nextProps.sentence.isArchived,
          comments: nextProps.sentence.comments || "",
          requiredData: nextProps.sentence.requiredData || "",
          categories: nextProps.sentence.categories || "",
          isSnackbarOpen: false,
          saturation: nextProps.sentence.saturation,
          utilization: nextProps.sentence.utilization
        },
        () => this.buildPreview()
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //Workaround to allow us to pass info from 2 children of NarrativeEditor to each other - NarrativeExplorer and here
    //Reason this code is needed is the NarrativeExplorer (child) can send info to the NarrativeEditor (parent) via a prop function
    //But when I change that state of a prop in the NarrativeEditor (parent) to tell this component (child) what has changed,
    //this component senses the change in prop values and updates, BUT it does not receive the current prop values
    //So as a workaround, I save that info in the NarrativeEditor in LocalStorage and then read it here and clear it out
    let cookies = new Cookies()
    let val = cookies.get("setEditFromExplorer")
    if (val && typeof val.toggleViewFromExplorer === "boolean") {
      if (val.toggleViewFromExplorer !== this.state.sentenceDetails) {
        this.setState({ sentenceDetails: val.toggleViewFromExplorer })
      }
    }
    cookies.set("setEditFromExplorer", {})

    if (
      prevState.libraryParagraph_Id !== this.state.libraryParagraph_Id ||
      prevState.librarySentencePosition !== this.state.librarySentencePosition
    ) {
      this.setState({
        sentenceName: this.getPrefilledPlaceholderSentenceName()
      })
    }
  }

  static contextType = NarrativeContext

  get standardAlertMessage() {
    return `Warning: Sentence ${
      this.props.sentence?.id || "Unknown"
    } has Trigger IDs that need to be synced.`
  }

  get filteredLibraryParagraphs() {
    const narrativeParagraphTagsSorted = this.props.narrativeParagraphTags || []

    const filteredLibraryParagraphsById = []
    narrativeParagraphTagsSorted?.forEach(itm => {
      this.state.libraryParagraphTagsSelected?.forEach((itm2, index) => {
        if (itm.tagId === itm2.tagId && itm.tagTypeId === itm2.tagTypeId) {
          // $TODO This check is always false, because it's checking for a newly created object reference in the array
          // It is not checking for an object with a certain id or index
          if (
            !filteredLibraryParagraphsById.includes({
              id: itm.paragraphId,
              index
            })
          ) {
            filteredLibraryParagraphsById.push({
              id: itm.paragraphId,
              index
            })
          }
        }
      })
    })

    const { libraryParagraphs } = this.props
    let filteredLibraryParagraphs = filteredLibraryParagraphsById.length
      ? libraryParagraphs?.filter(itm => {
          let found = []
          filteredLibraryParagraphsById.forEach(itm2 => {
            if (itm.id === itm2.id) {
              if (!found.includes(itm2.index)) {
                found.push(itm2.index)
              }
            }
          })
          return found.length === this.state.libraryParagraphTagsSelected.length
        })
      : libraryParagraphs || []
    filteredLibraryParagraphs = filteredLibraryParagraphs.filter(itm => {
      if (this.state.narrative_Id?.isLibrary) {
        return true
      } else if (!this.state.includeAllStatuses) {
        if (this.state.libraryParagraph_Id === itm.id) {
          return true
        } else {
          return itm.status_Id === PUBLISHED
        }
      } else {
        return true
      }
    })

    return filteredLibraryParagraphs
  }

  handleSave = () => {
    const { sentence } = this.props
    const {
      template,
      starter,
      position,
      narrative_Id,
      paragraph_Id,
      sentenceName,
      sentencetype,
      triggerIds,
      triggerType,
      triggerValue,
      triggerWeight,
      isLibraryPlaceholder,
      libraryParagraph_Id,
      librarySentencePosition,
      status_Id,
      ownerId,
      isArchived,
      comments,
      categories,
      renderType,
      saturation,
      utilization
    } = this.state

    this.setState({ isSaving: true })

    if (
      sentence.libraryParagraph_Id !== libraryParagraph_Id ||
      sentence.position !== position
    ) {
      this.setState({ libraryOrPositionChange: true })
    }

    sentence.narrative_Id = narrative_Id
    sentence.name = sentenceName
    sentence.sentencetype = sentencetype
    sentence.narrative_Id = narrative_Id
    sentence.template = template
    sentence.starter = starter
    sentence.position = position
    sentence.paragraph_Id = paragraph_Id
    sentence.triggerIds = triggerIds
    sentence.triggerType = triggerType
    sentence.triggerValue = triggerValue
    sentence.triggerWeight = triggerWeight
    sentence.isLibraryPlaceholder = isLibraryPlaceholder
    sentence.libraryParagraph_Id = libraryParagraph_Id
    sentence.librarySentencePosition = librarySentencePosition
    sentence.status_Id = status_Id || 3
    sentence.ownerId = ownerId || 0
    sentence.isArchived = isArchived
    sentence.comments = comments
    sentence.categories = categories
    sentence.renderType = renderType
    sentence.saturation = saturation
    sentence.utilization = utilization

    if (template || isLibraryPlaceholder) {
      put(`${globalSettings.apiBaseUrl}/api/sentence`, null, sentence)
        .then(Response => Response && Response.ok === true && Response.json())
        .then(sId => {
          const newSentence = {
            ...sentence,
            id: sId
          }
          this.props.savedSentence && this.props.savedSentence(newSentence)
          this.setState({ isSaving: false, isSnackbarOpen: false }, () => {
            this.toggle(true, newSentence, sId)
          })
          if (this.state.libraryOrPositionChange) {
            this.props.handleReload && this.props.handleReload()
            this.setState({ libraryOrPositionChange: false })
          }
        })
    } else {
      this.setState({ isSaving: false, isSnackbarOpen: false })
      //TODO: add message to tell user if did not save
    }
  }

  handleStatusChange = event => {
    this.setState({ includeAllStatuses: event.target.checked })
  }

  handleCodeEditorChange = editor => {
    const { template } = this.state
    if (editor && editor.getValue() !== template) {
      this.setState({
        isSnackbarOpen: true,
        alertMessage: this.standardAlertMessage,
        template: editor.getValue()
      })
    }
  }

  handleAddCTAVariant = templateCTA => {
    this.setState(prevState => ({
      template: `${prevState.template} ${templateCTA}`
    }))
  }

  getPrefilledPlaceholderSentenceName = () => {
    const { libraryParagraph_Id, librarySentencePosition } = this.state

    const libraryParagraph = this.filteredLibraryParagraphs?.find(
      paragraph => paragraph.id === libraryParagraph_Id
    )
    if (!libraryParagraph) {
      return ""
    }

    const paragraphNameDisplay = `Lib. Para. ${libraryParagraph.id} - ${libraryParagraph.name}`
    const positionDisplay = `Pos. ${librarySentencePosition}`
    const prefilledName = librarySentencePosition
      ? `${paragraphNameDisplay} - ${positionDisplay}`
      : paragraphNameDisplay
    return prefilledName
  }

  handleLibraryParagraphChange = event => {
    this.setState({
      isSnackbarOpen: true,
      libraryParagraph_Id: event.target.value
    })
  }

  handleLibraryPlaceholderChange = event => {
    const { checked } = event.target || {}
    this.setState({
      isSnackbarOpen: true,
      alertMessage: this.standardAlertMessage,
      isLibraryPlaceholder: checked,
      sentenceName: checked ? this.getPrefilledPlaceholderSentenceName() : ""
    })
  }

  labelStatusIcon(status) {
    const { includeAllStatuses } = this.state
    switch (status) {
      case 1:
        return includeAllStatuses ? <Battery20 /> : null
      case 2:
        return includeAllStatuses ? <BatteryUnknown /> : null
      case 3:
        return null
      case 4:
        return includeAllStatuses ? <PlannedIcon /> : null
      case 5:
        return includeAllStatuses ? <BookmarkBorderOutlined /> : null
      case 6:
        return includeAllStatuses ? <BlockOutlined /> : null
      default:
        return null
    }
  }

  loadSentenceHistory() {
    if (!this.state.isHistoryVisible) {
      this.setState({ isHistoryVisible: true })
      if (!this.state.isChangeLogsLoaded) {
        this.fetchSentenceChangeLog()
      }
    } else {
      this.setState({ isHistoryVisible: false })
    }
  }

  toggleRawContent() {
    if (!this.state.isRawContentVisibile) {
      this.setState({ isRawContentVisibile: true })
    } else {
      this.setState({ isRawContentVisibile: false })
    }
  }

  handleMultiChange(event, newValue, reason, name) {
    this.setState({ libraryParagraphTagsSelected: newValue })
  }

  fetchSentenceChangeLog() {
    const { sentence_Id } = this.state
    this.setState({ isChangeLogsLoading: true })
    get(`${globalSettings.apiBaseUrl}/api/sentence/${sentence_Id}/history`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(content => {
        if (!content) {
          return
        }
        this.setState({
          changeLogs: content,
          totalChangeLogRecords: content.length,
          isChangeLogsLoading: false,
          isChangeLogsLoaded: true
        })
      })
  }

  loadCloneHistory() {
    if (!this.state.isCloneVisible) {
      this.setState({ isCloneVisible: true })
    } else {
      this.setState({ isCloneVisible: false })
    }
  }

  maybeLoadDetails() {
    if (!this.state.isCloneHistoryLoaded) {
      this.loadClonedFrom()
    }
  }

  loadClonedFrom() {
    const { sentence_Id } = this.state
    if (!sentence_Id) {
      return
    }
    get(`${globalSettings.apiBaseUrl}/api/sentence/${sentence_Id}`)
      .then(Response => Response && Response.ok === true && Response.json())
      .then(content => {
        if (!content) {
          return
        }

        const clonedInfo = []

        if (content.clonedFrom_Id) {
          const obj = {
            relationship: "Cloned From",
            sentence_Id: content.clonedFrom_Id,
            narrative_Id: content.clonedFrom.narrative_Id,
            template: content.clonedFrom.template
          }

          clonedInfo.push(obj)
        }

        if (content.clones) {
          content.clones.forEach(clone => {
            const obj = {
              relationship: "Cloned To",
              sentence_Id: clone.id,
              narrative_Id: clone.narrative_Id,
              template: clone.template
            }

            clonedInfo.push(obj)
          })
        }

        this.setState({
          clonedInfo,
          totalClonedInfo: content.length,
          isCloneHistoryLoaded: true,
          narrative_Id: content.narrative_Id,
          paragraph_Id: content.paragraph_Id,
          template: content.template,
          starter: content.starter,
          position: content.position,
          sentence_Id: content.id,
          sentenceName: content.name,
          renderType: content.renderType,
          triggerIds: content.triggerIds || "",
          triggerType: content.triggerType || "",
          triggerValue: content.triggerValue,
          triggerWeight: content.triggerWeight || "",
          isLibraryPlaceholder: content.isLibraryPlaceholder || false,
          libraryParagraph_Id: content.libraryParagraph_Id || null,
          librarySentencePosition: content.librarySentencePosition || null,
          status_Id: content.status_Id || 3,
          ownerId: content.ownerId || 0,
          isArchived: content.isArchived,
          comments: content.comments || "",
          categories: content.categories,
          requiredData: content.requiredData || "",
          clonedFrom_Id: content.clonedInfo
        })
      })
  }

  handleEdit() {
    const { location, sentence } = this.props
    const queryParams = queryString.parse(location.search)
    if (queryParams.view && queryParams.view.includes("sentenceid")) {
      this.state && this.setState({ edit: true })
    } else {
      this.context.setActiveView(`sentenceid-${sentence.id}`)
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      currentView: newValue
    })
  }

  toggle(reloadParent = false, sentence, sId) {
    if (!this.props) {
      return
    }
    // Toggle the modal edit state
    this.state && this.setState({ edit: !this.state.edit })
    if (this.props.hitExitSentence) {
      //In Search Editor, close on cancel
      this.props.hitExitSentence()
    }
    // callback to whatever the parent wanted you to
    if (this.props && this.props.toggleEdit) {
      this.props.toggleEdit(reloadParent, sentence, sId)
    }
  }

  getOwnerInfo() {
    let ownerId = this.state.ownerId

    return (
      <div
        style={{
          width: "200px",
          justifyContent: "flex-end",
          display: "flex",
          marginRight: "10px"
        }}
      >
        <FormControl margin="normal" fullWidth>
          <InputLabel>Owner</InputLabel>
          <Select
            label="Owner"
            value={ownerId || ""}
            type="select"
            name="ownerId"
            id="ownerId"
            onChange={e => {
              this.setState({ ownerId: e.target.value })
              e.preventDefault()
            }}
          >
            <MenuItem
              key="none"
              value="0"
              classes={{ selected: this.props.classes.selected }}
            >
              None
            </MenuItem>
            {this.props.ownerListForOrg &&
              this.props.ownerListForOrg.map((option, i) => (
                <MenuItem
                  key={i}
                  value={option.id}
                  classes={{ selected: this.props.classes.selected }}
                >
                  {option.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    )
  }

  buildPreview() {
    const { sentence } = this.props
    const { currentNarrativeId, ignoreKillswitch } = this.state

    const { model, modelType, modelTypeId, contentId, contentType } =
      this.context
    const queryParams = queryString.parse(this.props.location.search)
    this.setState({
      isLoadingPreview: true
    })
    this.previewStartDateTime = new Date()
    put(
      `${globalSettings.apiBaseUrl}/api/narrativepreview/template-preview`,
      null,
      {
        contentId,
        contentType: contentId ? contentType : "",
        dataModelText: contentId ? "" : model,
        modelType,
        modelTypeId,
        narrativeId: currentNarrativeId,
        templateString: this.state.template,
        itemIndex: parseInt(queryParams.itemindex, 10),
        useCache: true,
        ignoreKillswitch: ignoreKillswitch === true
      }
    )
      .then(Response => Response && Response.ok === true && Response.json())
      .then(preview => {
        const previewText = sentence.renderType
          ? `<${sentence.renderType}>${preview}</${sentence.renderType}`
          : preview
        this.setState({
          preview: previewText,
          isLoadingPreview: false,
          previewElapsedTimeMilliseconds:
            new Date().getTime() - this.previewStartDateTime.getTime()
        })
      })
  }

  splitTriggers() {
    const split = this.state.triggerType.split(", ")
    const activeTriggers = []
    const inverseTriggers = []
    split.forEach(trigger => {
      if (trigger[0] === "!") {
        let edittedTrigger = trigger.substr(1)
        inverseTriggers.push(edittedTrigger)
      } else {
        activeTriggers.push(trigger)
      }
    })
    this.setState({
      activeTriggers,
      inverseTriggers
    })
  }

  handleSnackClose = () => {
    this.setState({ isSnackbarOpen: false })
  }

  render() {
    const {
      sentence,
      createMode,
      classes,
      triggers,
      contentBlock,
      orgId,
      leagueId,
      verticalId
    } = this.props
    const {
      renderType,
      template,
      position,
      triggerIds,
      triggerType,
      triggerWeight,
      isLibraryPlaceholder,
      preview,
      narrative_Id,
      currentNarrativeId,
      status_Id,
      isArchived,
      sentenceDetails,
      requiredData,
      previewElapsedTimeMilliseconds,
      isSnackbarOpen,
      alertMessage,
      activeTriggers,
      inverseTriggers,
      clonedInfo,
      saturation,
      utilization
    } = this.state

    const { searchString } = this.context

    if (!sentence) {
      return null
    }

    const titleText = (
      <React.Fragment>
        <span style={{ color: "#3f51b5", fontWeight: "bold" }}>&nbsp;</span>
      </React.Fragment>
    )

    const triggerTypes =
      ((activeTriggers !== null || inverseTriggers !== null) &&
        activeTriggers.concat(inverseTriggers)) ||
      []

    const filteredTriggers =
      triggers && triggers.filter(t => triggerTypes.includes(t.type))

    const sentenceTriggers = filteredTriggers.map((trigger, key) => (
      <Trigger
        key={`${trigger.id}-${key}`}
        trigger={trigger}
        model={this.state.model}
        toggleViewFromExplorer={this.state.toggleViewFromExplorer}
        defaultExpanded={false}
      />
    ))

    const narrativeParagraphTagsSummarySorted =
      this.props.narrativeParagraphTagsSummary || []

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = (
      <CheckBoxIcon fontSize="small" className={classes.checkRegion} />
    )

    return (
      <div onClick={this.maybeLoadDetails}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={isSnackbarOpen}
          key="ChangeLogSnack"
          message={alertMessage}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
        />
        {!this.state.edit && !createMode && (
          <div
            style={{
              background:
                status_Id !== 3 ? "rgba(193, 184, 184, 0.6)" : "initial",
              textDecoration: isArchived ? "line-through" : "initial",
              paddingBottom: "10px",
              borderTop: "1px solid #efefef"
            }}
          >
            <div className={classes.sentenceHeader}>
              <BlockTriggerDetails
                triggerType={triggerType}
                triggerIds={triggerIds}
                allAvailableTriggers={triggers}
                titleText={titleText}
                searchString={searchString}
                setActiveView={this.context.setActiveView}
                searchEditorMode={this.props.searchEditorMode}
                setTriggerIds={triggerIds => {
                  if (currentNarrativeId === narrative_Id) {
                    this.setState({
                      isSnackbarOpen: true,
                      alertMessage: `${this.standardAlertMessage} Trigger Ids have changed.`,
                      triggerIds
                    })
                  }
                }}
              />
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: sentenceDetails
                })}
                onClick={e => this.handleEdit(e)}
                aria-expanded={sentenceDetails}
                aria-label="show more"
                size="large"
              >
                <Edit />
              </IconButton>
            </div>
            <div
              className={classes.codePreview}
              onClick={() => this.handleEdit()}
            >
              <CodeBlock highlight={searchString}>{template}</CodeBlock>
            </div>
          </div>
        )}
        {this.state.edit && (
          <div>
            <div className={classes.sentenceHeader}>
              <BlockTriggerDetails
                triggerType={triggerType}
                triggerIds={triggerIds}
                allAvailableTriggers={triggers}
                titleText={titleText}
                searchString={searchString}
                setActiveView={this.context.setActiveView}
                searchEditorMode={this.props.searchEditorMode}
                setTriggerIds={triggerIds => {
                  if (currentNarrativeId === narrative_Id) {
                    this.setState({
                      isSnackbarOpen: true,
                      alertMessage: `${this.standardAlertMessage} Run Trigger ID sync now.`,
                      triggerIds
                    })
                  }
                }}
              />
              {this.getOwnerInfo()}
              <div style={{ width: "200px" }}>
                <StatusType
                  value={status_Id}
                  disabled={currentNarrativeId !== sentence.narrative_Id}
                  onChange={e =>
                    this.setState({
                      isSnackbarOpen: true,
                      alertMessage: this.standardAlertMessage,
                      status_Id: e
                    })
                  }
                  classes={{ selected: classes.selected }}
                />
              </div>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: sentenceDetails
                })}
                onClick={() =>
                  this.setState({
                    sentenceDetails: !sentenceDetails
                  })
                }
                aria-expanded={sentenceDetails}
                aria-label="show more"
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
            <div className={classes.codeHolder}>
              <Collapse in={sentenceDetails}>
                <Box style={{ padding: "0 10px" }}>
                  <Grid container spacing={0}>
                    <Grid container spacing={2} item xs={12}>
                      <GridItem item xs={2}>
                        <FormControl margin="normal">
                          <TextField
                            type="number"
                            name="sentenceidinput"
                            id="sentenceid"
                            label="Sentence Id"
                            value={sentence.id}
                            onChange={e =>
                              this.setState({
                                isSnackbarOpen: true,
                                alertMessage: this.standardAlertMessage,
                                sentence_id: e.target.value
                              })
                            }
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={6}>
                        <FormControlLabel
                          className={classes.checkboxStyle}
                          control={
                            <Checkbox
                              checked={isArchived}
                              onChange={event => {
                                this.setState({
                                  isSnackbarOpen: true,
                                  alertMessage: this.standardAlertMessage,
                                  isArchived: event.target.checked
                                })
                              }}
                            />
                          }
                          label="Archived"
                        />
                      </GridItem>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid container spacing={2} item xs={12}>
                        <Grid item xs={2}>
                          <FormControl margin="normal">
                            <InputLabel>Paragraph Id</InputLabel>
                            <OutlinedInput
                              type="number"
                              name="paragraphidinput"
                              id="paragraphid"
                              label="Paragraph Id"
                              value={sentence.paragraph_Id}
                              onChange={e =>
                                this.setState({
                                  isSnackbarOpen: true,
                                  alertMessage: this.standardAlertMessage,
                                  paragraph_Id: e.target.value
                                })
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl margin="normal">
                            <InputLabel>Position</InputLabel>
                            <OutlinedInput
                              type="number"
                              name="positioninput"
                              id="position"
                              label="Position"
                              value={position}
                              onChange={e =>
                                this.setState({
                                  isSnackbarOpen: true,
                                  alertMessage: this.standardAlertMessage,
                                  position: e.target.value
                                })
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel>Sentence Name</InputLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              name="sentenceName"
                              id="sentenceName"
                              label="Sentence Name"
                              value={this.state.sentenceName || ""}
                              onChange={e =>
                                this.setState({
                                  isSnackbarOpen: true,
                                  alertMessage: this.standardAlertMessage,
                                  sentenceName: e.target.value
                                })
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          <RenderType
                            aria-label="Sentence RenderType Dropdown"
                            margin="normal"
                            fullWidth
                            value={renderType || ""}
                            renderNoneOption={
                              sentence.isLibraryPlaceholder ||
                              this.props.isLibrary
                            }
                            onChange={e =>
                              this.setState({
                                isSnackbarOpen: true,
                                alertMessage: this.standardAlertMessage,
                                renderType: e
                              })
                            }
                            classes={{ selected: classes.selected }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            className={classes.checkboxStyle}
                            control={
                              <Checkbox
                                checked={isLibraryPlaceholder}
                                onChange={this.handleLibraryPlaceholderChange}
                              />
                            }
                            label="Library Position Group"
                          />
                        </Grid>
                      </Grid>
                      {isLibraryPlaceholder && (
                        <React.Fragment>
                          <GridContainer
                            xs={12}
                            className={classes.libraryContainer}
                          >
                            {this.props.isToBeFilteredBy && (
                              <Grid xs={2}>
                                <Autocomplete
                                  style={{
                                    marginTop: "16px",
                                    marginBottom: "8px"
                                  }}
                                  multiple={true}
                                  className={classes.autocompleteModal}
                                  fullWidth={true}
                                  limitTags={4}
                                  name="libraryParagraphTagsSelected"
                                  id="libraryParagraphTagsSelected"
                                  value={narrativeParagraphTagsSummarySorted.filter(
                                    rgn => {
                                      let match = false
                                      this.state.libraryParagraphTagsSelected &&
                                        this.state.libraryParagraphTagsSelected?.forEach(
                                          itm => {
                                            if (
                                              itm.tagId === rgn.tagId &&
                                              itm.tagTypeId === rgn.tagTypeId
                                            ) {
                                              match = true
                                            }
                                          }
                                        )
                                      return match
                                    }
                                  )}
                                  options={narrativeParagraphTagsSummarySorted}
                                  onChange={(event, newValue, reason) => {
                                    this.handleMultiChange(
                                      event,
                                      newValue,
                                      reason,
                                      "libraryParagraphTagsSelected"
                                    )
                                  }}
                                  getOptionLabel={option =>
                                    `${option.tagTypeId} - ${option.tagName}`
                                  }
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={selected}
                                      />
                                      {option.tagTypeId} - {option.tagName}
                                    </li>
                                  )}
                                  isOptionEqualToValue={(option, value) =>
                                    option && value
                                      ? option.tagId === value.tagId
                                      : false
                                  }
                                  renderInput={params => (
                                    <TextField {...params} label="FILTER BY" />
                                  )}
                                />
                              </Grid>
                            )}
                            <GridItem xs={3}>
                              <div className={classes.saturationContainer}>
                                <div className={classes.saturationComponent}>
                                  <ContentSaturationParagraphModal />
                                </div>
                              </div>
                              <FormControl margin="normal" fullWidth>
                                <InputLabel>Library Paragraph</InputLabel>
                                <Select
                                  fullWidth={true}
                                  label="Library Paragraph"
                                  type="select"
                                  name="libraryParagraph_Id"
                                  id="libraryParagraph_Id"
                                  value={this.state.libraryParagraph_Id || ""}
                                  onChange={this.handleLibraryParagraphChange}
                                >
                                  <MenuItem
                                    key="none"
                                    value=""
                                    classes={{
                                      selected: this.props.classes.selected
                                    }}
                                  >
                                    None
                                  </MenuItem>
                                  {this.filteredLibraryParagraphs?.map(lp => (
                                    <MenuItem
                                      key={lp.id}
                                      value={lp.id}
                                      classes={{
                                        selected: this.props.classes.selected
                                      }}
                                    >
                                      <Icon className={classes.statusIcon}>
                                        {this.labelStatusIcon(lp.status_Id)}
                                      </Icon>
                                      {lp.id} - {lp.name}
                                      <SaturationChip
                                        saturation={lp.saturation}
                                        utilization={lp.utilization}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                            <GridItem xs={2}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id="includeAllStatuses"
                                    size="small"
                                    checked={
                                      this.state.includeAllStatuses || false
                                    }
                                    onChange={this.handleStatusChange}
                                  />
                                }
                                label="Include All Statuses?"
                              />
                            </GridItem>
                            <GridItem xs={2}>
                              <FormControl margin="normal">
                                <InputLabel>Library Position</InputLabel>
                                <OutlinedInput
                                  type="text"
                                  name="librarySentencePosition"
                                  id="librarySentencePosition"
                                  label="Library Position"
                                  value={
                                    this.state.librarySentencePosition || ""
                                  }
                                  onChange={e => {
                                    //Check for any character not a number or "," OR two straight ",," and reject it
                                    if (
                                      e.target.value.replace(/\d|,/g, "") ||
                                      e.target.value.match(/,,/) ||
                                      e.target.value.match(/^,/)
                                    ) {
                                      return
                                    }
                                    this.setState({
                                      isSnackbarOpen: true,
                                      alertMessage: this.standardAlertMessage,
                                      librarySentencePosition: e.target.value
                                    })
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={2}>
                              <InputLabel>Sentence Variation</InputLabel>
                              <div className={classes.saturationModalIcon}>
                                <ContentSaturationSentenceModal />
                              </div>
                              <FormControl margin="normal">
                                <SaturationChip
                                  saturation={saturation}
                                  utilization={utilization}
                                />
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2} xs={12}>
                    <Grid item xs={7}>
                      <TriggerTypeManager
                        value={triggerType || ""}
                        onChange={o =>
                          this.setState({
                            isSnackbarOpen: true,
                            alertMessage: this.standardAlertMessage,
                            triggerType: o.key,
                            triggerName: o.name
                          })
                        }
                        classes={{ selected: classes.selected }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl margin="normal">
                        <InputLabel>Weight</InputLabel>
                        <OutlinedInput
                          type="number"
                          name="triggerWeight"
                          id="triggerWeight"
                          label="Weight"
                          value={triggerWeight}
                          onChange={e =>
                            this.setState({
                              isSnackbarOpen: true,
                              alertMessage: this.standardAlertMessage,
                              triggerWeight: e.target.value
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid>
                    <CTAAccordion
                      orgId={orgId}
                      leagueId={leagueId}
                      verticalId={verticalId}
                      handleAddCTAVariant={this.handleAddCTAVariant}
                    />
                  </Grid>
                  <Grid
                    className={classes.categoriesGrid}
                    container
                    item
                    spacing={2}
                    xs={12}
                  />
                  {requiredData && requiredData !== "[]" && (
                    <Grid container item spacing={2} xs={12}>
                      <Grid item xs={12}>
                        <ReactJson
                          enableClipboard={true}
                          displayObjectSize={true}
                          displayDataTypes={false}
                          src={requiredData}
                          collapsed={true}
                          shouldCollapse={({ name }) => name === "value"}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Accordion
                    collapses={[
                      {
                        title: "Comments",
                        content: (
                          <TextField
                            id="comments"
                            fullWidth={true}
                            multiline={true}
                            minRows={3}
                            value={this.state.comments}
                            style={{ padding: "5px" }}
                            onChange={e =>
                              this.setState({
                                isSnackbarOpen: true,
                                alertMessage: this.standardAlertMessage,
                                comments: e.target.value
                              })
                            }
                          />
                        )
                      }
                    ]}
                  />
                </Box>
                {isLibraryPlaceholder && (
                  <Box>
                    {(this.state.isSaving || this.state.isLoadingPreview) && (
                      <LinearProgress />
                    )}
                    <Grid container spacing={1}>
                      <Grid
                        container
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        {!isLibraryPlaceholder && (
                          <Button
                            onClick={() => this.buildPreview()}
                            disabled={this.state.isLoadingPreview}
                          >
                            Preview
                          </Button>
                        )}
                        &nbsp;
                        <Button onClick={() => this.toggle()}>
                          Cancel
                        </Button>{" "}
                        &nbsp;
                        {currentNarrativeId === narrative_Id && (
                          <React.Fragment>
                            <Button
                              color="primary"
                              onClick={() => this.handleSave()}
                              disabled={this.isSaving}
                            >
                              Save
                            </Button>
                          </React.Fragment>
                        )}
                        {currentNarrativeId !== narrative_Id && (
                          <Button
                            title="Opens new window"
                            onClick={() => {
                              window.open(
                                `/portal/narrative/${narrative_Id}/edit?view=sentenceid-${sentence.id}`,
                                "_blank"
                              )
                            }}
                            color="primary"
                          >
                            View In Library
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Collapse>
              {!isLibraryPlaceholder && (
                <Box>
                  {(contentBlock === "categories" ||
                    contentBlock === "dscategories" ||
                    contentBlock === "outputexperimentlog" ||
                    contentBlock === "tags" ||
                    contentBlock === "entrytags" ||
                    contentBlock === "metadata" ||
                    contentBlock === "interestgroups") && (
                    <div>
                      <Tabs
                        value={this.state.currentView}
                        onChange={this.handleTabChange}
                        aria-label="Editor Tabs"
                        style={{ paddingBottom: "10px" }}
                        textColor="primary"
                        indicatorColor="primary"
                      >
                        <Tab label="Code Editor (Scriban)" />
                        <Tab
                          label={
                            contentBlock === "tags"
                              ? "Tag Editor (JSON)"
                              : "Category Editor (JSON)"
                          }
                        />
                      </Tabs>
                      <div className={classes.editorOptions}>
                        <TabPanel value={this.state.currentView} index={0}>
                          <div>
                            <CodeEditor
                              withTabs
                              template={template}
                              handleChange={this.handleCodeEditorChange}
                            />
                          </div>
                        </TabPanel>
                        {(contentBlock === "categories" ||
                          contentBlock === "dscategories" ||
                          contentBlock === "outputexperimentlog" ||
                          contentBlock === "tags" ||
                          contentBlock === "entrytags" ||
                          contentBlock === "metadata" ||
                          contentBlock === "interestgroups") && (
                          <TabPanel value={this.state.currentView} index={1}>
                            <>
                              {!Helpers.parseJSON(template).isValidJSON && (
                                <Card style={{ padding: "5px" }}>
                                  <Grid container spacing={1} item xs={12}>
                                    <Grid item>
                                      <p
                                        style={{
                                          color: Helpers.parseJSON(template)
                                            .isValidJSON
                                            ? "#66BB6A"
                                            : "#D01111"
                                        }}
                                      >
                                        <Report />
                                        &nbsp;
                                        {
                                          Helpers.parseJSON(template)
                                            .invalidJSONMessage
                                        }
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            fontSize: "14px",
                                            position: "relative",
                                            color: "black"
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              template &&
                                              Helpers.constructPreviewText(
                                                true,
                                                template
                                              )
                                          }}
                                          ref={this.contentRef}
                                        />
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Card>
                              )}
                              {(contentBlock === "categories" ||
                                contentBlock === "dscategories" ||
                                contentBlock === "outputexperimentlog" ||
                                contentBlock === "entrytags" ||
                                contentBlock === "metadata" ||
                                contentBlock === "interestgroups") && (
                                <NarrativeCategories
                                  name={"template"}
                                  categories={template}
                                  onChange={event =>
                                    this.setState({
                                      isSnackbarOpen: true,
                                      alertMessage: this.standardAlertMessage,
                                      template: event.target.value
                                    })
                                  }
                                />
                              )}
                              {contentBlock === "tags" && (
                                <NarrativeTags
                                  name={"template"}
                                  tags={template}
                                  onChange={event =>
                                    this.setState({
                                      isSnackbarOpen: true,
                                      alertMessage: this.standardAlertMessage,
                                      template: event.target.value
                                    })
                                  }
                                />
                              )}
                            </>
                          </TabPanel>
                        )}
                      </div>
                    </div>
                  )}
                  {contentBlock !== "categories" &&
                    contentBlock !== "dscategories" &&
                    contentBlock !== "outputexperimentlog" &&
                    contentBlock !== "tags" &&
                    contentBlock !== "entrytags" &&
                    contentBlock !== "metadata" &&
                    contentBlock !== "interestgroups" && (
                      <div>
                        <CodeEditor
                          withTabs
                          template={template}
                          handleChange={editor => {
                            if (editor && editor.getValue() !== template) {
                              this.setState({
                                isSnackbarOpen: true,
                                alertMessage: this.standardAlertMessage,
                                template: editor.getValue()
                              })
                            }
                          }}
                        />
                      </div>
                    )}
                </Box>
              )}
              {this.state.isLoadingPreview && <LinearProgress />}
              {!isLibraryPlaceholder && (
                <Box>
                  {(this.state.isSaving || this.state.isLoadingPreview) && (
                    <LinearProgress />
                  )}
                  <Grid container spacing={1}>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      <GridItem xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.ignoreKillswitch || false}
                              onChange={event => {
                                this.setState({
                                  ignoreKillswitch: event.target.checked
                                })
                              }}
                            />
                          }
                          label="Ignore Kill Switches?"
                        />
                      </GridItem>
                      <GridItem
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "0 0"
                        }}
                      >
                        {!isLibraryPlaceholder && (
                          <>
                            <Button
                              onClick={() => {
                                this.setState({ doValidateJSON: false }, () =>
                                  this.buildPreview()
                                )
                              }}
                              disabled={this.state.isLoadingPreview}
                            >
                              Preview
                            </Button>
                            &nbsp;
                            <Button
                              onClick={() => {
                                this.setState({ doValidateJSON: true }, () =>
                                  this.buildPreview()
                                )
                              }}
                              disabled={this.state.isLoadingPreview}
                            >
                              Check JSON
                            </Button>
                          </>
                        )}
                        &nbsp;
                        <Button onClick={() => this.toggle()}>
                          Cancel
                        </Button>{" "}
                        &nbsp;
                        {currentNarrativeId === narrative_Id && (
                          <React.Fragment>
                            <Button
                              color="primary"
                              onClick={() => this.handleSave()}
                              disabled={this.isSaving}
                            >
                              Save
                            </Button>
                          </React.Fragment>
                        )}
                        {currentNarrativeId !== narrative_Id && (
                          <Button
                            title="Opens new window"
                            onClick={() => {
                              window.open(
                                `/portal/narrative/${narrative_Id}/edit?view=sentenceid-${sentence.id}`,
                                "_blank"
                              )
                            }}
                            color="primary"
                          >
                            View In Library
                          </Button>
                        )}
                      </GridItem>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {!isLibraryPlaceholder && (
                <React.Fragment>
                  <div style={{ marginTop: "10px" }} />
                  <WordCounter
                    title="Metrics"
                    inputString={preview}
                    renderTimeInMilliseconds={previewElapsedTimeMilliseconds}
                  />

                  <Card>
                    <Grid container spacing={1} item xs={12}>
                      <Grid item>
                        {this.state.isLoadingPreview && <CircularProgress />}
                        {this.state.doValidateJSON &&
                          !this.state.isLoadingPreview && (
                            <p
                              style={{
                                color: Helpers.parseJSON(preview).isValidJSON
                                  ? "#66BB6A"
                                  : "#D01111"
                              }}
                            >
                              {!Helpers.parseJSON(preview).isValidJSON && (
                                <Report />
                              )}
                              {Helpers.parseJSON(preview).isValidJSON && (
                                <Check />
                              )}
                              &nbsp;
                              {Helpers.parseJSON(preview).invalidJSONMessage}
                            </p>
                          )}
                        {!this.state.isLoadingPreview && (
                          <div
                            style={{
                              marginTop: "10px",
                              fontSize: "14px",
                              position: "relative"
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                preview &&
                                Helpers.constructPreviewText(
                                  this.state.doValidateJSON,
                                  preview
                                )
                            }}
                            ref={this.contentRef}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </React.Fragment>
              )}
            </div>
            {!this.props.searchEditorMode &&
              sentenceTriggers &&
              sentenceTriggers.length > 0 && <Card>{sentenceTriggers}</Card>}
            {!this.props.searchEditorMode && (
              <div>
                <MUIAccordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className={classes.accordionIcon} />
                    }
                    className={classes.sentenceHeader}
                    title="View Raw Content (markup)"
                    onClick={this.toggleRawContent}
                    style={{ flexGrow: 1, cursor: "pointer" }}
                  >
                    <h5>Content (raw)</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    {this.state.isRawContentVisibile && (
                      <TextField
                        fullWidth={true}
                        multiline={true}
                        minRows={10}
                        value={preview}
                      />
                    )}
                  </AccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className={classes.accordionIcon} />
                    }
                    className={classes.sentenceHeader}
                    title="View Change Log"
                    onClick={this.loadSentenceHistory}
                    style={{ flexGrow: 1, cursor: "pointer" }}
                  >
                    <h5>Change Log</h5>
                  </AccordionSummary>
                  <AccordionDetails className={classes.cloneSentenceTable}>
                    {this.state.isHistoryVisible && (
                      <ChangeLog
                        handleRollBack={newValue =>
                          this.setState({ ...this.state, ...newValue })
                        }
                        changeLogs={this.state.changeLogs}
                        totalChangeLogRecords={this.state.totalChangeLogRecords}
                        isChangeLogsLoading={this.state.isChangeLogsLoading}
                        isChangeLogsLoaded={this.state.isChangeLogsLoaded}
                      />
                    )}
                  </AccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className={classes.accordionIcon} />
                    }
                    className={classes.sentenceHeader}
                    title="View Clone Information"
                    onClick={this.loadCloneHistory}
                    style={{ flexGrow: 2, cursor: "pointer" }}
                  >
                    <h5>Cloned Sentence Information</h5>
                  </AccordionSummary>
                  <AccordionDetails className={classes.cloneSentenceTable}>
                    {this.state.isCloneVisible && (
                      <ReactTable
                        data={clonedInfo}
                        columns={[
                          {
                            Header: "Relationship",
                            accessor: "relationship",
                            width: 200
                          },
                          {
                            Header: "Sentence ID",
                            accessor: "sentence_Id",
                            Cell: ({ row }) => (
                              <Link
                                style={{
                                  color: "#144D69",
                                  textDecoration: "underline"
                                }}
                                to={`/portal/narrative/${row._original.narrative_Id}/edit?view=sentenceid-${row.sentence_Id}`}
                              >
                                {row.sentence_Id}
                              </Link>
                            ),
                            width: 200
                          },
                          {
                            Header: "First Characters",
                            accessor: "template",
                            Cell: ({ row }) => {
                              if (!row.template) {
                                return " "
                              }
                              return row.template.substring(0, 160)
                            }
                          }
                        ]}
                        minRows={0}
                        noDataText={"This sentence has no related sentences."}
                        className="-striped -hightlight -compact"
                        showPaginationTop
                        defaultPageSize={25}
                        showPageSizeOptions={true}
                        pageSizeOptions={[5, 10, 20, 25, 50, 500]}
                        showPaginationBottom={false}
                        defaultSorted={[
                          {
                            id: "sentence_Id",
                            desc: false
                          }
                        ]}
                      />
                    )}
                  </AccordionDetails>
                </MUIAccordion>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

Sentence.propTypes = {
  toggleEdit: PropTypes.func,
  savedSentence: PropTypes.func,
  hitExitSentence: PropTypes.func,
  classes: PropTypes.object,
  sentence: PropTypes.object,
  ownerListForOrg: PropTypes.array,
  edit: PropTypes.bool,
  isLibary: PropTypes.bool,
  createMode: PropTypes.bool,
  toggleViewFromExplorer: PropTypes.bool,
  contentType: PropTypes.string,
  searchEditorMode: PropTypes.bool,
  handleReload: PropTypes.func,
  isToBeFilteredBy: PropTypes.bool
}

export default withStyles(styles)(withRouter(useStore(Sentence)))
