import { globalSettings } from "variables/general.jsx"
import { get, put, post } from "../tools/request"

export default class FeedEntryDAO {
  static getFeedEntryInfo = async entryId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/feedentry/db/${entryId}`
    )
    return await res.json()
  }

  static getFeed = async feedId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/feed/retrieve/${feedId}`
    )
    return await res.json()
  }

  static getFeedEntryLogs = async entryId => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/feedentry/logs/${entryId}`
    )
    return await res.json()
  }

  static getFeedBlockSnapShot = async (entryId, versionNumber) => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/feedentry/blocksnapshots/${entryId}?versionNumber=${versionNumber}`
    )
    return await res.json()
  }

  static getFeedDataModelSnapShot = async (entryId, versionNumber) => {
    const res = await get(
      `${globalSettings.apiBaseUrl}/api/feedentry/datamodelandtriggerssnapshot/${entryId}?versionNumber=${versionNumber}`
    )
    return await res.json()
  }

  static validateHtml = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/validatehtml`,
      null,
      body
    )
    return await res.json()
  }

  static postFeedEntry = async body => {
    const res = await put(
      `${globalSettings.apiBaseUrl}/api/feedentry/update`,
      null,
      body
    )
    return await res.json()
  }

  static postFeedEntryToWebhook = async entryId => {
    const res = await post(
      `${globalSettings.apiBaseUrl}/api/webhook/manualrender?feedEntryId=${entryId}`,
      null,
      null
    )
    return res.text()
  }
}
