import { useEffect, useMemo, useState } from "react"
import { TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Autocomplete } from "@mui/lab"

import CustomPopper from "components/Popper"

import {
  checkEntryModeOperations,
  getFilterValues,
  getMinAndMaxInRows,
  handleClearFiltersClick,
  handleEditEntriesClick,
  setLocalStorageVals
} from "tools/tableUtil"

import { Cookies } from "tools/storage"
const cookies = new Cookies()

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    }
  },
  inputRoot: {
    fontWeight: 400,
    fontSize: "13px",
    height: "32px",
    paddingLeft: "5px"
  },
  acOption: {
    fontWeight: 400,
    fontSize: "13px",
    '&[aria-selected="true"]': {
      backgroundColor: "#bdbdbd"
    }
  }
}))

const FilterActionsUi = ({
  columnId,
  filterValue,
  onClickClearFilter,
  onClickPersistFilter
}) => {
  const editEntryMode = cookies.get("editEntryMode") || []

  const isMatchEntryMode = checkEntryModeOperations(
    editEntryMode,
    columnId,
    "match"
  )
  const isGetOldFilterEntryMode = !!checkEntryModeOperations(
    editEntryMode,
    columnId,
    "getOldFilter"
  )

  const oldFilterVal = checkEntryModeOperations(
    editEntryMode,
    columnId,
    "getOldFilterVal"
  )

  return (
    <div>
      {(!!filterValue || isMatchEntryMode) && (
        <div>
          {!!filterValue && (
            <button
              className="editFilters"
              value={`__${columnId}`}
              onClick={onClickClearFilter}
            >
              Clear This Filter
            </button>
          )}
          <button
            value={`___${columnId}`}
            className="editEntries"
            onClick={onClickPersistFilter}
          >
            {!isMatchEntryMode
              ? "Persist this filter"
              : "Clear persisted filter"}
          </button>
          {isMatchEntryMode && isGetOldFilterEntryMode && (
            <span className="boldFilter">"{oldFilterVal}"</span>
          )}
        </div>
      )}
    </div>
  )
}

export function SelectRowsHeaderActions({ table, loadSelectedRow }) {
  const { root, inputRoot, acOption } = useStyles()
  const [selectedOption, setSelectedOption] = useState(null)
  const { rowSelection } = table.getState()

  const options = [
    { label: "All", value: 1 },
    { label: "Shown Rows", value: 2 },
    { label: "Unsaved/Changed", value: 3 }
  ]

  const { rows } = table.getCoreRowModel()
  const { changedRows } = table.options.meta

  const onChange = (e, option) => {
    setSelectedOption(option)
    takeActionForOption(option)
  }

  const takeActionForOption = option => {
    switch (option?.value) {
      case 1:
        table.toggleAllRowsSelected(true)
        rows.forEach(row => {
          loadSelectedRow(row)
        })

        break
      case 2:
        table.resetRowSelection()
        table.toggleAllPageRowsSelected(true)
        break
      case 3:
        table.resetRowSelection()
        changedRows?.forEach(id =>
          rows.forEach(row => {
            if (+row.id === id) {
              row.toggleSelected(true)
            }
          })
        )
        break
      default:
        table.resetRowSelection()
    }
  }

  const setOptionFromTableState = () => {
    if (table.getIsAllRowsSelected()) {
      setSelectedOption(options[0])
    } else if (table.getIsAllPageRowsSelected()) {
      setSelectedOption(options[1])
    } else if (
      changedRows?.length &&
      changedRows.every(rowIndex => !!rowSelection[rowIndex])
    ) {
      setSelectedOption(options[2])
    } else {
      setSelectedOption(null)
    }
  }

  useEffect(() => {
    setOptionFromTableState()
  }, [rowSelection])

  // Render a multi-select box
  return (
    <Autocomplete
      classes={{
        root,
        inputRoot,
        option: acOption,
        noOptions: acOption
      }}
      id="select"
      value={selectedOption}
      options={options}
      onChange={onChange}
      PopperComponent={CustomPopper}
      getOptionLabel={option => option?.label || ""}
      isOptionEqualToValue={(option, value) =>
        option && value ? option.value === value.value : false
      }
      renderInput={params => <TextField {...params} />}
    />
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter({ column, table }) {
  const { id, getFilterValue, setFilterValue } = column
  const { rows: allRows } = table.getCoreRowModel()

  const [min, max] = useMemo(
    () => getMinAndMaxInRows(allRows, id),
    [id, allRows]
  )

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={getFilterValue() || min}
        onChange={e => {
          setFilterValue(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilterValue(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({ column, table }) {
  const { id, getFilterValue, setFilterValue } = column
  const { rows: allRows } = table.getCoreRowModel()

  const filterValue = getFilterValue()

  const [min, max] = useMemo(
    () => getMinAndMaxInRows(allRows, id),
    [id, allRows]
  )

  return (
    <div
      style={{
        display: "flex"
      }}
    >
      <input
        value={filterValue?.[0] || ""}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilterValue((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1]
          ])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem"
        }}
      />
      to
      <input
        value={filterValue?.[1] || ""}
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilterValue((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined
          ])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem"
        }}
      />
    </div>
  )
}

export function NarrativeFilter({ column, table }) {
  const { id, getFilterValue, setFilterValue } = column

  let filterValue = getFilterValue()
  const preFilteredRows = table.getCoreRowModel().rows

  // Calculate the options for filtering
  // using the preFilteredRows
  let { newVal, useNewVal } = getFilterValues(id, filterValue, "filterLoad")
  if (useNewVal) {
    //If it was determined we need to update it
    setFilterValue(newVal)
    filterValue = newVal
  }
  filterValue = filterValue || ""

  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.getValue(id))
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  if (options.length > 1) {
    return (
      <select
        value={filterValue}
        onChange={e => {
          setLocalStorageVals(id, e.target.value)
          setFilterValue(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  } else {
    return null
  }
}

export function SelectColumnAltArrayFilter({ column, table }) {
  const { columnDef, getFilterValue, id, setFilterValue } = column

  const [localValue, setLocalValue] = useState(getFilterValue())
  const { root, inputRoot, acOption } = useStyles()

  const editEntryMode = cookies.get("editEntryMode") || []
  const preFilteredRows = table.getCoreRowModel().rows

  if (checkEntryModeOperations(editEntryMode, id, "match")) {
    setFilterValue(undefined)
  } else {
    let valx = getFilterValues(id, localValue, "filterLoad")
    let val = valx.newVal //Maybe new filter value, depending on...
    if (valx.useNewVal) {
      //If it was determined we need to update it
      setLocalValue(val)
      setFilterValue(val)
    }
  }

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows?.forEach(row => {
      columnDef.meta?.acOptions?.forEach(option =>
        row.getValue(id)?.forEach(value => {
          if (value === option.id) {
            options.add(option)
          }
        })
      )
    })
    options.forEach(option => option === undefined && options.delete(option))
    return [...options.values()]
  }, [id, preFilteredRows])

  const onChange = (e, option, reason) => {
    if (reason === "clear") {
      setFilterValue(undefined)
    }
    setLocalStorageVals(id, (option && option.id) || undefined)
    setLocalValue((option && option.id) || undefined)
  }
  // Render a multi-select box
  return (
    <div>
      <FilterActionsUi
        columnId={id}
        filterValue={localValue}
        onClickClearFilter={e => {
          handleClearFiltersClick(e)
          setLocalValue(undefined)
          setFilterValue(undefined)
        }}
        onClickPersistFilter={e => {
          handleEditEntriesClick(e, id)
          setLocalValue(undefined)
        }}
      />
      <Autocomplete
        classes={{ root, inputRoot, option: acOption, noOptions: acOption }}
        id={id}
        value={options.find(option => option.id === localValue) || ""}
        options={options}
        onChange={onChange}
        PopperComponent={CustomPopper}
        getOptionLabel={option => (option ? option.name : "")}
        isOptionEqualToValue={(option, value) =>
          option && value ? option === value : false
        }
        renderInput={params => <TextField {...params} />}
      />
    </div>
  )
}

export function SelectColumnAltFilter({ column, table }) {
  const { columnDef, id, getFilterValue, setFilterValue } = column
  const [localValue, setLocalValue] = useState(getFilterValue())

  // Calculate the options for filtering
  // using the preFilteredRows
  const { root, inputRoot, acOption } = useStyles()

  let editEntryMode = cookies.get("editEntryMode") || []
  const preFilteredRows = table.getCoreRowModel().rows

  if (checkEntryModeOperations(editEntryMode, id, "match")) {
    setLocalValue(undefined)
  } else {
    let valx = getFilterValues(id, localValue, "filterLoad")
    let val = valx.newVal //Maybe new filter value, depending on...
    if (valx.useNewVal) {
      //If it was determined we need to update it
      setFilterValue(val)
      setLocalValue(val)
    }
  }

  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows?.forEach(row => {
      options.add(
        columnDef.meta?.acOptions?.find(value => value.id === row.getValue(id))
      )
    })
    options.forEach(option => option === undefined && options.delete(option))
    return [...options.values()]
  }, [id, preFilteredRows])

  const onChange = (e, option, reason) => {
    if (reason === "clear") {
      setLocalValue(undefined)
    }
    setLocalStorageVals(id, (option && option.id) || undefined)
    setFilterValue((option && option.id) || undefined)
  }
  // Render a multi-select box
  return (
    <div>
      <FilterActionsUi
        columnId={id}
        filterValue={localValue}
        onClickClearFilter={e => {
          handleClearFiltersClick(e)
          setLocalValue(undefined)
          setFilterValue(undefined)
        }}
        onClickPersistFilter={e => {
          handleEditEntriesClick(e, id)
          setLocalValue(undefined)
        }}
      />
      <Autocomplete
        classes={{ root, inputRoot, option: acOption, noOptions: acOption }}
        id={id}
        value={options.find(option => option.id === localValue) || ""}
        options={options}
        onChange={onChange}
        PopperComponent={CustomPopper}
        getOptionLabel={option => (option ? option.name : "")}
        isOptionEqualToValue={(option, value) =>
          option && value ? option === value : false
        }
        renderInput={params => <TextField {...params} />}
      />
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({ column, table }) {
  const { id, getFilterValue, setFilterValue } = column
  let filterValue = getFilterValue()

  // Calculate the options for filtering
  // using the preFilteredRows
  const { root, inputRoot, acOption } = useStyles()

  const preFilteredRows = table.getCoreRowModel().rows

  let editEntryMode = cookies.get("editEntryMode") || []
  if (checkEntryModeOperations(editEntryMode, id, "match")) {
    filterValue = undefined
  } else {
    let valx = getFilterValues(id, filterValue, "filterLoad")
    let val = valx.newVal //Maybe new filter value, depending on...
    if (valx.useNewVal) {
      //If it was determined we need to update it
      setFilterValue(val)
      filterValue = val
    }
  }

  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      const value = row.getValue(id)
      if (value) {
        options.add(value)
      }
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const onChange = (e, option) => {
    setLocalStorageVals(id, (option && option) || undefined)
    setFilterValue((option && option) || undefined)
  }
  // Render a multi-select box
  return (
    <div>
      <FilterActionsUi
        columnId={id}
        filterValue={filterValue}
        onClickClearFilter={e => {
          handleClearFiltersClick(e)
          filterValue = undefined
          setFilterValue(undefined)
        }}
        onClickPersistFilter={e => {
          handleEditEntriesClick(e, id)
          filterValue = undefined
        }}
      />
      <Autocomplete
        classes={{ root, inputRoot, option: acOption, noOptions: acOption }}
        id={id}
        value={filterValue}
        options={options}
        onChange={onChange}
        PopperComponent={CustomPopper}
        getOptionLabel={option => (option ? option.toString() : "")}
        isOptionEqualToValue={(option, value) =>
          option && value ? option === value : false
        }
        renderInput={params => <TextField {...params} />}
      />
    </div>
  )
}

// Define a default UI for filtering
export function DefaultColumnFilterSimple({ column, table }) {
  const count = table.getCoreRowModel().rows.length

  return (
    <input
      value={column.getFilterValue() || ""}
      onChange={e => {
        column.setFilterValue(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

// Define a default UI for filtering
export function DefaultColumnFilter({
  column,
  table
  // column: { id, filterValue, preFilteredRows, setFilter }
}) {
  const { id, getFilterValue, setFilterValue } = column
  const count = table.getCoreRowModel().rows.length

  let filterValue = getFilterValue()
  const editEntryMode = cookies.get("editEntryMode") || []

  if (checkEntryModeOperations(editEntryMode, id, "match")) {
    filterValue = ""
  } else {
    let valx = getFilterValues(id, filterValue, "filterLoad")
    // EGG - As we go through this specific filter (id), we check to see if we have a
    //value in LocalStorage for this. The return value contains a newValue AND if it should be used
    let val = valx.newVal //Maybe new filter value, depending on...
    if (valx.useNewVal) {
      //If it was determined we need to update it
      setFilterValue(val)
      filterValue = val
    }
    filterValue = filterValue ? filterValue : ""
  }
  return (
    <div>
      <FilterActionsUi
        columnId={id}
        filterValue={filterValue}
        onClickClearFilter={e => {
          handleClearFiltersClick(e)
          setFilterValue(undefined)
        }}
        onClickPersistFilter={e => {
          handleEditEntriesClick(e, id)
          setFilterValue(undefined)
        }}
      />
      <input
        value={filterValue}
        id={`_${id}`}
        onChange={e => {
          setLocalStorageVals(id, e.target.value) //CHICKEN - Sets this filter value as the latest LocalStorage value for this input (id)
          setFilterValue(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    </div>
  )
}

export function ContentElementFilter({ column, table }) {
  const { id, getFilterValue, setFilterValue } = column
  let filterValue = getFilterValue()

  const { root, inputRoot, acOption } = useStyles()

  const editEntryMode = cookies.get("editEntryMode") || []
  const preFilteredRows = table.getCoreRowModel().rows

  if (checkEntryModeOperations(editEntryMode, id, "match")) {
    filterValue = undefined
  } else {
    let valx = getFilterValues(id, filterValue, "filterLoad")
    let val = valx.newVal //Maybe new filter value, depending on...
    if (valx.useNewVal) {
      //If it was determined we need to update it
      setFilterValue(val)
      filterValue = val
    }
  }

  // $TODO Are these hardcoded options still valid?
  const labels = [
    { label: "EntryId", value: "entryid" },
    { label: "Title", value: "title" },
    { label: "SEOTitle", value: "seotitle" },
    { label: "SEOSlug", value: "seoslug" },
    { label: "Link", value: "link" },
    { label: "Expired On", value: "expiredon" },
    { label: "Summary", value: "summary" },
    { label: "Meta Description", value: "metadescription" },
    { label: "Rights", value: "rights" },
    { label: "Library", value: "library" },
    { label: "Categories (JSON)", value: "categories" },
    { label: "DS Categories (JSON)", value: "dscategories" },
    { label: "Output Experiment Log (JSON)", value: "outputexperimentlog" },
    { label: "Featured Image Object (JSON)", value: "featuredimagejson" },
    { label: "Intro Paragraph", value: "introparagraph" },
    { label: "Content", value: "content" },
    { label: "Bottom Heading", value: "bottomheading" },
    { label: "Bottom Paragraph", value: "bottomparagraph" },
    { label: "SchemaOrg", value: "schemaorg" },
    { label: "AP ByLine", value: "byline" },
    {
      label: "AP Characteristic Media Type",
      value: "apcontentmanagementcharacteristicsmediatype"
    },
    { label: "AP DateLine", value: "apdateline" },
    { label: "AP Extended Headline", value: "extendedheadline" },
    { label: "AP Headline", value: "headline" },
    { label: "AP Management Id", value: "managementid" },
    { label: "AP Management Type", value: "managementtype" },
    {
      label: "AP Publishing Special Instructions",
      value: "apnewsmanagementpublishingspecialinstructions"
    },
    {
      label: "AP Publishing Status",
      value: "apnewsmanagementpublishingstatus"
    },
    {
      label: "AP Sequence Number",
      value: "apnewsmanagementmanagementsequencenumber"
    },
    { label: "AP SlugLine", value: "slugline" },
    {
      label: "Message Type ID",
      value: "messagetypeid"
    },
    {
      label: "Insight Score",
      value: "insightscore"
    },
    {
      label: "Delivery Type ID",
      value: "deliverytypeid"
    },
    {
      label: "Media URL",
      value: "mediaurl"
    },
    {
      label: "EntryTags (JSON)",
      value: "entrytags"
    },
    {
      label: "Metadata (JSON)",
      value: "metadata"
    },
    {
      label: "Interest Groups (JSON)",
      value: "interestgroups"
    }
  ]

  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      if (labels.find(({ value }) => value === row.getValue(id))) {
        options.add(labels.find(({ value }) => value === row.getValue(id)))
      }
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const onChange = (e, option) => {
    setLocalStorageVals(id, (option && option.value) || undefined)
    setFilterValue((option && option.value) || undefined)
  }

  // Render a multi-select box
  return (
    <div>
      <FilterActionsUi
        columnId={id}
        filterValue={filterValue}
        onClickClearFilter={e => {
          handleClearFiltersClick(e)
          setFilterValue(undefined)
        }}
        onClickPersistFilter={e => {
          handleEditEntriesClick(e, id)
          setFilterValue(undefined)
        }}
      />
      <Autocomplete
        classes={{ root, inputRoot, option: acOption, noOptions: acOption }}
        id={id}
        value={filterValue}
        options={options}
        onChange={onChange}
        PopperComponent={CustomPopper}
        getOptionLabel={option => (option ? option.label : "")}
        isOptionEqualToValue={(option, value) =>
          option && value ? option.value === value.value : false
        }
        renderInput={params => <TextField {...params} />}
      />
    </div>
  )
}

export function OwnerFilter({ column, table }) {
  const { id, getFilterValue, setFilterValue } = column
  const { root, inputRoot, acOption } = useStyles()

  let filterValue = getFilterValue()
  const preFilteredRows = table.getCoreRowModel().rows

  let accountsForOrg = cookies.get("AccountsForOrg") || []
  accountsForOrg.unshift({ name: "SYSTEM", username: "SYSTEM", id: 1 })
  const editEntryMode = cookies.get("editEntryMode") || []
  if (checkEntryModeOperations(editEntryMode, id, "match")) {
    filterValue = ""
  } else {
    let valx = getFilterValues(id, filterValue, "filterLoad") // EGG - As we go through this specific filter (id), we check to see if we have a
    //value in LocalStorage for this. The return value contains a newValue AND if it should be used
    let val = valx.newVal //Maybe new filter value, depending on...
    if (valx.useNewVal) {
      //If it was determined we need to update it
      setFilterValue(val)
      filterValue = val
    }
    filterValue = filterValue || ""
  }

  const options = useMemo(() => {
    const rowIds = new Set()
    preFilteredRows.forEach(row => {
      rowIds.add(row.getValue(id))
    })
    const options = []
    rowIds.forEach(rowId => {
      let accountTmp = accountsForOrg.find(account => account.id === rowId)
      accountTmp && options.push(accountTmp)
    })
    return [...options]
  }, [id, preFilteredRows])

  const onChange = (e, option) => {
    setLocalStorageVals(id, (option && option.id) || undefined)
    setFilterValue((option && option.id) || undefined)
    filterValue = option
  }

  filterValue = options?.find(option => option?.id === filterValue) || null

  let oldFilterValue = checkEntryModeOperations(
    editEntryMode,
    id,
    "getOldFilterVal"
  )
  let oldFilterName =
    options?.find(option => option?.id === oldFilterValue) || ""

  if (oldFilterName && oldFilterName.name) {
    oldFilterName = oldFilterName.name
  }

  return (
    <div>
      <FilterActionsUi
        columnId={id}
        filterValue={filterValue}
        onClickClearFilter={e => {
          handleClearFiltersClick(e)
          setFilterValue(undefined)
        }}
        onClickPersistFilter={e => {
          handleEditEntriesClick(e, id)
          setFilterValue(undefined)
        }}
      />
      <Autocomplete
        classes={{ root, inputRoot, option: acOption, noOptions: acOption }}
        id={id}
        value={filterValue}
        options={options}
        onChange={onChange}
        PopperComponent={CustomPopper}
        getOptionLabel={option => (option ? option.name : "")}
        isOptionEqualToValue={(option, value) =>
          option && value ? option.id === value.id : false
        }
        renderInput={params => <TextField {...params} />}
      />
    </div>
  )
}

// This is a custom filter UI for selecting
// a status option from a list
export function StatusTypeFilter({ column, table }) {
  const { id, getFilterValue, setFilterValue } = column
  const { root, inputRoot, acOption } = useStyles()

  let filterValue = getFilterValue()
  const preFilteredRows = table.getCoreRowModel().rows

  const labels = [
    { label: "Draft", value: 1 },
    { label: "Review", value: 2 },
    { label: "Published", value: 3 },
    { label: "Planned", value: 4 },
    { label: "Sample", value: 5 }
  ]
  let editEntryMode = cookies.get("editEntryMode") || []
  if (checkEntryModeOperations(editEntryMode, id, "match")) {
    filterValue = undefined
  } else {
    let valx = getFilterValues(id, filterValue, "filterLoad")
    let val = valx.newVal //Maybe new filter value, depending on...
    if (valx.useNewVal) {
      //If it was determined we need to update it
      setFilterValue(val)
      filterValue = val
    }
  }

  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(labels.find(l => l.value === row.getValue(id)))
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const onChange = (e, option) => {
    setLocalStorageVals(id, (option && option) || undefined)
    setFilterValue((option && option.value) || undefined)
  }

  let oldFilterValue = checkEntryModeOperations(
    editEntryMode,
    id,
    "getOldFilterVal"
  )
  let oldFilterName =
    options?.find(option => {
      if (option && oldFilterValue === option.value) {
        return option
      }
      return false
    }) || ""

  if (oldFilterName && oldFilterName.label) {
    oldFilterName = oldFilterName.label
  }

  return (
    <div>
      <FilterActionsUi
        columnId={id}
        filterValue={filterValue}
        onClickClearFilter={e => {
          handleClearFiltersClick(e)
          setFilterValue(undefined)
        }}
        onClickPersistFilter={e => {
          handleEditEntriesClick(e, id)
          setFilterValue(undefined)
        }}
      />{" "}
      <Autocomplete
        classes={{ root, inputRoot, option: acOption, noOptions: acOption }}
        id={id}
        value={filterValue}
        options={options}
        onChange={onChange}
        PopperComponent={CustomPopper}
        getOptionLabel={option => (option ? option.label : null)}
        isOptionEqualToValue={(option, value) =>
          option && value ? option.id === value.id : false
        }
        renderInput={params => <TextField {...params} />}
      />
    </div>
  )
}
