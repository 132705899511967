import React, {
  useEffect,
  useState,
  useMemo //, useCallback
} from "react"
import { Link } from "react-router-dom"
import { useObserver } from "mobx-react"
import { useStore } from "contexts/rootContext"

import {
  Autocomplete,
  Card,
  CardContent,
  CardActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material"

import Button from "components/CustomButtons/Button"
import RealTimeLibraryStatus from "./RealTimeLibraryStatus"

function useStoreData() {
  const store = useStore()
  return useObserver(() => ({
    getRealTimeLibraryById: store.realTimeStore.getRealTimeLibraryById,
    realTimeLibraryById: store.realTimeStore.realTimeLibraryById,
    getRealTimeLibraries: store.realTimeStore.getRealTimeLibraries,
    realTimeLibraries: store.realTimeStore.realTimeLibraries,
    putLibraryById: store.realTimeStore.putLibraryById,
    getRealTimeLeague: store.realTimeStore.getRealTimeLeague,
    insightLeague: store.realTimeStore.insightLeague,
    getRealTimeOutputTypes: store.realTimeStore.getRealTimeOutputTypes,
    getRealTimeDeliveryTypes: store.realTimeStore.getRealTimeDeliveryTypes,
    realTimeDeliveryTypes: store.realTimeStore.realTimeDeliveryTypes,
    realTimeOutputTypes: store.realTimeStore.realTimeOutputTypes,
    getVariantVerticals: store.ctav2Store.getVariantVerticals,
    verticals: store.ctav2Store.verticals,
    sortedAccountOrganizations: store.accountStore.sortedAccountOrganizations,
    getAllRealTimeOutputs: store.realTimeStore.getAllRealTimeOutputs,
    allRealTimeOutputs: store.realTimeStore.allRealTimeOutputs,
    setDialogSuccessMessage: store.uiStore.setDialogSuccessMessage,
    setDialogSuccessOpen: store.uiStore.setDialogSuccessOpen,
    setDialogFailMessage: store.uiStore.setDialogFailMessage,
    setDialogFailOpen: store.uiStore.setDialogFailOpen
  }))
}

const RealTimeOutputForm = ({
  mode,
  onClose,
  step,
  editStep2,
  tabsFormat,
  outputId,
  cancelEdit,
  outputAccordionOpen,
  infoAccordionOpen,
  closePage,
  errorValues,
  onSave,
  onModeChange,
  realTimeOutput,
  sendFormValues,
  activateSave,
  setActivateSave
}) => {
  const inCreateMode = mode === "create"
  const inEditMode = mode === "edit"

  const _SPORTS = 1

  const {
    getRealTimeLibraryById,
    realTimeLibraryById,
    getRealTimeLibraries,
    realTimeLibraries,
    getRealTimeLeague,
    insightLeague,
    verticals,
    sortedAccountOrganizations,
    getAllRealTimeOutputs,
    allRealTimeOutputs,
    getRealTimeOutputTypes,
    getRealTimeDeliveryTypes,
    realTimeDeliveryTypes,
    realTimeOutputTypes
  } = useStoreData()
  const defaultVerticalOption = { id: 1, name: "Sports" }
  // eslint-disable-next-line no-unused-vars
  const insightTypeIds = []
  realTimeOutput?.insightTypeIds?.forEach(x => {
    insightTypeIds.push(x.id)
  })

  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [initialFormValues, setInitialFormValues] = useState({})
  const [formStep, setFormStep] = useState(step)
  const [showInsightTuning, setShowInsightTuning] = useState(false)
  const [noResend, setNoResend] = useState(false)

  useEffect(() => {
    getRealTimeOutputTypes()
    getRealTimeDeliveryTypes()
    getRealTimeLeague()
    getRealTimeLibraries()
  }, [])

  useEffect(() => {
    if (!allRealTimeOutputs || allRealTimeOutputs?.length === 0) {
      getAllRealTimeOutputs()
    }
  }, [allRealTimeOutputs])

  useEffect(() => {
    if (realTimeOutput) {
      const fv = {
        id: realTimeOutput.id,
        organization: realTimeOutput.organization,
        name: realTimeOutput.name || "No Name from data",
        status: realTimeOutput.status || { id: null, name: "" },
        vertical: realTimeOutput.vertical || defaultVerticalOption,
        library: realTimeOutput.library,
        league: realTimeOutput.league || { id: null, name: "" },
        deliveryType: realTimeOutput.deliveryType || { id: null, name: "" },
        outputType: realTimeOutput.outputType || { id: null, name: "" }
      }
      const insightTypeIds = []
      realTimeOutput?.insightTypes?.forEach(x => {
        fv[x.name] = true
        insightTypeIds.push(x.id)
      })
      fv.insightTypeIds = insightTypeIds
      setFormValues(fv)
      setInitialFormValues(fv)
      console.log("ZZ setting Form values to:", fv)
      console.log("ZZ And realTimeOutput is:", realTimeOutput)
    }
  }, [realTimeOutput])

  useEffect(() => {
    if (errorValues) {
      console.log("We have error values", errorValues)
      const errObj = {}
      errorValues?.forEach(x => {
        errObj[x.name] = x.msg
      })
      setFormErrors(errObj)
    } else {
      setFormErrors({})
    }
  }, [errorValues])

  useEffect(() => {
    if (formValues && !noResend) {
      sendFormValues(formValues)
    } else {
      setNoResend(false)
    }
  }, [formValues])

  useEffect(() => {
    if (
      realTimeLibraryById?.id > 0 &&
      realTimeLibraryById.id === formValues.library?.id
    ) {
      setShowInsightTuning(true)
    } else {
      setShowInsightTuning(false)
    }
  }, [realTimeLibraryById])

  useEffect(() => {
    if (step > -1) {
      console.log(`We are now on step ${step}`)
      setFormStep(step)
      if (step === 1) {
        if (realTimeLibraryById?.id === formValues.library?.id) {
          setShowInsightTuning(true)
        } else {
          setShowInsightTuning(false)
          if (formValues.library?.id > 0) {
            getRealTimeLibraryById(formValues.library?.id)
          }
        }
      } else if (step === 2) {
      }
    } else {
      console.log(`Bad value for step: ${step}`)
    }
  }, [step])

  const handleCancel = () => {
    if (!inCreateMode) {
      if (step === 0) {
        setFormValues(initialFormValues)
        setFormErrors({})
        cancelEdit()
      } else if (step === 1) {
        const newFormValues = { ...formValues }
        const insightTypesList =
          currentLibrary?.insightTypes?.length > 0
            ? currentLibrary.insightTypes
            : realTimeLibraryById?.insightTypes?.length > 0
            ? realTimeLibraryById.insightTypes
            : []
        if (insightTypesList.length > 0) {
          insightTypesList.forEach(x => {
            if (initialFormValues.insightTypeIds.includes(x.id)) {
              newFormValues[x.name] = true
            } else {
              newFormValues[x.name] = false
            }
          })
          setFormValues(newFormValues)
          setFormErrors({})
        }
      }
    }
    if (closePage) {
      closePage()
    }
    if (onClose) {
      onClose()
    } else {
      setFormValues(initialFormValues)
      if (onModeChange) {
        onModeChange("view")
      }
    }
  }

  const handleFieldChanges = (field, value) => {
    const newFormValues = {
      ...formValues,
      [field]: value
    }
    setFormValues(newFormValues)
  }

  const handleSave = editSave => {
    onSave(editSave)
  }

  const renderViewField = (label, value) => (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">
          {typeof value === "object" && value !== null ? value.name : value}
        </Typography>
      </Grid>
    </Grid>
  )

  const renderViewField_Link = (label, value) => (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2">
          <Link
            to={`/portal/real-time-library/edit?id=${realTimeOutput?.library?.id}`}
            target="_blank"
            style={{ color: "#0000EE", textDecoration: "underline" }}
          >
            {typeof value === "object" && value !== null ? value.name : value}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  )

  const isSaveValid = fv => {
    const insightTypeId = []
    realTimeLibraryById?.insightTypes?.forEach(x => {
      if (fv[x.name] === true) {
        insightTypeId.push(x.id)
      }
    })
    return insightTypeId.length > 0 ? true : false
  }

  const handleCheckboxChange = name => e => {
    const newFormValues = {
      ...formValues,
      disableSaveButton: false,
      [e.target.id]: e.target.checked
    }
    const isValid = isSaveValid(newFormValues)
    setFormValues(newFormValues)
    if (!isValid) {
      const newFormErrors = {
        ...formErrors,
        insightTypeIds: { msg: "You must select at least one Insight Type" }
      }
      setFormErrors(newFormErrors)
      setActivateSave(isValid)
    } else {
      const newFormErrors = {
        ...formErrors,
        insightTypeIds: null
      }
      setFormErrors(newFormErrors)
      setActivateSave(isValid)
    }
  }

  const orgsAndChildProperties = useMemo(() => {
    const temp = []
    sortedAccountOrganizations?.forEach(x => {
      if (x.isActive) {
        temp.push({ ...x, parentName: x.name })
        x.properties?.forEach(y => {
          if (y.isActive) {
            temp.push({ ...y, parentName: x.name })
          }
        })
      }
    })
    return temp
  }, [sortedAccountOrganizations])

  const formContentStep1 = () => (
    <>
      <CardContent aria-label="RealTime Output CreatorForm FormCard Step 1">
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <TextField
                aria-label="RealTime Output CreatorForm NameField"
                sx={{ width: "100%" }}
                name="name"
                id="realTimeName"
                label="NAME"
                value={formValues.name}
                error={!!formErrors.name}
                helperText={formErrors.name ? "Name required" : ""}
                required
                disabled={!inEditMode && !inCreateMode}
                onChange={e => handleFieldChanges("name", e.target.value)}
              />
            ) : (
              renderViewField("NAME: ", formValues.name)
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <Autocomplete
                aria-label="RealTime Output CreatorForm OutputTypeField"
                readOnly={!inCreateMode && !inEditMode}
                id="league"
                options={realTimeOutputTypes}
                value={formValues.outputType}
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="OUTPUT TYPE"
                    required={true}
                    error={!!formErrors.outputType}
                    helperText={formErrors.outputType}
                    disabled={!inEditMode && !inCreateMode}
                  />
                )}
                onChange={(event, newValue) => {
                  handleFieldChanges("outputType", newValue)
                }}
              />
            ) : (
              renderViewField("OUTPUT TYPE: ", formValues.outputType)
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <Autocomplete
                aria-label="RealTime Output CreatorForm DeliveryTypeField"
                readOnly={!inCreateMode && !inEditMode}
                id="league"
                options={realTimeDeliveryTypes}
                value={formValues.deliveryType}
                getOptionLabel={option => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="DELIVERY TYPE"
                    required={true}
                    error={!!formErrors.deliveryType}
                    helperText={formErrors.deliveryType}
                    disabled={!inEditMode && !inCreateMode}
                  />
                )}
                onChange={(event, newValue) => {
                  handleFieldChanges("deliveryType", newValue)
                }}
              />
            ) : (
              renderViewField("DELIVERY TYPE: ", formValues.deliveryType)
            )}
          </Grid>
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <>
                {orgsAndChildProperties?.length > 0 && (
                  <Autocomplete
                    aria-label="RealTime Output CreatorForm OrganizationidField"
                    fullWidth
                    options={
                      orgsAndChildProperties?.slice().sort(function (a, b) {
                        return a.parentName &&
                          a.parentName.localeCompare(b.parentName) !== 0
                          ? a.parentName.localeCompare(b.parentName)
                          : a.name === a.parentName
                          ? -1
                          : b.name === b.parentName
                          ? 1
                          : a.name && a.name.localeCompare(b.name)
                      }) || []
                    }
                    required={true}
                    getOptionLabel={option => option.name}
                    renderOption={(props, option) =>
                      option.parentName !== option.name ? (
                        <li
                          {...props}
                          style={{
                            marginLeft: "10px"
                          }}
                        >
                          {"  "}
                          {option.name}
                        </li>
                      ) : (
                        <span {...props}>{option.name}</span>
                      )
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="ORGANIZATION"
                        variant="outlined"
                        key={params.id}
                        value={params.name}
                        required={true}
                        error={formErrors.organizationId}
                        helperText={
                          formErrors.organizationId
                            ? "Organization is Required"
                            : ""
                        }
                      />
                    )}
                    value={orgsAndChildProperties?.find(org => {
                      if (org.id === formValues.organization?.id) {
                        return true
                      } else {
                        return false
                      }
                    })}
                    onChange={(e, newValue) =>
                      handleFieldChanges("organization", newValue)
                    }
                    disabled={false}
                  />
                )}
              </>
            ) : (
              renderViewField(
                "Organization name: ",
                formValues.organization?.name
              )
            )}
          </Grid>
          {verticals.length > 0 && (
            <Grid item xs={12}>
              {inCreateMode || inEditMode ? (
                <Autocomplete
                  aria-label="RealTime Output CreatorForm VerticalField"
                  readOnly={!inCreateMode && !inEditMode}
                  id="vertical"
                  options={verticals}
                  value={formValues.vertical}
                  getOptionLabel={option => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="VERTICAL"
                      required
                      error={!!formErrors.verticalId}
                      helperText={formErrors.verticalId}
                      disabled={!inEditMode && !inCreateMode}
                    />
                  )}
                  onChange={(e, newValue) =>
                    handleFieldChanges("vertical", newValue)
                  }
                />
              ) : (
                renderViewField("VERTICAL: ", defaultVerticalOption.name)
              )}
            </Grid>
          )}
          {formValues.vertical?.id === _SPORTS && (
            <Grid item xs={12}>
              {inCreateMode || inEditMode ? (
                <Autocomplete
                  aria-label="RealTime Output CreatorForm LeagueField"
                  readOnly={!inCreateMode && !inEditMode}
                  id="league"
                  options={insightLeague}
                  value={formValues.league}
                  getOptionLabel={option => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="ASSOCIATED LEAGUE"
                      required={true}
                      error={!!formErrors.leagueId}
                      helperText={formErrors.leagueId}
                      disabled={!inEditMode && !inCreateMode}
                    />
                  )}
                  onChange={(event, newValue) => {
                    handleFieldChanges("league", newValue)
                  }}
                />
              ) : (
                renderViewField("ASSOCIATED LEAGUE: ", formValues.league?.name)
              )}
            </Grid>
          )}
          {realTimeLibraries?.length > 0 && (
            <Grid item xs={12}>
              {inCreateMode || inEditMode ? (
                <Autocomplete
                  readOnly={!inCreateMode && !inEditMode}
                  aria-label="RealTime Output CreatorForm libraryIdField"
                  id="library"
                  options={realTimeLibraries}
                  value={formValues.library}
                  getOptionLabel={option =>
                    option?.name && option?.id
                      ? `${option.id} - ${option.name}`
                      : ""
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="ASSOCIATED LIBRARY"
                      required={true}
                      error={!!formErrors.libraryId}
                      helperText={formErrors.libraryId}
                      disabled={!inEditMode && !inCreateMode}
                    />
                  )}
                  onChange={(event, newValue) => {
                    handleFieldChanges("library", newValue)
                  }}
                />
              ) : (
                renderViewField_Link(
                  "ASSOCIATED LIBRARY: ",
                  formValues.library?.name
                )
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            {inCreateMode || inEditMode ? (
              <RealTimeLibraryStatus
                aria-label="RealTime Output CreatorForm StatusField"
                value={formValues.status}
                onChange={newValue => {
                  setFormValues(prev => ({
                    ...prev,
                    status: newValue
                      ? { id: newValue.id, name: newValue.name }
                      : null
                    //status: newValue ? newValue.name : null
                  }))
                }}
                statusErrorMsg={formErrors.status}
              />
            ) : (
              renderViewField("STATUS: ", formValues.status?.name)
            )}
          </Grid>
        </Grid>
      </CardContent>
    </>
  )

  const formContentStep2 = (
    <>
      {showInsightTuning && (
        <>
          <CardContent aria-label="RealTime Output CreatorForm FormCard Step 2">
            {formErrors.insightTypeIds && (
              <div
                style={{
                  display: "inline-block",
                  color: "#c00",
                  border: "2px solid #c00",
                  fontWeight: "bold",
                  padding: "5px"
                }}
              >
                {formErrors.insightTypeIds?.msg}
              </div>
            )}
            {realTimeLibraryById?.insightTypes?.map(x => (
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    aria-label={`RealTime Output CreatorForm InsightCheckbox ${x.name}Field`}
                    key={x.name}
                    control={
                      <Checkbox
                        id={x.name}
                        checked={formValues[x.name]}
                        onChange={handleCheckboxChange(x.name)}
                        value={x.id}
                        //aria-label="org-internal-checkbox"
                      />
                    }
                    label={
                      <Typography variant="body2" color="secondary">
                        {x.name}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </CardContent>
        </>
      )}
    </>
  )

  const currentLibrary =
    realTimeLibraries.find(x => x.id === realTimeOutput?.library?.id) || {}

  const formContentStep2_EditForm = () => (
    <>
      {editStep2 === true && (
        <>
          <CardContent aria-label="RealTime Output CreatorForm FormCard Step 2">
            {formErrors.insightTypeIds && (
              <div
                style={{
                  display: "inline-block",
                  color: "#c00",
                  border: "2px solid #c00",
                  fontWeight: "bold",
                  padding: "5px"
                }}
              >
                {formErrors.insightTypeIds?.msg}
              </div>
            )}
            {currentLibrary?.insightTypes?.map(x => (
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    aria-label={`RealTime Output CreatorForm InsightCheckbox ${x.name}Field`}
                    key={x.name}
                    control={
                      <Checkbox
                        id={x.name}
                        checked={formValues[x.name]}
                        onChange={handleCheckboxChange(x.name)}
                        value={x.id}
                        //aria-label="org-internal-checkbox"
                        //disabled={!inEditMode}
                      />
                    }
                    label={
                      <Typography variant="body2" color="secondary">
                        {x.name}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </CardContent>
        </>
      )}
    </>
  )

  return (
    <>
      {formValues?.id > 0 && (
        <>
          {inCreateMode ? (
            <>
              <DialogTitle>Real Time Output Create</DialogTitle>
              <DialogContent>
                {formStep === 0
                  ? formContentStep1()
                  : formStep === 1
                  ? formContentStep2
                  : null}
              </DialogContent>
            </>
          ) : editStep2 !== true ? (
            <Card style={{ maxWidth: "100%" }} elevation={0}>
              <Typography
                variant="h6"
                component="div"
                style={{ padding: "16px" }}
              >
                Real Time Output Info - {inEditMode ? "Edit" : "View"}
              </Typography>
              {formStep === 0
                ? formContentStep1()
                : formStep === 1
                ? formContentStep2
                : null}
              {inEditMode && (
                <CardActions style={{ justifyContent: "flex-end" }}>
                  <Button color="secondary" onClick={handleCancel} size="sm">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleSave(true)
                    }}
                    size="sm"
                    disabled={
                      activateSave === undefined ? false : !activateSave
                    }
                  >
                    Save
                  </Button>
                </CardActions>
              )}
            </Card>
          ) : step === 1 &&
            mode === "edit" &&
            (outputAccordionOpen || infoAccordionOpen) &&
            tabsFormat !== true ? (
            <Card
              style={{ maxWidth: "100%", padding: "150px 0 0 50px" }}
              elevation={0}
            >
              <h6>Insight Tuning Filters - EDIT</h6>
              {formContentStep2_EditForm()}
            </Card>
          ) : step === 1 &&
            (outputAccordionOpen || infoAccordionOpen) &&
            tabsFormat === true ? (
            <Card style={{ maxWidth: "100%" }} elevation={0}>
              <Typography
                variant="h6"
                component="div"
                style={{ padding: "0 16px" }}
              >
                Select Insight Types
              </Typography>
              {formStep === 0
                ? formContentStep1()
                : formStep === 1
                ? formContentStep2_EditForm()
                : null}
              {(inEditMode || formStep === 1) && (
                <CardActions style={{ justifyContent: "center" }}>
                  <Button color="secondary" onClick={handleCancel} size="sm">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleSave(true)
                    }}
                    size="sm"
                    disabled={
                      activateSave === undefined ? false : !activateSave
                    }
                  >
                    Save
                  </Button>
                </CardActions>
              )}
            </Card>
          ) : null}
        </>
      )}
    </>
  )
}
export default RealTimeOutputForm
